import React, {useState, useEffect} from "react";
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import * as Path from 'routes/paths';
import AuthService from 'services';
import swal from 'sweetalert';
import env from 'env.json';
import { Link } from 'react-router-dom';
import ContentLoader  from 'react-content-loader';

const BusinessView = (props) => {
    
    const [sending, setSending] = useState(false);
    const [data, setData] = useState({});
    const MODULE_NAME = 'user';

    //get user
    let isMounted = true;
    async function getData() {
        try{
            let query = {
                id: props.match.params.id,
                MODULE_NAME
            };
            setSending(true);
            await props.dispatch(AuthService.getDetail(query)).then((res) => {
                if(isMounted){
                    setSending(false);
                    setData(res.body);
                }
            });
        }catch(err) {
            setSending(false)
            console.log(err);
            if(err && err.data && err.data.message){
                swal("Oops!", err.data.message, "error");
            }
        }
    };
  
    useEffect(() => {
        getData();
        return () => {
            isMounted = false;
        };
    }, []);

    return(
        <>
        <Helmet title="Business" />
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Business Detail</h1>
                        </div>
                    </div>
                </div>
            </section>

            {sending && 
                <ContentLoader
                height={200}
                style={{ padding: 10, }} 
                speed={1}
                backgroundColor={'#f3f3f3'}
                foregroundColor={'#c1c5c7'}
                viewBox="0 0 380 70"
              >
                {/* Only SVG shapes */}
                <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
                <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
                <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
              </ContentLoader>
            }
            {(!sending && data)  &&
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="card card-primary card-outline">
                                    <div className="card-body box-profile">
                                        <div className="text-center">
                                            <a href={data.image && data.image.original ? env.SERVER_URL+data.image.original : '#'} target="_blank">
                                                <img className="profile-user-img img-fluid img-circle user-detail-img rounded mb-3"
                                                src={data.image && data.image.original ? env.SERVER_URL+data.image.original : '/assets/images/dummy.png'}
                                                alt="User profile picture" />
                                            </a>
                                        </div>

                                        <h3 className="profile-username text-center">{data.first_name} {data.last_name}</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <ul className="list-group list-group-unbordered mb-3">
                                    <li className="list-group-item">
                                        <b>Email</b> <span className="float-right">{data.email}</span>
                                    </li>
                                    {data.location &&
                                        <li className="list-group-item">
                                            <b>Address</b> <span className="float-right">{data.location}</span>
                                        </li>
                                    }
                                    {(data.country_code && data.phone) &&
                                        <li className="list-group-item">
                                            <b>Contact</b> <span className="float-right">{data.country_code} {data.phone}</span>
                                        </li>
                                    }
                                    {data.gender!=0 &&
                                        <li className="list-group-item">
                                            <b>Gender</b> <span className={`float-right`}>{data.gender == 1 ? 'Male' : data.gender == 2 ? 'Female' : 'Other'}</span>
                                        </li>
                                    }
                                    {data.notification_status &&
                                        <li className="list-group-item">
                                            <b>Notification Status</b> <span className={`${data.notification_status == 1 ? 'badge badge-success' : 'badge badge-danger'} float-right`}>{data.notification_status == 1 ? 'ON' : 'OFF'}</span>
                                        </li>
                                    }
                                    <li className="list-group-item">
                                        <b>Status</b> <span className={`${data.status == 1 ? 'badge badge-success' : 'badge badge-danger'} float-right`}>{data.status==1 ? 'Active' : 'Inactive'}</span>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Subscription Status</b> <span className={`${data.is_subscribed == 1 ? 'badge badge-success' : 'badge badge-danger'} float-right`}>{data.is_subscribed==1 ? 'Active' : 'Inactive'}</span>
                                    </li>
                                    {data.is_subscribed == 1 &&
                                        <>
                                        {data.subscription_title && 
                                        <li className="list-group-item">
                                            <b>Membership Plan</b> <span className="float-right">{data.subscription_title}</span>
                                        </li>
                                        }
                                        {data.subscription_amount &&
                                        <li className="list-group-item">
                                            <b>Membership Amount</b><span className="float-right">{data.subscription_amount}</span>
                                        </li>
                                        }
                                        {data.subscription_expire &&
                                        <li className="list-group-item">
                                            <b>Membership Expire</b><span className="float-right">{data.subscription_expire}</span>
                                        </li>
                                        }
                                        </>
                                    }
                                </ul>
                            </div>
                        </div>
                        {data.bio &&
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-primary card-outline">
                                    <div className="card-body box-profile">
                                            <div class="timeline-post">
                                                <div class="post-media">
                                                    <div class="content">
                                                        <h5>Bio</h5>
                                                    </div>
                                                </div>
                                                <div class="post-content">
                                                <p>{data.bio}</p>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                        <div className="row">
                            <div className="col-md-10">
                            </div>
                            <div className="col-md-2 back-button">        
                                <Link to={Path.business} class="btn btn-primary btn-block"><b>Back</b></Link>
                            </div>
                        </div>
                    </div>
                </section>   
            }
        </>
    )
};

const mapStateToProps = (state) => {
    return{
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BusinessView);

