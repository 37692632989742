import React, {useState, useEffect} from "react";
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import * as Path from 'routes/paths';
import AuthService from 'services';
import swal from 'sweetalert';
import * as actions from 'store/actions';
import env from 'env.json';
import { Field, reduxForm, reset } from 'redux-form';
import LoadingButton from 'components/shared/LoadingButton';
import ImageUploading from 'react-images-uploading';

const renderField = ({
    input,
    placeholder,
    type,
    disabled,
    meta: { touched, error, warning }
}) => (
    <>
        <input {...input} type={type} placeholder={placeholder} disabled={disabled} className="form-control"/>
        
        {touched &&
            (error && <span className="errorMessage">{error}</span>)
        }
    </>
)

const FormThingsToDoSubCategory = (props) => {

    //history
    const history = useHistory();
 
    let editkey = props && props.match.params.id;
    const { handleSubmit, submitting} = props;
    
    const [sending, setSending] = useState(false);
    const MODULE_NAME = 'thingstodo_subcategories';

    //categories
    const [categories, setCategories] = useState([]);

    const [ category, setCategory] = useState({
        cat_id:"",
    });

    const [ values, setValues] = useState({
        name:"",
    });

    //images
    const [ images, setImages] = useState([]);  
    const maxNumber = 1;

    //images
    const onChange = (imageList) => {
        setImages(imageList);
    };

    const handleChange2 = (e) => {
        const { name, value } = e.target;
        setCategory({
            ...category,
            [name]: value
        });
    }; 

    const editModal = async () => {
        let query = {
            MODULE_NAME,
            id:editkey
        };
        setSending(true);
        await props.dispatch(AuthService.getDetail(query)).then((res) => {
            setSending(false);
            props.dispatch(actions.persist_store({ editFormThingsToDoCategory:res.body}));
            //set images
            let newImg = [];
            newImg.push(res.body.image);
            setImages(newImg);
            setValues(res.body);
            setCategory(res.body.cat_id);
        });
    };  

    //get categories
    async function getCategories() {
        try{
            let query = {
                MODULE_NAME : 'thingstodo_categories'
            };
            setSending(true);
            await props.dispatch(AuthService.getCategory(query)).then((res) => {
                setSending(false);
                setCategories(res.body);
            });
        }catch(err) {
            setSending(false)
            console.log(err);
            if(err && err.data && err.data.message){
                swal("Oops!", err.data.message, "error");
            }
        }
    };

    useEffect(() => {
        if(editkey){
            editModal();
        }
        getCategories();
    }, []);

    //submit function
    const handleSubmit_ = async (values) => {
        try{
            const formData = new FormData(); 
            let query = {
                MODULE_NAME,
                name:values.name,
                cat_id:category.cat_id ? category.cat_id : category._id,
                image: images.length > 0 && images[0]['data_url'] ? [...images] : ''
            };

            if(images.length > 0 && images[0]['data_url']){
                for ( let key in images ) {
                    if(images[key]['file']){
                        formData.append('image', images[key]['file']);
                    }
                }
                
                formData.append('type', 'image');
                formData.append('folder', 'thingstodo');
                await props.dispatch(AuthService.fileUpload(formData)).then((res) => {
                    let newImg = res.body;
                    query.image = JSON.stringify(newImg)
                });
            }
            setSending(true);
            if(editkey){
                query._id = editkey;
                await props.dispatch(AuthService.updateData(query)).then((res) => {
                    setSending(false);
                    swal("Success!", res.message, "success");
                    setValues({
                        name:"",
                    });
                    props.dispatch(actions.persist_store({ editFormThingsToDoSubCategory:""}));
                    props.dispatch(reset('formThingsToDoSubCategory'));
                    history.push(Path.thingstodo_subcategory);
                });
            }
            else{
                await props.dispatch(AuthService.createData(query)).then((res) => {
                    setSending(false);
                    swal("Success!", res.message, "success");
                    setValues({
                        name:"",
                    });
                    props.dispatch(actions.persist_store({ editFormThingsToDoSubCategory:""}));
                    props.dispatch(reset('formThingsToDoSubCategory'));
                    history.push(Path.thingstodo_subcategory);
                });
            }
        }catch(err) {
            console.log(err);
            setSending(false);
            if(err && err.data && err.data.message){
                swal("Oops!", err.data.message, "error");
            }
        }
    }

    // render form
    const _renderForm = () => {
        return(
            <>
                <form onSubmit={handleSubmit(handleSubmit_)}>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12" style={{marginBottom:20}}>
                                <h3 className="card-title"><b>{editkey ? 'Edit' : 'Add'} SubCategory</b></h3>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                <label for="exampleSelect1">Categories</label>
                                    <select
                                        name="cat_id"
                                        value={category.cat_id}
                                        onChange={handleChange2}
                                        className={`form-control`}
                                        >
                                        <option value="">Select Category</option>
                                        {categories.length>0 && categories.map((item, key) => {
                                           return(
                                                <option key={key} value={item._id} selected={item._id==category._id}>
                                                    {item.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label for="exampleInputEmail1">Name</label>
                                    <Field 
                                        name="name" 
                                        component={renderField}
                                        type="text" 
                                    />
                                </div>
                            </div>
                        </div>
                        <label for="exampleInputEmail1">Image</label>
                        <ImageUploading
                            multiple
                            value={images}
                            onChange={onChange}
                            maxNumber={maxNumber}
                            dataURLKey="data_url"
                            >
                            {({
                                imageList,
                                onImageUpload,
                                // onImageRemoveAll,
                                onImageUpdate,
                                onImageRemove,
                                isDragging,
                                dragProps,
                            }) => (
                                // write your building UI
                                <>
                                <div className="form-row" style={isDragging ? { color: 'red' } : undefined}
                                    onClick={onImageUpload}
                                    {...dragProps}>
                                    <img src="/assets/images/upld.png" onClick={() => onImageRemove(0)} style={{height:'70px', height:'70px'}} />
                                </div>
                                <div className="row">
                                    {images.length>0 && images.map((image, index) => {
                                        let imgData;
                                        if(image['data_url']){
                                            imgData = image['data_url'];
                                        } else{
                                            imgData = env.SERVER_URL+image.original;
                                        }
                                        return(
                                        <div className="image-item" key={index}>
                                            <div className="close-img-item" onClick={() => onImageRemove(index)}>
                                                <i className="fa fa-times" aria-hidden="true"></i>
                                            </div>
                                            <img src={imgData} alt="" style={{width:'50px', height:'50px'}}/>
                                        </div>
                                        )
                                    })}
                                </div>
                                </>
                            )}
                        </ImageUploading> 

                        <div className="row">
                            <div className="col-4 loading--submit-button" style={{marginTop:'20px'}}>
                                <LoadingButton
                                    type="submit"
                                    className="btn btn-primary"
                                    loading={sending}
                                    disabled={submitting}
                                >
                                    {editkey ? 'Update' : 'Submit'}
                                </LoadingButton>
                            </div>
                        </div>  
                    </div>
                </form>
            </>
        )
    }

    return(
        <>
            <Helmet title="Entertainment SubCategory" />
            <div className="app-title">
                <div>
                <h1><i className="fa fa-th-list"></i> Entertainment SubCategory</h1>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="tile">
                        <div className="tile-body">
                            {_renderForm()}
                        </div>
                    </div>
                </div>
            </div>
            
        </>
    )
}

const initialValues = (values) => {	
	if(values) {
		return {
			...values,
            name: values.name ? values.name : "",
		}
	}
}

const validate = (values) => {
    const errors = {}
    //name
    if (!values.name) {
      errors.name = 'Required*'
    }
    return errors
}

const mapStateToProps = (state) => {
    return{
        initialValues: initialValues(state.persistStore.editFormThingsToDoCategory),
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}


const FormThingsToDoSubCategoryForm = reduxForm({
    form: 'formThingsToDoSubCategory',
    enableReinitialize: true,
	validate
})(FormThingsToDoSubCategory);

export default connect(mapStateToProps, mapDispatchToProps)(FormThingsToDoSubCategoryForm);
