//auth
export const login = '/admin/login';
//dashboard
export const dashboard = '/admin/dashboard';
//profile
export const profile = '/admin/profile';
//setting
export const setting = '/admin/setting';
//users
export const user = '/admin/users';
export const userview = '/admin/user';
export const user_view = '/admin/user/:id';
export const send_notification = '/admin/notification/sendNotificaiton'
//cms
export const cms = '/admin/cms';
//business
export const business = '/admin/business';
export const businessview = '/admin/business';
export const business_view = '/admin/business/:id';
//food_type
export const foodtype = '/admin/food_type';
export const form_foodtype = '/admin/form_food_type';
export const form_food_type = '/admin/form_food_type/:id';
//contact_us
export const contactus = '/admin/contact_us';
export const contactusview = '/admin/contact_us';
export const contactus_view = '/admin/contact_us/:id';
//brands
export const brand = '/admin/brands';
export const formbrand = '/admin/form_brands';
export const form_brand = '/admin/form_brands/:id';
//models
export const model = '/admin/models';
//types
export const type = '/admin/types';
//home_banners
export const banner = '/admin/home_banners';
export const form_banner = '/admin/form_home_banner';
export const form_home_banner = '/admin/form_home_banner/:id';
//banners
export const banner1 = '/admin/banners';
export const form_banner1 = '/admin/form_banner';

//ads
export const ads = '/admin/ads';
export const adsview = '/admin/ads';
export const ads_form_banner = '/admin/form_ads';
export const ads_form_home_banner = '/admin/form_ads/:id';
export const ads_view = '/admin/ads/:id';

//coupon
export const coupon = '/admin/coupon';
export const form_coupon = '/admin/form_coupon';
export const formcoupon = '/admin/form_coupon/:id';
//restaurants
export const restaurants = '/admin/restaurants';
export const restaurantsview = '/admin/restaurants';
export const restaurants_view = '/admin/restaurants/:id';
//hotels
export const hotels = '/admin/hotels';
export const hotelview = '/admin/hotels';
export const hotel_view = '/admin/hotels/:id';

//entertainment
export const entertainment = '/admin/entertainment';
export const entertainmentview = '/admin/entertainment';
export const entertainment_view = '/admin/entertainment/:id';
//employment
export const employment = '/admin/employment';
export const employmentview = '/admin/employment';
export const employment_view = '/admin/employment/:id';
export const employment_subcategory = '/admin/employment_subcategory';
export const employment_category = '/admin/employment_category';
//cars
export const cars = '/admin/cars';
export const carview = '/admin/cars';
export const car_view = '/admin/cars/:id';

//realestae
export const realestate = '/admin/real_estate';
export const realestateview = '/admin/real_estate';
export const realestate_view = '/admin/real_estate/:id';
export const realestate_type = '/admin/real_estate_type';

//travel tourism
export const traveltourism = '/admin/travel_tourism';
export const traveltourismview = '/admin/travel_tourism';
export const traveltourism_view = '/admin/travel_tourism/:id';
export const traveltourism_subcategory = '/admin/travel_tourism_subcategory';
export const form_travel_tourism_subcategory = '/admin/form_travel_tourism_subcategory';
export const form_traveltourism_subcategory = '/admin/form_travel_tourism_subcategory/:id';

//thingstodo
export const thingstodo = '/admin/things_to_do';
export const thingstodoview = '/admin/things_to_do';
export const thingstodo_view = '/admin/things_to_do/:id';

export const thingstodo_subcategory = '/admin/things_to_do_subcategory';
export const form_thingstodo_subcategory = '/admin/form_thingstodo_subcategory';
export const form_things_todo_subcategory = '/admin/form_thingstodo_subcategory/:id';

export const thingstodo_category = '/admin/thingstodo_category';
export const form_thingstodo_category = '/admin/form_thingstodo_category';
export const form_things_todo_category = '/admin/form_thingstodo_category/:id';

//services
export const services = '/admin/services';
export const servicesview = '/admin/services';
export const services_view = '/admin/services/:id';
export const services_subcategory = '/admin/services_subcategory';
export const form_services_subcategory= '/admin/form_services_subcategory';
export const formservices_subcategory = '/admin/form_services_subcategory/:id';
export const services_category = '/admin/services_category';
export const form_services_category = '/admin/form_services_category';
export const formservices_category = '/admin/form_services_category/:id';

//market place
export const marketplace = '/admin/marketplace_category';
export const marketplacelist = '/admin/marketplace/list';
export const marketplaceproduct = '/admin/marketplace/product';
export const marketplace_product= '/admin/marketplace/product/:id';
export const marketplaceview = '/admin/marketplace';
export const marketplace_view = '/admin/marketplace/:id';
export const marketplace_subcategory = '/admin/marketplace_subcategory';
export const marketplace_category = '/admin/marketplace_category';

//name category
export const name_category = '/admin/categories';
export const name_categoryform = '/admin/categories';
export const name_category_form = '/admin/categories/:id';

//spotted_deals
export const spotted_deals = '/admin/spotted_deals';
export const view_spotted_deals = '/admin/spotted_deals';
export const viewspotted_deals = '/admin/spotted_deals/:id';
export const spotted_form_deals = '/admin/form_deals';

//reported user
export const reported_user = '/admin/reported_user';
export const reporteduserview = '/admin/reported_user';
export const reporteduser_view = '/admin/reported_user/:id';

//blocked user
export const blocked_user = '/admin/blocked_user';
export const blocked_userview = '/admin/blocked_user';
export const blockeduserview = '/admin/blocked_user/:id';


//subscription
export const subscription = '/admin/subscription';
export const form_subscription = '/admin/subscriptions';
export const formsubscription = '/admin/subscriptions/:id';
export const subscription_view = '/admin/subscription';
export const subscriptionview = '/admin/subscription/:id';

//store subscription
export const store_subscription = '/admin/store_subscription';
export const store_formsubscription = '/admin/store_subscriptions';
export const store_form_subscription = '/admin/store_subscriptions/:id';
export const store_subscription_view = '/admin/store_subscription';
export const store_subscriptionview = '/admin/store_subscription/:id';


