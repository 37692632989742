import React, {useState} from "react";
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link, useHistory} from 'react-router-dom';
import * as Path from 'routes/paths';
import env from 'env.json';
// import * as actions from 'store/actions';

const Sidebar = ({user, userProfile}) => {

    //history
    const history = useHistory();

    const pathname = history.location.pathname.split('/');
 
 
    const filtered = pathname.filter(function (el) {
        if(el !== ""){
            return el;
        }
    });
     
    let path = '/';
 
    if(filtered.length>=2){
         path += filtered[0]+"/"+filtered[1];
    }
    else {
        path += filtered[0] ?? '';
    }
    //Restaurants
    const [showDropDown,setShowDropDown] = useState(false);
    const handleToggleDropDown = () => setShowDropDown(!showDropDown);

    //Cars
    const [showDropDown1,setShowDropDown1] = useState(false);
    const handleToggleDropDown1 = () => setShowDropDown1(!showDropDown1);

    //Real estate
    const [showDropDown2,setShowDropDown2] = useState(false);
    const handleToggleDropDown2 = () => setShowDropDown2(!showDropDown2);

    //Thing to do
    const [showDropDown3,setShowDropDown3] = useState(false);
    const handleToggleDropDown3 = () => setShowDropDown3(!showDropDown3);

    //Travel & Tourism
    const [showDropDown4,setShowDropDown4] = useState(false);
    const handleToggleDropDown4 = () => setShowDropDown4(!showDropDown4);

    //Employment
    const [showDropDown8,setShowDropDown8] = useState(false);
    const handleToggleDropDown8 = () => setShowDropDown8(!showDropDown8);
  
    //Services
    const [showDropDown5,setShowDropDown5] = useState(false);
    const handleToggleDropDown5 = () => setShowDropDown5(!showDropDown5);

    //Marketplace
    const [showDropDown6,setShowDropDown6] = useState(false);
    const handleToggleDropDown6 = () => setShowDropDown6(!showDropDown6);

    //Spotted deals
    const [showDropDown7,setShowDropDown7] = useState(false);
    const handleToggleDropDown7 = () => setShowDropDown7(!showDropDown7);

    return(
        <>
        <div className="app-sidebar__overlay" data-toggle="sidebar"></div>
        <aside className="app-sidebar">
            <div className="app-sidebar__user">
                <img className="app-sidebar__user-avatar profile-img" src={userProfile && userProfile.image && userProfile.image.original ? env.SERVER_URL+userProfile.image.original : user && user.user && user.user.image &&  user.user.image.original ? env.SERVER_URL+user.user.image.original  : '/assets/images/dummy.png'} alt="User Image" />
                <div>
                 <p className="app-sidebar__user-name">{userProfile ? userProfile.first_name  : user && user.user ? user.user.first_name : ''}</p>
                </div>
            </div>
            <ul className="app-menu">
                <li>
                    <Link className={classNames("app-menu__item", {'active' : (path===Path.dashboard)})} to={Path.dashboard}><i className="app-menu__icon fa fa-dashboard"></i><span className="app-menu__label">Dashboard</span>
                    </Link>
                </li>
                <li>
                    <Link className={classNames("app-menu__item", {'active' : (path===Path.name_category)})} to={Path.name_category}><i className="app-menu__icon fa fa-file"></i><span className="app-menu__label">Main Category</span>
                    </Link>
                </li>
                <li>
                    <Link className={classNames("app-menu__item", {'active' : (path===Path.user || path===Path.userview || path===Path.user_view)})} to={Path.user}><i className="app-menu__icon fa fa-users"></i><span className="app-menu__label">Users</span>
                    </Link>
                </li>
                <li>
                    <Link className={classNames("app-menu__item", {'active' : (path===Path.business || path===Path.businessview || path===Path.business_view)})} to={Path.business}><i className="app-menu__icon fa fa-industry"></i><span className="app-menu__label">Business</span>
                    </Link>
                </li>
                <li>
                    <Link className={classNames("app-menu__item", {'active' : (path===Path.cms)})} to={Path.cms}><i className="app-menu__icon fa fa-files-o"></i><span className="app-menu__label">CMS</span>
                    </Link>
                </li>
                <li>
                    <Link className={classNames("app-menu__item", {'active' : (path===Path.send_notification)})} to={Path.send_notification}><i className="app-menu__icon fa fa-bell"></i><span className="app-menu__label">Send Notification</span>
                    </Link>
                </li>
                <li>
                    <Link className={classNames("app-menu__item", {'active' : (path===Path.banner || path===Path.banner1 || path===Path.form_banner1 || path===Path.form_banner || path===Path.form_home_banner )})} to={Path.banner}><i className="app-menu__icon fa fa-picture-o"></i><span className="app-menu__label">Banner</span>
                    </Link>
                </li> 
              
                <li>
                    <Link className={classNames("app-menu__item", {'active' : (path===Path.ads || path===Path.adsview  || path===Path.ads_view ||path===Path.ads_form_banner  )})} to={Path.ads}><i className="app-menu__icon fa fa-file"></i><span className="app-menu__label">Ads</span>
                    </Link>
                </li>
                <li className={`treeview ${showDropDown ? 'is-expanded' : ''}`} onClick={handleToggleDropDown}>
                {/* <li className={`treeview ${(showDropDown || path===Path.type || path===Path.cars || path===Path.model || path===Path.brand) ? 'is-expanded' : ''}`} onClick={handleToggleDropDown}> */}
                    <span className={classNames("app-menu__item", {'active' : (path===Path.restaurants  || path===Path.foodtype || path===Path.form_foodtype || path===Path.form_food_type)})}>
                    <i className="app-menu__icon fa fa-file"></i>
                    <span className="app-menu__label">Restaurants</span>
                    <i className="treeview-indicator fa fa-angle-right"></i></span>
                    <ul className="treeview-menu">
                        <li>
                            <Link className={classNames("treeview-item", {'active' : (path===Path.restaurants )})} to={Path.restaurants}><i className="icon fa fa-th-list"></i> Listing</Link>
                        </li>
                        <li>
                            <Link className={classNames("treeview-item", {'active' : (path===Path.foodtype )})} to={Path.foodtype}><i className="icon fa fa-cutlery"></i> Food Types</Link>
                        </li>
                    </ul>
                </li>
                <li>
                    <Link className={classNames("app-menu__item", {'active' : (path===Path.hotels || path===Path.hotelview || path===Path.hotel_view)})} to={Path.hotels}><i className="app-menu__icon fa fa-building"></i><span className="app-menu__label">Hotels</span>
                    </Link>
                    <li>
                        <Link className={classNames("app-menu__item", {'active' : (path===Path.entertainment || path===Path.entertainmentview || path===Path.entertainment_view)})} to={Path.entertainment}><i className="app-menu__icon fa fa-file"></i><span className="app-menu__label">Things to do</span>
                        </Link>
                    </li>
                </li>
                <li className={`treeview ${showDropDown1 ? 'is-expanded' : ''}`} onClick={handleToggleDropDown1}>
                    <span className={classNames("app-menu__item", {'active' : (path===Path.cars || path===Path.type || path===Path.model)})}>
                    <i className="app-menu__icon fa fa-car"></i>
                    <span className="app-menu__label">Cars</span>
                    <i className="treeview-indicator fa fa-angle-right"></i></span>
                    <ul className="treeview-menu">
                        <li>
                            <Link className={classNames("treeview-item", {'active' : (path===Path.cars )})} to={Path.cars}><i className="icon fa fa-th-list"></i> Listing</Link>
                        </li>
                        <li>
                            <Link className={classNames("treeview-item", {'active' : (path===Path.type )})} to={Path.type}><i className="icon fa fa-th-list"></i> Types</Link>
                        </li>
                        <li>
                            <Link className={classNames("treeview-item", {'active' : (path===Path.model )})} to={Path.model}><i className="icon fa fa-th-list"></i> Models</Link>
                        </li>
                        <li>
                            <Link className={classNames("treeview-item", {'active' : (path===Path.brand )})} to={Path.brand}><i className="icon fa fa-th-list"></i> Brands</Link>
                        </li>
                    </ul>
                </li>
                {/* <li>
                    <Link className={classNames("app-menu__item", {'active' : (path===Path.employment)})} to={Path.employment}><i className="app-menu__icon fa fa-file"></i><span className="app-menu__label">Employment</span>
                    </Link>
                </li> */}
                <li className={`treeview ${showDropDown8 ? 'is-expanded' : ''}`} onClick={handleToggleDropDown8}>
                    <span className={classNames("app-menu__item", {'active' : (path===Path.employment  || path===Path.employment_category || path===Path.employment_subcategory )})}>
                    <i className="app-menu__icon fa fa-file"></i>
                    <span className="app-menu__label">Employment</span>
                    <i className="treeview-indicator fa fa-angle-right"></i></span>
                    <ul className="treeview-menu">
                        <li>
                            <Link className={classNames("treeview-item", {'active' : (path===Path.employment )})} to={Path.employment}><i className="icon fa fa-th-list"></i> Listing</Link>
                        </li>
                        <li>
                            <Link className={classNames("treeview-item", {'active' : (path===Path.employment_category )})} to={Path.employment_category}><i className="icon fa fa-th-list"></i>  Category</Link>
                        </li>
                        <li>
                            <Link className={classNames("treeview-item", {'active' : (path===Path.employment_subcategory )})} to={Path.employment_subcategory}><i className="icon fa fa-th-list"></i> Sub Category</Link>
                        </li>
                    </ul>
                </li>
                <li className={`treeview ${showDropDown2 ? 'is-expanded' : ''}`} onClick={handleToggleDropDown2}>
                    <span className={classNames("app-menu__item", {'active' : (path===Path.realestate  || path===Path.realestate_type)})}>
                    <i className="app-menu__icon fa fa-file"></i>
                    <span className="app-menu__label">Real Estate</span>
                    <i className="treeview-indicator fa fa-angle-right"></i></span>
                    <ul className="treeview-menu">
                        <li>
                            <Link className={classNames("treeview-item", {'active' : (path===Path.realestate )})} to={Path.realestate}><i className="icon fa fa-th-list"></i> Listing</Link>
                        </li>
                        <li>
                            <Link className={classNames("treeview-item", {'active' : (path===Path.realestate_type )})} to={Path.realestate_type}><i className="icon fa fa-th-list"></i> Types</Link>
                        </li>
                    </ul>
                </li>
                <li className={`treeview ${showDropDown3 ? 'is-expanded' : ''}`} onClick={handleToggleDropDown3}>
                    <span className={classNames("app-menu__item", {'active' : (path===Path.thingstodo || path===Path.thingstodo_category  || path===Path.thingstodo_subcategory || path===Path.form_thingstodo_category || path===Path.form_thingstodo_subcategory || path===Path.form_things_todo_subcategory)})}>
                    <i className="app-menu__icon fa fa-file"></i>
                    <span className="app-menu__label">Entertainment</span>
                    <i className="treeview-indicator fa fa-angle-right"></i></span>
                    <ul className="treeview-menu">
                        <li>
                            <Link className={classNames("treeview-item", {'active' : (path===Path.thingstodo )})} to={Path.thingstodo}><i className="icon fa fa-th-list"></i>Listing</Link>
                        </li>
                        <li>
                            <Link className={classNames("treeview-item", {'active' : (path===Path.thingstodo_category )})} to={Path.thingstodo_category}><i className="icon fa fa-th-list"></i> Category</Link>
                        </li>
                        <li>
                            <Link className={classNames("treeview-item", {'active' : (path===Path.thingstodo_subcategory )})} to={Path.thingstodo_subcategory}><i className="icon fa fa-th-list"></i> Sub Category</Link>
                        </li>
                    </ul>
                </li>
                <li className={`treeview ${showDropDown4 ? 'is-expanded' : ''}`} onClick={handleToggleDropDown4}>
                    <span className={classNames("app-menu__item", {'active' : (path===Path.traveltourism  || path===Path.traveltourism_subcategory || path===Path.form_travel_tourism_subcategory)})}>
                    <i className="app-menu__icon fa fa-file"></i>
                    <span className="app-menu__label">Travel & Tourisms</span>
                    <i className="treeview-indicator fa fa-angle-right"></i></span>
                    <ul className="treeview-menu">
                        <li>
                            <Link className={classNames("treeview-item", {'active' : (path===Path.traveltourism )})} to={Path.traveltourism}><i className="icon fa fa-th-list"></i> Listing</Link>
                        </li>
                        <li>
                            <Link className={classNames("treeview-item", {'active' : (path===Path.traveltourism_subcategory )})} to={Path.traveltourism_subcategory}><i className="icon fa fa-th-list"></i> Sub Category</Link>
                        </li>
                    </ul>
                </li>
                <li className={`treeview ${showDropDown5 ? 'is-expanded' : ''}`} onClick={handleToggleDropDown5}>
                    <span className={classNames("app-menu__item", {'active' : (path===Path.services  || path===Path.services_category || path===Path.services_subcategory || path===Path.form_services_category || path==Path.form_services_subcategory)})}>
                    <i className="app-menu__icon fa fa-file"></i>
                    <span className="app-menu__label">Services</span>
                    <i className="treeview-indicator fa fa-angle-right"></i></span>
                    <ul className="treeview-menu">
                        <li>
                            <Link className={classNames("treeview-item", {'active' : (path===Path.services )})} to={Path.services}><i className="icon fa fa-th-list"></i> Listing</Link>
                        </li>
                        <li>
                            <Link className={classNames("treeview-item", {'active' : (path===Path.services_category )})} to={Path.services_category}><i className="icon fa fa-th-list"></i>  Category</Link>
                        </li>
                        <li>
                            <Link className={classNames("treeview-item", {'active' : (path===Path.services_subcategory )})} to={Path.services_subcategory}><i className="icon fa fa-th-list"></i> Sub Category</Link>
                        </li>
                    </ul>
                </li>
                <li className={`treeview ${showDropDown6 ? 'is-expanded' : ''}`} onClick={handleToggleDropDown6}>
                    <span className={classNames("app-menu__item", {'active' : (path===Path.marketplacelist ||path===Path.marketplacelist || path===Path.marketplaceview || path===Path.marketplace_subcategory || path===Path.marketplace|| path===Path.marketplace_category)})}>
                    <i className="app-menu__icon fa fa-file"></i>
                    <span className="app-menu__label">Market Place</span>
                    <i className="treeview-indicator fa fa-angle-right"></i></span>
                    <ul className="treeview-menu">
                        <li>
                            <Link className={classNames("treeview-item", {'active' : (path===Path.marketplacelist || path===Path.marketplaceview)})} to={Path.marketplacelist}><i className="icon fa fa-th-list"></i> Listing</Link>
                        </li>
                        <li>
                            <Link className={classNames("treeview-item", {'active' : (path===Path.marketplace )})} to={Path.marketplace}><i className="icon fa fa-th-list"></i> Category</Link>
                        </li>
                        <li>
                            <Link className={classNames("treeview-item", {'active' : (path===Path.marketplace_subcategory )})} to={Path.marketplace_subcategory}><i className="icon fa fa-th-list"></i> Sub Category</Link>
                        </li>
                    </ul>
                </li>
                <li>
                    <Link className={classNames("app-menu__item", {'active' : (path===Path.coupon || path===Path.formcoupon || path===Path.form_coupon)})} to={Path.coupon}><i className="app-menu__icon fa fa-file"></i><span className="app-menu__label">Shoppers</span>
                    </Link>
                </li>
                <li>
                    <Link className={classNames("app-menu__item", {'active' : (path===Path.spotted_deals || path===Path.viewspotted_deals || path===Path.view_spotted_deals)})} to={Path.spotted_deals}><i className="app-menu__icon fa fa-file"></i><span className="app-menu__label">Spotted Deals</span>
                    </Link>
                </li>
                <li>
                    <Link className={classNames("app-menu__item", {'active' : (path===Path.contactus || path===Path.contactus_view || path===Path.contactusview)})} to={Path.contactus}><i className="app-menu__icon fa fa-address-book"></i><span className="app-menu__label">Contact Us</span>
                    </Link>
                </li>
                {/* <li>
                    <Link className={classNames("app-menu__item", {'active' : (path===Path.subscription || path===Path.subscription_view || path===Path.subscriptionview || path===Path.formsubscription || path===Path.form_subscription )})} to={Path.subscription}><i className="app-menu__icon fa fa-file"></i><span className="app-menu__label">Subscription</span>
                    </Link>
                </li> */}
                <li>
                    <Link className={classNames("app-menu__item", {'active' : (path===Path.store_subscription || path===Path.store_subscription_view || path===Path.store_subscriptionview || path===Path.store_formsubscription || path===Path.store_form_subscription )})} to={Path.store_subscription}><i className="app-menu__icon fa fa-file"></i><span className="app-menu__label">Store & Deals Subscription</span>
                    </Link>
                </li>
                <li>
                    <Link className={classNames("app-menu__item", {'active' : (path===Path.blocked_user || path===Path.blockeduserview || path===Path.blocked_userview)})} to={Path.blocked_user}><i className="app-menu__icon fa fa-file"></i><span className="app-menu__label">Blocked User</span>
                    </Link>
                </li>
                <li>
                    <Link className={classNames("app-menu__item", {'active' : (path===Path.reported_user || path===Path.reporteduserview || path===Path.reporteduser_view)})} to={Path.reported_user}><i className="app-menu__icon fa fa-file"></i><span className="app-menu__label">Reported User</span>
                    </Link>
                </li>
                <li>
                    <Link className={classNames("app-menu__item", {'active' : (path===Path.setting)})} to={Path.setting}><i className="app-menu__icon fa fa-cogs"></i><span className="app-menu__label">Setting</span>
                    </Link>
                </li>
            </ul>
        </aside>
        </>
    )
}

const mapStateToPros = (state, props) => {
    return{
        isAuthenticated: state.Auth.isAuthenticated,
        loader: state.persistStore.loader,
        userProfile: state.persistStore.editSetting,
        user: state.Auth,
    }
};

export default connect(
  mapStateToPros,
)(Sidebar);
