import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import * as Path from "routes/paths";
import AuthService from "services";
import swal from "sweetalert";
import * as actions from "store/actions";
import env from "env.json";
import { Field, reduxForm, reset } from "redux-form";
import LoadingButton from "components/shared/LoadingButton";
import ImageUploading from "react-images-uploading";
import ImageUploader from "react-images-upload";
import moment from "moment";
import { List } from "react-content-loader";
import PlacesAutocomplete from "./PlaceAutoComplete"
const renderField = ({
  input,
  placeholder,
  type,
  disabled,
  min,
  meta: { touched, error, warning },
}) => (
  <>
    <input
      {...input}
      type={type}
      min={min}
      placeholder={placeholder}
      disabled={disabled}
      className="form-control"
    />

    {touched && error && <span className="errorMessage">{error}</span>}
  </>
);

const renderFieldTextArea = ({
  input,
  placeholder,
  type,
  disabled,
  meta: { touched, error, warning },
}) => (
  <>
    <textarea
      {...input}
      type={type}
      placeholder={placeholder}
      disabled={disabled}
      className="form-control"
    />

    {touched && error && <span className="errorMessage">{error}</span>}
  </>
);

const FormCoupon = (props) => {
  //history
  const [selectedLocation, setSelectedLocation] = useState("");
  console.log('selected location ==============>' , selectedLocation);
  const history = useHistory();

  let editkey = props && props.match.params.id;
  const { handleSubmit, submitting } = props;
  const todayDate = moment().format("YYYY-MM-DD");
  const [sending, setSending] = useState(false);
  const MODULE_NAME = "coupon";
  //maincategories
  const [mainCategories, setMainCategories] = useState([]);
  const [mainCat, setMainCat] = useState({
    main_cat_id: "",
  });

  const [values, setValues] = useState({
    category_id: "",
    title: "",
    coupon_code: "",
    expiry_date: "",
    location: "",
    description: "",
  });
  const [catArr, setCatArr] = useState([
    "Market Place",
    "Shoppers",
    "Spotted Deals",
  ]);
  const [users, setUsers] = useState([]);
  const [userBusiness, setUserBusiness] = useState({
    user_id: "",
  });

  //handle change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setMainCat({
      ...mainCat,
      [name]: value,
    });
  };

  //images
  const [images, setImages] = useState([]);
  const maxNumber = 1;

  const onChange = (imageList) => {
    setImages(imageList);
  };

  const [pdf, setPdf] = useState(null);
  const [dbPdf, setdbPdf] = useState({});
  const [pdfName, setPdfName] = useState("");

  const editModal = async () => {
    let query = {
      MODULE_NAME,
      id: editkey,
    };
    setSending(true);
    await props.dispatch(AuthService.getDetail(query)).then((res) => {
      setSending(false);
      props.dispatch(actions.persist_store({ editFormCoupon: res.body }));
      //set images pdf
      let newImg = [];
      newImg.push(res.body.image);
      setImages(newImg);
      setdbPdf(res.body.pdf);
      setMainCat(res.body.category_id._id);
      setValues(res.body);
      setUserBusiness(res.body.user_id);
    });
  };

  //get users
  async function getUsers() {
    try {
      let query = {
        MODULE_NAME,
      };
      setSending(true);
      await props.dispatch(AuthService.getUserBusiness(query)).then((res) => {
        setSending(false);
        setUsers(res.body);
      });
    } catch (err) {
      setSending(false);
      console.log(err);
      if (err && err.data && err.data.message) {
        swal("Oops!", err.data.message, "error");
      }
    }
  }

  useEffect(() => {
    if (editkey) {
      editModal();
    }
    getUsers();
    getMainCategories();
  }, []);

  const getMainCategories = async () => {
    let query = {
      MODULE_NAME: "banners",
      API_NAME: "main_categories",
    };
    setSending(true);
    await props.dispatch(AuthService.getHomeBannerCat(query)).then((res) => {
      setSending(false);
      let arr = res.body;
      arr = arr.filter((data) => {
        return !catArr.includes(data.name);
      });
      setMainCategories(arr);
    });
  };

  //profile image
  const uploadPdf = (picture, base64) => {
    console.log("picture ==============>", picture);
    setPdfName(picture[0].name);
    setPdf(picture[0]);
  };

  //submit function
  const handleSubmit_ = async (values) => {
    try {
      const formData = new FormData();
      const formData2 = new FormData();
      let query = {
        MODULE_NAME,
        title: values.title,
        category_id: mainCat.category_id ? mainCat.category_id : mainCat,
        coupon_code: values.coupon_code,
        expiry_date: values.expiry_date,
        location: selectedLocation, //values.location,
        description: values.description,
        image: images.length > 0 && images[0]["data_url"] ? [...images] : "",
        // pdf: pdf.length > 0 && pdf[0]['data_url'] ? [...pdf] : ''
      };
      // console.log(query, "query")
      // console.log(values, "values")
      if (images.length > 0 && images[0]["data_url"]) {
        for (let key in images) {
          if (images[key]["file"]) {
            formData.append("image", images[key]["file"]);
          }
        }

        formData.append("type", "image");
        formData.append("folder", "coupon");
        await props.dispatch(AuthService.fileUpload(formData)).then((res) => {
          let newImg = res.body;
          query.image = JSON.stringify(newImg);
        });
      }

      if (pdf != null) {
        formData2.append("image", pdf);
        formData2.append("type", "pdf");
        formData2.append("folder", "coupon");
        await props.dispatch(AuthService.fileUpload(formData2)).then((res) => {
          let newPdf = res.body;
          query.pdf = JSON.stringify(newPdf);
        });
      }

      setSending(true);
      if (editkey) {
        query.coupon_id = editkey;
        await props.dispatch(AuthService.updateData(query)).then((res) => {
          setSending(false);
          swal("Success!", res.message, "success");
          setValues({
            category_id: "",
            title: "",
            coupon_code: "",
            expiry_date: "",
            location: "",
            description: "",
          });
          props.dispatch(actions.persist_store({ editFormCoupon: "" }));
          props.dispatch(reset("formCoupon"));
          history.push(Path.coupon);
        });
      } else {
        await props.dispatch(AuthService.createData(query)).then((res) => {
          setSending(false);
          swal("Success!", res.message, "success");
          setValues({
            title: "",
            category_id: "",
            coupon_code: "",
            expiry_date: "",
            location: "",
            description: "",
          });
          props.dispatch(actions.persist_store({ editFormCoupon: "" }));
          props.dispatch(reset("formCoupon"));
          history.push(Path.coupon);
        });
      }
    } catch (err) {
      console.log(err);
      setSending(false);
      if (err && err.data && err.data.message) {
        swal("Oops!", err.data.message, "error");
      }
    }
  };
  //render pdf
  const renderPDF = (name, buttonText) => {
    let defaultPDF = "";
    if (dbPdf && name === "image") {
      defaultPDF =
        dbPdf && dbPdf.original ? env.SERVER_URL + dbPdf.original : "";
    }
    return (
      <div className="profile_images_boxs pdf-design">
        <ImageUploader
          name={name}
          withIcon={false}
          withPreview={false}
          singleImage={true}
          buttonText={buttonText}
          onChange={(picture) =>
            Array.isArray(picture) && picture[0]
              ? uploadPdf(picture, name)
              : null
          }
          imgExtension={[".pdf"]}
          fileSizeError="file size is too big"
          accept="accept=pdf/*"
          maxFileSize={100000000}
          label={
            <div className="label-image-profile">{pdfName ? pdfName : ""}</div>
          }
          //  defaultImages={ defaultImage ? [defaultImage] : [] }
        />
        {dbPdf && dbPdf.original && (
          <>
            <p>
              <a
                href={
                  dbPdf && dbPdf.original
                    ? env.SERVER_URL + dbPdf.original
                    : "#"
                }
                target="_blank"
              >
                click here to view pdf
              </a>
            </p>
          </>
        )}
      </div>
    );
  };

  // render form
  const _renderForm = () => {
    return (
      <>
        <form onSubmit={handleSubmit(handleSubmit_)}>
          <div className="card-body">
            <div className="row">
              <div className="col-md-12" style={{ marginBottom: 20 }}>
                <h3 className="card-title">
                  <b>{editkey ? "Edit" : "Add"} Shopper </b>
                </h3>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label for="exampleSelect1">Select Categories</label>
                  <select
                    name="category_id"
                    value={mainCat.category_id}
                    onChange={handleChange}
                    className={`form-control`}
                    required
                    disabled={editkey ? "disabled" : ""}
                  >
                    <option value="">Select Category</option>
                    {mainCategories.length > 0 &&
                      mainCategories.map((item, key) => {
                        return (
                          <option
                            key={key}
                            value={item._id}
                            selected={item._id == mainCat}
                            disabled={editkey ? "disabled" : ""}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label for="exampleInputEmail1">Title</label>
                  <Field name="title" component={renderField} type="text" />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label for="exampleInputEmail1">Coupon code</label>
                  <Field
                    name="coupon_code"
                    component={renderField}
                    type="text"
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label for="exampleInputEmail1">Expiry Date</label>
                  <Field
                    name="expiry_date"
                    component={renderField}
                    min={todayDate}
                    type="date"
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label for="exampleInputEmail1">Location</label>
                  {/* <Field name="location" component={renderField} type="text" /> */}
                  <PlacesAutocomplete setSelectedLocation={setSelectedLocation}/>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label for="exampleInputEmail1">Description</label>
                  <Field
                    name="description"
                    component={renderFieldTextArea}
                    type="text"
                  />
                </div>
              </div>
            </div>
            <label for="exampleInputEmail1">Images</label>
            <ImageUploading
              multiple
              value={images}
              onChange={onChange}
              maxNumber={maxNumber}
              dataURLKey="data_url"
            >
              {({
                imageList,
                onImageUpload,
                // onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
              }) => (
                // write your building UI
                <>
                  <div
                    className="form-row"
                    style={isDragging ? { color: "red" } : undefined}
                    onClick={onImageUpload}
                    {...dragProps}
                  >
                    <img
                      src="/assets/images/upld.png"
                      onClick={() => onImageRemove(0)}
                      style={{ height: "70px", height: "70px" }}
                    />
                  </div>
                  <div className="row">
                    {images.length > 0 &&
                      images.map((image, index) => {
                        // <>
                        {
                          /* {image.length>0 && image.map((item, index) => { */
                        }
                        let imgData;
                        if (image && image["data_url"]) {
                          imgData = image["data_url"];
                        } else {
                          imgData = env.SERVER_URL + image.original;
                        }
                        return (
                          <div className="image-item" key={index}>
                            <div
                              className="close-img-item"
                              onClick={() => onImageRemove(index)}
                            >
                              <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                            <img
                              src={imgData}
                              alt=""
                              style={{ width: "50px", height: "50px" }}
                            />
                          </div>
                        );
                        {
                          /* })} */
                        }
                        // </>
                      })}
                  </div>
                </>
              )}
            </ImageUploading>

            <div style={{ textAlign: "center" }}>
              <label for="exampleInputEmail1">Upload pdf here</label>
            </div>

            {renderPDF(
              "pdf",
              <img src="/assets/images/upld.png" className="img-user" />
            )}

            <div className="row">
              <div
                className="col-4 loading--submit-button"
                style={{ marginTop: "20px" }}
              >
                <LoadingButton
                  type="submit"
                  className="btn btn-primary"
                  loading={sending}
                  disabled={submitting}
                >
                  {editkey ? "Update" : "Submit"}
                </LoadingButton>
              </div>
            </div>
          </div>
        </form>
      </>
    );
  };

  return (
    <>
      <Helmet title="Shopper" />
      <div className="app-title">
        <div>
          <h1>
            <i className="fa fa-th-list"></i> Shopper
          </h1>
        </div>
      </div>
      {sending && (
        <List
          style={{ width: "100%", padding: 10 }}
          speed={2}
          height={150}
          backgroundColor="#f3f3f3"
          foregroundColor="#c1c5c7"
          viewBox="30 0 380 30"
        />
      )}
      {!sending && mainCategories && (
        <div className="row">
          <div className="col-md-6">
            <div className="tile">
              <div className="tile-body">{_renderForm()}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const initialValues = (values) => {
  if (values) {
    return {
      ...values,
      title: values.title ? values.title : "",
      coupon_code: values.coupon_code ? values.coupon_code : "",
      expiry_date: values.expiry_date ? values.expiry_date : "",
      location: values.location ? values.location : "",
      description: values.description ? values.description : "",
    };
  }
};

const validate = (values) => {
  const errors = {};
  //title
  if (!values.category_id) {
    errors.category_id = "Required*";
  }
  if (!values.title) {
    errors.title = "Required*";
  }
  // if (values?.title?.trim().length==0) {
  //     errors.title = 'Required*'
  // }
  if (!values.coupon_code) {
    errors.coupon_code = "Required*";
  }
  if (!values.expiry_date) {
    errors.expiry_date = "Required*";
  }
  if (!values.location) {
    errors.location = "Required*";
  }
  if (!values.description) {
    errors.description = "Required*";
  }
  return errors;
};

const mapStateToProps = (state) => {
  return {
    initialValues: initialValues(state.persistStore.editFormCoupon),
    isAuthenticated: state.Auth.isAuthenticated,
    user: state.Auth,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

const CouponForm = reduxForm({
  form: "formCoupon",
  enableReinitialize: true,
  validate,
})(FormCoupon);

export default connect(mapStateToProps, mapDispatchToProps)(CouponForm);
