import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as Path from "routes/paths";
import AuthService from "services";
import swal from "sweetalert";
import * as actions from "store/actions";
import Toggle from "react-toggle";
import ReactToggle from "./ReactToggle.css";
import Pagination from "react-js-pagination";
import { List } from "react-content-loader";
import env from "env.json";
import { Field, reduxForm, reset } from "redux-form";
import SelectInput from "components/shared/SelectInput";
import TextInput from "components/shared/TextInput";
import LoadingButton from "components/shared/LoadingButton";
import Modal from "react-awesome-modal";

const renderField = ({
  input,
  placeholder,
  type,
  disabled,
  meta: { touched, error, warning },
}) => (
  <>
    <input
      {...input}
      type={type}
      placeholder={placeholder}
      disabled={disabled}
      className="form-control"
    />

    {touched && error && <span className="errorMessage">{error}</span>}
  </>
);

const MarketPlace = (props) => {
  //pagination
  const [activePage, setActivePage] = useState(1);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [itemsCountPerPage, setItemsCountPerPage] = useState(0);
  const [pageRange, setPageRange] = useState(5);

  //modal
  const [modal, setModal] = useState(false);
  const [editkey, setEditkey] = useState(null);
  const { handleSubmit, submitting } = props;

  const [sending, setSending] = useState(false);
  const [load, setLoad] = useState(false);
  const [data, setData] = useState([]);
  const MODULE_NAME = "marketplace_category";

  //search record
  const [sortby, setSortby] = useState(1);
  const [clearSearch, setClearSearch] = useState(false);
  const [values, setValues] = useState({
    name: "",
    search: "",
  });
  const [file, setFile] = useState({
    data: null,
    url: "",
  });
  //handle change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  // hnddle file change
  const handdleFileChange = (e) => {
    const fileData = e.target.files;
    const blobUrl = URL.createObjectURL(fileData[0]);
    setFile({
      url: blobUrl,
      data: fileData[0],
    });
  };
  //open modal
  const openModal = () => {
    setEditkey(null);
    setValues({
      name: "",
    });
    props.dispatch(actions.persist_store({ editMarketPlace: "" }));
    props.dispatch(reset("marketPlace"));
    setModal(true);
  };

  const editModal = async (id) => {
    let query = {
      MODULE_NAME,
      id: id,
    };
    setModal(true);
    setSending(true);
    await props.dispatch(AuthService.getDetail(query)).then((res) => {
      setSending(false);
      props.dispatch(actions.persist_store({ editMarketPlace: res.body }));
      setEditkey(res.body._id);
      setValues(res.body);
    });
  };

  //close modal
  const closeModal = () => {
    setEditkey(null);
    setModal(false);
    setValues({
      name: "",
    });
    props.dispatch(actions.persist_store({ editMarketPlace: "" }));
    props.dispatch(reset("marketPlace"));
  };

  //get thingsToDoSubcategory
  let isMounted = true;
  async function getData() {
    try {
      let search = clearSearch == false ? values.search : "";
      let query = {
        page: activePage,
        MODULE_NAME,
        sortby,
        search: search,
      };
      setLoad(true);
      await props.dispatch(AuthService.getList(query)).then((res) => {
        if (isMounted) {
          setLoad(false);
          setData(res.body.data);
          setItemsCountPerPage(res.body.per_page);
          setTotalItemsCount(res.body.total_count);
        }
      });
    } catch (err) {
      setLoad(false);
      console.log(err);
      if (err && err.data && err.data.message) {
        swal("Oops!", err.data.message, "error");
      }
    }
  }

  useEffect(() => {
    getData();
    return () => {
      isMounted = false;
    };
  }, [activePage, sortby, clearSearch]);

  const clearFilter = () => {
    setClearSearch(!clearSearch);
    setValues({
      search: "",
    });
  };

  //submit function
  const addData = async (values) => {
    if (!values?.name) {
      swal("Oops!", "Name is requires", "error");
      return;
    }
    const formData = new FormData();
    formData.MODULE_NAME = MODULE_NAME;
    formData.append("name", values?.name);
    if (file?.data && file?.data !== null) formData.append("image", file?.data);
    try {
      setSending(true);
      if (editkey) {
        formData.append("_id", editkey);
        await props.dispatch(AuthService.updateData(formData)).then((res) => {
          setSending(false);
          swal("Success!", res.message, "success");
          setValues({
            name: "",
          });
          setFile({ data: null, url: "" });
          getData();
          setEditkey(null);
          props.dispatch(reset("marketPlace"));
          setModal(false);
        });
      } else {
        if (file?.data === null) {
          swal("Oops!", "Image is required", "error");
          setSending(false);
          return;
        }
        await props.dispatch(AuthService.createData(formData)).then((res) => {
          setSending(false);
          swal("Success!", res.message, "success");
          setValues({
            name: "",
          });
          setFile({ data: null, url: "" });
          getData();
          setEditkey(null);
          props.dispatch(reset("marketPlace"));
          setModal(false);
        });
      }
    } catch (err) {
      console.log(err);
      setSending(false);
      if (err && err.data && err.data.message) {
        swal("Oops!", err.data.message, "error");
      }
    }
  };

  //update  status
  const updateStatus = async (id) => {
    try {
      let query = {
        id: id,
        MODULE_NAME,
      };
      await props.dispatch(AuthService.updateStatus(query)).then((res) => {
        swal("Success!", res.message, "success");
        getData();
      });
    } catch (err) {
      console.log(err);
      setSending(false);
      if (err && err.data && err.data.message) {
        swal("Oops!", err.data.message, "error");
      }
    }
  };

  const handlePageChange = (page) => {
    setActivePage(page);
    window.scrollTo(0, 0);
  };

  const handleSubmit_ = async (e) => {
    try {
      e.preventDefault();
      let query = {
        page: activePage,
        MODULE_NAME,
        sortby,
        search: values.search,
      };
      setLoad(true);
      setSending(true);
      await props.dispatch(AuthService.getList(query)).then((res) => {
        setLoad(false);
        setSending(false);
        setData(res.body.data);
      });
    } catch (err) {
      console.log(err);
      setSending(false);
      if (err && err.data && err.data.message) {
        swal("Oops!", err.data.message, "error");
      }
    }
  };

  // render form
  const _renderForm = () => {
    return (
      <>
        <form onSubmit={handleSubmit(addData)}>
          <div className="card-body">
            <div className="row">
              <div className="col-md-12" style={{ marginBottom: 20 }}>
                <h3 className="card-title">
                  <b>{editkey ? "Edit" : "Add"} Category</b>
                </h3>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label for="exampleInputEmail1">Category Name</label>
                  <Field
                    name="name"
                    component={renderField}
                    type="text"
                    required
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <input
                    type="file"
                    accept="image/*"
                    name="image"
                    component={renderField}
                    onChange={handdleFileChange}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-4 loading--submit-button">
                <LoadingButton
                  type="submit"
                  className="btn btn-primary"
                  loading={sending}
                  disabled={submitting}
                >
                  {editkey ? "Update" : "Submit"}
                </LoadingButton>
              </div>
            </div>
          </div>
        </form>
      </>
    );
  };

  return (
    <>
      <Helmet title="Market Place" />
      <div className="app-title">
        <div>
          <h1>
            <i className="fa fa-th-list"></i> MarketPlace Category
          </h1>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="tile">
            <div className="row">
              <div className="col-md-4">
                <h3 className="tile-title">MarketPlace Category Listing</h3>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <SelectInput
                    name="sort_by"
                    className="custom-select"
                    onChange={(value) => setSortby(value.target.value)}
                  >
                    <option value="" disabled>
                      Sort by{" "}
                    </option>
                    <option value="0">Asc</option>
                    <option value="1">Desc</option>
                  </SelectInput>
                </div>
              </div>
              <div className="col-md-4">
                <form onSubmit={handleSubmit_}>
                  <div className="input-group">
                    <TextInput
                      name="search"
                      className="form-control"
                      placeholder="Search by name..."
                      value={values.search}
                      onChange={handleChange}
                    ></TextInput>
                    <div className="input-group-append">
                      <LoadingButton
                        type="submit"
                        className="btn btn-primary"
                        loading={sending}
                      >
                        <i className="fa fa-search"></i>
                      </LoadingButton>
                    </div>
                    <div
                      className="input-group-append"
                      style={{ marginLeft: "3px" }}
                    >
                      <LoadingButton
                        type="button"
                        className="btn btn-danger"
                        onClick={clearFilter}
                      >
                        <i className="fa fa-times"></i>
                      </LoadingButton>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <span
                    onClick={() => openModal()}
                    className="btn btn-primary btn-sm"
                    title="Add"
                  >
                    <i className="fa fa-lg fa-plus"></i>Add
                  </span>
                </div>
              </div>
            </div>
            <Modal
              visible={modal}
              width="400px"
              height="245px"
              onClickAway={closeModal}
            >
              {_renderForm()}
            </Modal>
            {load && (
              <List
                style={{ width: "100%", padding: 10 }}
                speed={2}
                height={150}
                backgroundColor="#f3f3f3"
                foregroundColor="#c1c5c7"
                viewBox="30 0 380 30"
              />
            )}

            {!load && data.length > 0 && (
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Image</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 &&
                      data.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td>{item.name}</td>
                            <td>
                              <a
                                href={
                                  item?.image
                                    ? env.SERVER_URL + item?.image
                                    : "#"
                                }
                                target="_blank"
                              >
                                <img
                                  alt="Avatar"
                                  className="table-avatar profile-image-size"
                                  src={
                                    item?.image
                                      ? env.SERVER_URL + item?.image
                                      : "/assets/images/dummy.png"
                                  }
                                />
                              </a>
                            </td>
                            <td>
                              <Toggle
                                id="cheese-status"
                                checked={item.status == 1}
                                onClick={() => updateStatus(item._id)}
                              />
                            </td>
                            <td>
                              <span
                                style={{ marginLeft: "10px" }}
                                className="btn btn-warning btn-sm"
                                title="Edit"
                                onClick={() => {
                                  editModal(item._id);
                                }}
                              >
                                <i className="fa fa-lg fa-edit"></i>
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                  <div className="pagination-box-review">
                    {totalItemsCount > itemsCountPerPage && (
                      <Pagination
                        activePage={activePage}
                        itemsCountPerPage={itemsCountPerPage}
                        totalItemsCount={totalItemsCount}
                        pageRangeDisplayed={pageRange}
                        onChange={(page) => handlePageChange(page)}
                        itemClass="page-item"
                        linkClass="page-link"
                      />
                    )}
                  </div>
                </table>
              </div>
            )}
            {!load && data.length == 0 && (
              <div className="row">
                <div className="col-md-6 not-found-detail">
                  <h6>Data not found....</h6>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const initialValues = (values) => {
  if (values) {
    return {
      ...values,
      name: values.name ? values.name : "",
    };
  }
};

const validate = (values) => {
  const errors = {};
  //name
  if (!values.name) {
    errors.name = "Required*";
  }
  return errors;
};

const mapStateToProps = (state) => {
  return {
    initialValues: initialValues(state.persistStore.editMarketPlace),
    isAuthenticated: state.Auth.isAuthenticated,
    user: state.Auth,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

const MarketPlaceForm = reduxForm({
  form: "marketPlace",
  enableReinitialize: true,
  validate,
})(MarketPlace);

export default connect(mapStateToProps, mapDispatchToProps)(MarketPlaceForm);
