import React, {useState, useEffect} from "react";
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import * as Path from 'routes/paths';
import AuthService from 'services';
import swal from 'sweetalert';
import env from 'env.json';
import { Link } from 'react-router-dom';
import { List }  from 'react-content-loader';

const CarView = (props) => {

    const [sending, setSending] = useState(false);
    const [data, setData] = useState({});
    const [users, setUsers] = useState({});
    const [types, setTypes] = useState({});
    const [models, setModels] = useState({});
    const [brands, setBrands] = useState({});
    const MODULE_NAME = 'cars';

    //get data
    let isMounted = true;
    async function getData() {
        try{
            let query = {
                id: props.match.params.id,
                MODULE_NAME
            };
            setSending(true);
            await props.dispatch(AuthService.getDetail(query)).then((res) => {
                if(isMounted){
                    setSending(false);
                    setData(res.body);
                    setUsers(res.body.user_id);
                    setTypes(res.body.brand_id);
                    setModels(res.body.model_id);
                    setBrands(res.body.type_id);
                }
            });
        }catch(err) {
            setSending(false)
            console.log(err);
            if(err && err.data && err.data.message){
                swal("Oops!", err.data.message, "error");
            }
        }
    };
  
    useEffect(() => {
        getData();
        return () => {
            isMounted = false;
        };
    }, []);

    return(
        <>
            <Helmet title="Cars" />
            <div className="app-title">
                <div>
                <h1><i className="fa fa-th-list"></i> Car Detail</h1>
                </div>
            </div>
            {sending && 
                <List  
                    style={{ width: '100%', padding: 10, }} 
                    speed={2}
                    height={150}
                    backgroundColor="#f3f3f3"
                    foregroundColor="#c1c5c7"
                    viewBox="30 0 380 30"
                />
            }
            
            {(!sending && data)  &&
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="tile">
                                    <div className="tile-body">Car Info : </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <ul className="list-group list-group-unbordered mb-3">
                                    <li className="list-group-item">
                                        <b>Condition</b> <span className="float-right">{data.condition==1 ? 'New' : 'Used'}</span>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Location</b> <span className="float-right">{data.location}</span>
                                    </li>
                                    {(data && brands.name) &&
                                    <li className="list-group-item">
                                        <b>Brand</b> <span className="float-right">{brands.name}</span>
                                    </li>
                                    }
                                    {(data && models.name) &&
                                    <li className="list-group-item">
                                        <b>Model</b> <span className="float-right">{models.name}</span>
                                    </li>
                                    }
                                    {(data && types.name) &&
                                    <li className="list-group-item">
                                        <b>Type</b> <span className="float-right">{types.name}</span>
                                    </li>
                                    }
                                    <li className="list-group-item">
                                        <b>Price</b> <span className="float-right">$ {data.total_price}</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-6">
                                <div className="card card-primary card-outline">
                                    <div className="card-body box-profile">
                                        <div class="timeline-post">
                                            <div class="post-media">
                                                <div class="content">
                                                    <h5>Description</h5>
                                                </div>
                                            </div>
                                            <div class="post-content">
                                            <p>{data.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="tile">
                                <b>Place Images:</b><hr/>
                                    <div className="tile-body">
                                        <div class="row main-image-div">
                                            {data && data.images && data.images.length>0  && data.images.map((item, i) => {
                                                return(
                                                    <div class="col-lg-4">
                                                        <div class="bs-component">
                                                        <a href={item.original ? env.SERVER_URL+item.original : '#'} target="_blank">
                                                            <img src={env.SERVER_URL+item.original} style={{height:"80px", width:"80px"}}/>
                                                        </a>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="tile">
                                <b>Gallery Images:</b><hr/>
                                    <div className="tile-body">
                                        <div class="row main-image-div">
                                            {data && data.gallery_images && data.gallery_images.length>0  && data.gallery_images.map((item, i) => {
                                                return(
                                                    <div class="col-lg-4">
                                                        <div class="bs-component">
                                                        <a href={item.original ? env.SERVER_URL+item.original : '#'} target="_blank">
                                                            <img src={env.SERVER_URL+item.original} style={{height:"80px", width:"80px"}}/>
                                                        </a>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                        {data && data.image_logo &&                    
                        <div className="row">
                            <div className="col-md-6">
                                <div className="tile">
                                <b>Image Logo:</b><hr/>
                                    <div className="tile-body">
                                        <div class="row">
                                            <div class="col-lg-4">
                                                <div class="bs-component">
                                                <a href={data.image_logo.original ? env.SERVER_URL+data.image_logo.original : '#'} target="_blank">
                                                    <img src={env.SERVER_URL+data.image_logo.original} style={{height:"80px", width:"80px"}}/>
                                                </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                        {(data && data.user_id) &&
                        <div className="row">
                            <div className="col-md-12">
                                <div className="tile">
                                    <div className="tile-body">User Info : </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <ul className="list-group list-group-unbordered mb-3">
                                    <li className="list-group-item">
                                        <b>First Name</b> <span className="float-right">{users.first_name}</span>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Last Name</b> <span className="float-right">{users.last_name}</span>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Email</b> <span className="float-right">{users.email}</span>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Contact</b> <span className="float-right">{users.country_code}{users.phone}</span>
                                    </li>
                                </ul>
                            </div>
                            {(users.image && users.image.original) &&
                            <div className="col-md-6">
                                <div className="tile">
                                <b>Image:</b><hr/>
                                    <div className="tile-body">
                                        <div class="row">
                                            <div class="col-lg-4">
                                                <div class="bs-component">
                                                <a href={users.image.original ? env.SERVER_URL+users.image.original : '#'} target="_blank">
                                                    <img src={env.SERVER_URL+users.image.original} style={{height:"80px", width:"80px"}}/>
                                                </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                        }

                        <div className="row">
                            <div className="col-md-2" style={{marginTop:"10px"}}>        
                                <Link to={Path.cars} class="btn btn-primary btn-block"><b>Back</b></Link>
                            </div>
                        </div>
                    </div>
                </section> 
            }  

        </>
    )
}

const mapStateToProps = (state) => {
    return{
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CarView);
