import React, {useState, useEffect} from "react";
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import * as Path from 'routes/paths';
import AuthService from 'services';
import swal from 'sweetalert';
import env from 'env.json';
import { Link } from 'react-router-dom';
import { List }  from 'react-content-loader';
import moment from "moment";

const AddsView = (props) => {
    
    const [sending, setSending] = useState(false);
    const [data, setData] = useState({});
    const [users, setUsers] = useState({});
    const [category, setCategory] = useState({});
    const MODULE_NAME = 'adds';

    function checkFileType(item) {
        // Get the file extension by splitting the string at the dot (.)
        const fileExtension = item.split('.').pop().toLowerCase();
      
        // Define an array of common image file extensions
        const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
      
        // Check if the file extension is in the list of image extensions
        if (imageExtensions.includes(fileExtension)) {
          return 'image';
        } else {
          return 'video';
        }
      }

    //get data
    let isMounted = true;
    async function getData() {
        try{
            let query = {
                id: props.match.params.id,
                MODULE_NAME
            };
            setSending(true);
            await props.dispatch(AuthService.getDetail(query)).then((res) => {
                console.log(res.body.get_detail, "res.body.get_detail")
                if(isMounted){
                    setSending(false);
                    setData(res.body.get_detail);
                    setUsers(res.body.get_detail.user_id);
                    setCategory(res.body.get_detail.main_category_id);
                }
            });
        }catch(err) {
            setSending(false)
            console.log(err);
            if(err && err.data && err.data.message){
                swal("Oops!", err.data.message, "error");
            }
        }
    };
  
    useEffect(() => {
        getData();
        return () => {
            isMounted = false;
        };
    }, []);
    return(
        <>
        <Helmet title="Ads" />
        <div className="app-title">
                <div>
                <h1><i className="fa fa-folder"></i> Ads Detail</h1>
                </div>
            </div>

            {sending && 
                <List  
                    style={{ width: '100%', padding: 10, }} 
                    speed={2}
                    height={150}
                    backgroundColor="#f3f3f3"
                    foregroundColor="#c1c5c7"
                    viewBox="30 0 380 30"
                />
            }
            {(!sending && data)  &&
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="tile">
                                    <div className="tile-body">Ads Info : </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="card card-primary card-outline">
                                    <div className="card-body box-profile">
                                        <div class="timeline-post">
                                            <div class="post-media">
                                                <div class="content">
                                                    <h5>App Url</h5>
                                                </div>
                                            </div>
                                            <div class="post-content">
                                            <p>{data.title}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <ul className="list-group list-group-unbordered mb-3">
                                    {(data && data.start_date) &&
                                        <li className="list-group-item">
                                            <b>Start Date</b> <span className="float-right">{moment(data.start_date).format("MM/DD/YYYY")}</span>
                                        </li>
                                    }
                                    {(data && data.end_date) &&
                                        <li className="list-group-item">
                                            <b>End Date</b> <span className="float-right">{moment(data.end_date).format("MM/DD/YYYY")}</span>
                                        </li>
                                    }
                                    {(data && data.main_category_id && category.name) &&
                                    <li className="list-group-item">
                                        <b>Main Category Name</b> <span className="float-right">{category.name}</span>
                                    </li>
                                    }
                                    {(data && data.category_id && data.cat_model_name) &&
                                    <li className="list-group-item">
                                        <b>Category Name</b> <span className="float-right">{data.cat_model_name}</span>
                                    </li>
                                    }
                                    {(data && data.sub_category_id && data.subcat_model_name) &&
                                    <li className="list-group-item">
                                        <b>SubCategory Name</b> <span className="float-right">{data.subcat_model_name}</span>
                                    </li>
                                    }
                                    {(data && data.user_id && users.is_subscribed==1) &&
                                     <li className="list-group-item">
                                        <b>Subscription Status</b> <span className="float-right">{users.is_subscribed==1 ? 'Active' : 'Inactive'}</span>
                                    </li>
                                    }
                                </ul>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-md-6">
                                <div className="tile">
                                <b>Images:</b><hr/>
                                    <div className="tile-body">
                                        <div class="row main-image-div">
                                            {data && data.image && data.image.length>0  && data.image.map((item, i) => {
                                                if (!item.hasOwnProperty('isImage')) {
                                                    item.isImage = item.original.split(".")[1];
                                                }
                                                return(
                                                    <div class="col-lg-4">
                                                        <div class="bs-component">
                                                       
                                                        <a href={item.original ? env.SERVER_URL+item.original : '#'} target="_blank">
                                                        {checkFileType(item.isImage) === "image" ? (
                                                              <img src={env.SERVER_URL+item.original} style={{height:"80px", width:"80px"}}/>
                                                        ) : (
                                                            <video className="videoShowBox" controls src={env.SERVER_URL+item.original}  />
                                                        )}
                                                          
                                                        </a>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {(data && data.user_id) &&
                        <div className="row">
                            <div className="col-md-12">
                                <div className="tile">
                                    <div className="tile-body">User Info : </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <ul className="list-group list-group-unbordered mb-3">
                                    <li className="list-group-item">
                                        <b>First Name</b> <span className="float-right">{users.first_name}</span>
                                    </li>
                                    {users && users.last_name &&
                                    <li className="list-group-item">
                                        <b>Last Name</b> <span className="float-right">{users.last_name}</span>
                                    </li>
                                    }
                                    <li className="list-group-item">
                                        <b>Email</b> <span className="float-right">{users.email}</span>
                                    </li>
                                    {users && users.phone && 
                                    <li className="list-group-item">
                                        <b>Contact</b> <span className="float-right">{users.country_code}{users.phone}</span>
                                    </li>
                                    }
                                </ul>
                            </div>
                            {(users.image && users.image.original) &&
                            <div className="col-md-6">
                                <div className="tile">
                                <b>Image:</b><hr/>
                                    <div className="tile-body">
                                        <div class="row">
                                            <div class="col-lg-4">
                                                <div class="bs-component">
                                                <a href={users.image.original ? env.SERVER_URL+users.image.original : '#'} target="_blank">
                                                    <img src={env.SERVER_URL+users.image.original} style={{height:"80px", width:"80px"}}/>
                                                </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                        }
                        <div className="row">
                            <div className="col-md-2" style={{marginTop:"10px"}}>        
                                <Link to={Path.ads} class="btn btn-primary btn-block"><b>Back</b></Link>
                            </div>
                        </div>
                    </div>
                </section>   
            }
        </>
    )
};

const mapStateToProps = (state) => {
    return{
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddsView);

