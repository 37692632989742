import React, { useState, useEffect } from "react";
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Path from 'routes/paths';
import Helmet from 'react-helmet';
import { List } from 'react-content-loader';
import AuthService from 'services';
import swal from 'sweetalert';
import Http from 'Http';
import { getAlluserList, sendNotificaiton } from "services/authService";
import LoadingButton from 'components/shared/LoadingButton';

const SendNotification = () => {
    const dispatch = useDispatch()
    const [notificationText, setNotificationText] = useState('');
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [sending, setSending] = useState(false);
    const [load, setLoad] = useState(false);
    const [userListing, setUserListing] = useState([])

    const handleNotificationTextChange = (event) => {
        setNotificationText(event.target.value);
    };

    const handleUserSelection = (event) => {
        const selectedOptions = Array.from(event.target.selectedOptions, option => option.value);
        setSelectedUsers(selectedOptions);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Perform actions with notificationText and selectedUsers, e.g., sending notifications
        console.log('Notification Text:', notificationText);
        console.log('Selected Users:', selectedUsers);
        setLoad(true);
        dispatch(sendNotificaiton({
            text : notificationText,
            userIds : JSON.stringify(selectedUsers)
        })).then(res => {
            setLoad(false);
            swal("Success!", "Notification send successfully", "success");
            setNotificationText("")
            setSelectedUsers([])
             console.log("NOTIFICATION RESOPNSE RESPONSE" , res)
        }).catch(err => console.log("error while getting the response",err ))

    };




    useEffect(() => {
        setSending(true);
        dispatch(getAlluserList()).then(res => {
            setSending(false);
            if (res.success) {
                
                setUserListing(res.body)
            }
        }).catch(err =>{
            setSending(false);
            console.log("check the error", err)})
    },[])

    return (
        <>
            <Helmet title="Dashboard" />
            <div className="app-title">
                <div>
                    <h1><i className="fa fa-bell"></i>Send Notification</h1>
                </div>
            </div>
            {sending &&
                <List
                    style={{ width: '100%', padding: 10, }}
                    speed={2}
                    height={150}
                    backgroundColor="#f3f3f3"
                    foregroundColor="#c1c5c7"
                    viewBox="0 0 380 50"
                />
            }
            {!sending &&
                <div className="row">
                <div className="col-md-6">
                <div className="tile">
                <form onSubmit={handleSubmit}>
                <div className="form-group">
                <div className="form-group">
                <label htmlFor="userSelect">Select Users</label>
                <select
                multiple
                className="form-control"
                id="userSelect"
                value={selectedUsers}
                onChange={handleUserSelection}
                >
                {userListing && userListing.length > 0 && userListing.map((val, key) => {
                    return <option value={val._id}>{val.first_name}</option>
                })
                }
                {/* Add more options as needed */}
                </select>
                </div>
                <label htmlFor="notificationText">Notification</label>
                <textarea
                className="form-control"
                id="notificationText"
                rows="4"
                value={notificationText}
                onChange={handleNotificationTextChange}
                placeholder="Type your notification here"
                />
                </div>
                <div className="tile-footer">
                <LoadingButton
                    type="submit"
                    className="btn btn-primary"
                    loading={load}
                    >
                Send➡️
                </LoadingButton>
                </div>
                </form>

                </div>
                </div>
                </div>
            }
        </>
    )
}

export default SendNotification