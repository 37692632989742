import React, {useState, useEffect} from "react";
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import * as Path from 'routes/paths';
import AuthService from 'services';
import swal from 'sweetalert';
import * as actions from 'store/actions';
import env from 'env.json';
import { Field, reduxForm, reset } from 'redux-form';
import LoadingButton from 'components/shared/LoadingButton';
import ImageUploading from 'react-images-uploading';

const renderField = ({
    input,
    placeholder,
    type,
    disabled,
    meta: { touched, error, warning }
}) => (
    <>
        <input {...input} type={type} placeholder={placeholder} disabled={disabled} className="form-control"/>
        
        {touched &&
            (error && <span className="errorMessage">{error}</span>)
        }
    </>
)

const FormBanner = (props) => {

    //history
    const history = useHistory();
 
    const { handleSubmit, submitting} = props;
    
    const [sending, setSending] = useState(false);
    const MODULE_NAME = 'home_banner';

    //images
    const [ images, setImages] = useState([]);    
    const maxNumber = 1;

    //images
    const onChange = (imageList) => {
        setImages(imageList);
    };

    //submit function
    const handleSubmit_ = async (values) => {
        try{
            const formData = new FormData(); 
            let query = {
                MODULE_NAME,
                image: images.length > 0 && images[0]['data_url'] ? [...images] : ''
            };
            if(images.length > 0 && images[0]['data_url']){
                for ( let key in images ) {
                    if(images[key]['file']){
                        formData.append('image', images[key]['file']);
                    }
                }
                
                formData.append('type', 'image');
                formData.append('folder', 'homebanner');
                await props.dispatch(AuthService.fileUpload(formData)).then((res) => {
                    let newImg = res.body;
                    query.image = JSON.stringify(newImg)
                });
            }

            setSending(true);
            await props.dispatch(AuthService.createData(query)).then((res) => {
                setSending(false);
                swal("Success!", res.message, "success");
                props.dispatch(reset('formFormBanner'));
                history.push(Path.banner1);
            });
        }catch(err) {
            console.log(err);
            setSending(false);
            if(err && err.data && err.data.message){
                swal("Oops!", err.data.message, "error");
            }
        }
    }


    // render form
    const _renderForm = () => {
        return(
            <>
                <form onSubmit={handleSubmit(handleSubmit_)}>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12" style={{marginBottom:20}}>
                                <h3 className="card-title"><b>Add Home Banner</b></h3>
                            </div>
                        </div>
                        <label for="exampleInputEmail1">Image</label>
                        <ImageUploading
                            multiple
                            value={images}
                            onChange={onChange}
                            maxNumber={maxNumber}
                            dataURLKey="data_url"
                            >
                            {({
                                imageList,
                                onImageUpload,
                                // onImageRemoveAll,
                                onImageUpdate,
                                onImageRemove,
                                isDragging,
                                dragProps,
                            }) => (
                                // write your building UI
                                <>
                                <div className="form-row" style={isDragging ? { color: 'red' } : undefined}
                                    onClick={onImageUpload}
                                    {...dragProps}>
                                    <img src="/assets/images/upld.png" onClick={() => onImageRemove(0)} style={{height:'70px', height:'70px'}} />
                                </div>
                                <div className="row">
                                    {images.length>0 && images.map((image, index) => {
                                        let imgData;
                                        if(image['data_url']){
                                            imgData = image['data_url'];
                                        } else{
                                            imgData = env.SERVER_URL+image.original;
                                        }
                                        return(
                                        <div className="image-item" key={index}>
                                            <div className="close-img-item" onClick={() => onImageRemove(index)}>
                                                <i className="fa fa-times" aria-hidden="true"></i>
                                            </div>
                                            <img src={imgData} alt="" style={{width:'50px', height:'50px'}}/>
                                        </div>
                                        )
                                    })}
                                    
                                </div>
                                </>
                            )}
                        </ImageUploading> 

                        <div className="row">
                            <div className="col-4 loading--submit-button" style={{marginTop:'20px'}}>
                                <LoadingButton
                                    type="submit"
                                    className="btn btn-primary"
                                    loading={sending}
                                    disabled={submitting}
                                >
                                    Submit
                                </LoadingButton>
                            </div>
                        </div>  
                    </div>
                </form>
            </>
        )
    }

    return(
        <>
            <Helmet title="Home Banner" />
            <div className="app-title">
                <div>
                <h1><i className="fa fa-th-list"></i> Home Banner</h1>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="tile">
                        <div className="tile-body">
                            {_renderForm()}
                        </div>
                    </div>
                </div>
            </div>
            
        </>
    )
}

const mapStateToProps = (state) => {
    return{
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}


const FormBannerForm = reduxForm({
    form: 'formFormBanner',
    enableReinitialize: true,
})(FormBanner);

export default connect(mapStateToProps, mapDispatchToProps)(FormBannerForm);
