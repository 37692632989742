import React, {useState, useEffect} from "react";
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import * as Path from 'routes/paths';
import AuthService from 'services';
import swal from 'sweetalert';
import env from 'env.json';
import { Link } from 'react-router-dom';
import { List }  from 'react-content-loader';
import { saveAs } from "file-saver";

const EmploymentView = (props) => {

    const [sending, setSending] = useState(false);
    const [data, setData] = useState({});
    const [users, setUsers] = useState({});
    const MODULE_NAME = 'employment';

    //get data
    let isMounted = true;
    async function getData() {
        try{
            let query = {
                id: props.match.params.id,
                MODULE_NAME
            };
            setSending(true);
            await props.dispatch(AuthService.getDetail(query)).then((res) => {
                if(isMounted){
                    setSending(false);
                    setData(res.body);
                    setUsers(res.body.user_id);
                }
            });
        }catch(err) {
            setSending(false)
            console.log(err);
            if(err && err.data && err.data.message){
                swal("Oops!", err.data.message, "error");
            }
        }
    };
  
    useEffect(() => {
        getData();
        return () => {
            isMounted = false;
        };
    }, []);

    //download file here
    const saveFile = (item) => {
        saveAs(
          `${env.SERVER_URL+item.file.original}`,
          `${item.user_id?.first_name}-${item.file.file_type}`
        );
    };

    return(
        <>
            <Helmet title="Employment" />
            <div className="app-title">
                <div>
                <h1><i className="fa fa-th-list"></i> Employment Detail</h1>
                </div>
            </div>
            {sending && 
                <List  
                    style={{ width: '100%', padding: 10, }} 
                    speed={2}
                    height={150}
                    backgroundColor="#f3f3f3"
                    foregroundColor="#c1c5c7"
                    viewBox="30 0 380 30"
                />
            }
            
            {(!sending && data)  &&
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="tile">
                                    <div className="tile-body">Employment Info : </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <ul className="list-group list-group-unbordered mb-3">
                                <li className="list-group-item">
                                        <b>Job Profile</b> <span className="float-right">{data.about}</span>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Job Type</b> <span className="float-right">{data.job_type}</span>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Location</b> <span className="float-right">{data.location}</span>
                                    </li>
                                    {data.cat_id &&
                                    <li className="list-group-item">
                                        <b>Category</b> <span className="float-right">{data.cat_id.name}</span>
                                    </li>
                                    }
                                    {data.sub_cat_id &&
                                    <li className="list-group-item">
                                        <b>SubCategory</b> <span className="float-right">{data.sub_cat_id.name}</span>
                                    </li>
                                    }
                                </ul>
                            </div>
                            <div className="col-md-6">
                                <div className="card card-primary card-outline">
                                    <div className="card-body box-profile">
                                        <div class="timeline-post">
                                            <div class="post-media">
                                                <div class="content">
                                                    <h5>Description</h5>
                                                </div>
                                            </div>
                                            <div class="post-content">
                                            {data.description}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="tile">
                                <b>Images:</b><hr/>
                                    <div className="tile-body">
                                        <div class="row main-image-div">
                                            {data && data.images && data.images.length>0  && data.images.map((item, i) => {
                                                return(
                                                    <div class="col-lg-4">
                                                        <div class="bs-component">
                                                        <a href={item.original ? env.SERVER_URL+item.original : '#'} target="_blank">
                                                            <img src={env.SERVER_URL+item.original} style={{height:"80px", width:"80px"}}/>
                                                        </a>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card card-primary card-outline" style={{height: '94px'}}>
                                    <div className="card-body box-profile">
                                        <div class="timeline-post">
                                            <div class="post-media">
                                                <div class="content">
                                                    <h5>About</h5>
                                                </div>
                                            </div>
                                            <div class="post-content">
                                            <p>{data.about}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {data && data.image_logo &&                    
                        <div className="row">
                            <div className="col-md-6">
                                <div className="tile">
                                <b>Image Logo:</b><hr/>
                                    <div className="tile-body">
                                        <div class="row">
                                            <div class="col-lg-4">
                                                <div class="bs-component">
                                                <a href={data.image_logo.original ? env.SERVER_URL+data.image_logo.original : '#'} target="_blank">
                                                    <img src={env.SERVER_URL+data.image_logo.original} style={{height:"80px", width:"80px"}}/>
                                                </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                        {(data && data.user_id) &&
                        <div className="row">
                            <div className="col-md-12">
                                <div className="tile">
                                    <div className="tile-body">User Info : </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <ul className="list-group list-group-unbordered mb-3">
                                    <li className="list-group-item">
                                        <b>First Name</b> <span className="float-right">{users.first_name}</span>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Last Name</b> <span className="float-right">{users.last_name}</span>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Email</b> <span className="float-right">{users.email}</span>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Contact</b> <span className="float-right">{users.country_code}{users.phone}</span>
                                    </li>
                                </ul>
                            </div>
                            {(users.image && users.image.original) &&
                            <div className="col-md-6">
                                <div className="tile">
                                <b>Image:</b><hr/>
                                    <div className="tile-body">
                                        <div class="row">
                                            <div class="col-lg-4">
                                                <div class="bs-component">
                                                <a href={users.image.original ? env.SERVER_URL+users.image.original : '#'} target="_blank">
                                                    <img src={env.SERVER_URL+users.image.original} style={{height:"80px", width:"80px"}}/>
                                                </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                        }

                        {data?.search_list?.length>0 &&
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="tile">
                                        <div className="table-responsive">
                                            <table className="table table-striped">
                                                <thead>
                                                <tr>
                                                    <th>User name</th>
                                                    <th>File</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {data?.search_list?.length>0  && data?.search_list?.map((item, i) => {
                                                    return(
                                                        <tr key={i}>
                                                            <td>{item.user_id.first_name} {item.user_id.last_name}</td>
                                                            <td>
                                                                <span style={{cursor:'pointer', color: '#337dcb',fontWeight: 700}} onClick={() => saveFile(item)}>click here</span>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="row">
                            <div className="col-md-2" style={{marginTop:"10px"}}>        
                                <Link to={Path.employment} class="btn btn-primary btn-block"><b>Back</b></Link>
                            </div>
                        </div>
                    </div>
                </section> 
            }  

        </>
    )
}

const mapStateToProps = (state) => {
    return{
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EmploymentView);
