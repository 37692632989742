import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import * as Path from "routes/paths";
import AuthService from "services";
import swal from "sweetalert";
import * as actions from "store/actions";
import env from "env.json";
import { Field, reduxForm, reset } from "redux-form";
import LoadingButton from "components/shared/LoadingButton";
import ImageUploading from "react-images-uploading";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";

import "@reach/combobox/styles.css";

const renderField = ({
  input,
  placeholder,
  type,
  disabled,
  meta: { touched, error, warning },
}) => (
  <>
    <input
      {...input}
      type={type}
      placeholder={placeholder}
      disabled={disabled}
      className="form-control"
    />

    {touched && error && <span className="errorMessage">{error}</span>}
  </>
);

const FormServicesSubcategory = (props) => {
  //history
  const history = useHistory();

  let editkey = props && props.match.params.id;
  const { handleSubmit, submitting } = props;

  const [sending, setSending] = useState(false);
  const MODULE_NAME = "service_sub_category";

  const [values, setValues] = useState({
    name: "",
    category_id: "",
    lat: "",
    long: "",
  });

  const [category, setCategory] = useState({
    category_id: "",
  });

  const [location, setLocation] = useState([]);

  //categories
  const [categories, setCategories] = useState([]);

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete();

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = (val) => {
    setValue(val, false);
    clearSuggestions();
    // Get latitude and longitude via utility functions
    getGeocode({ address: val }).then((results) => {
      const { lat, lng } = getLatLng(results[0]);
      setLocation([lat, lng])
      console.log("📍 Coordinates: ", { lat, lng });
    });
  };

  const handleChange2 = (e) => {
    const { name, value } = e.target;
    setCategory({
      ...category,
      [name]: value,
    });
  };

  //images
  const [images, setImages] = useState([]);
  const maxNumber = 1;

  //images
  const onChange = (imageList) => {
    setImages(imageList);
  };

  const editModal = async () => {
    let query = {
      MODULE_NAME,
      id: editkey,
    };
    setSending(true);
    await props.dispatch(AuthService.getDetail(query)).then((res) => {
      setSending(false);
      props.dispatch(
        actions.persist_store({ editFormServicesSubcategory: res.body })
      );
      //set images
      let newImg = [];
      newImg.push(res.body.image);
      setImages(newImg);
      setValues(res.body);
      setCategory(res.body.category_id);
      getCategories();
    });
  };
  //get categories
  async function getCategories() {
    try {
      let query = {
        MODULE_NAME: "service_category",
      };
      setSending(true);
      await props.dispatch(AuthService.getCategory(query)).then((res) => {
        setSending(false);
        setCategories(res.body);
      });
    } catch (err) {
      setSending(false);
      console.log(err);
      if (err && err.data && err.data.message) {
        swal("Oops!", err.data.message, "error");
      }
    }
  }

  useEffect(() => {
    if (editkey) {
      editModal();
    }
    getCategories();
  }, []);

  //submit function
  const handleSubmit_ = async (values) => {
    try {
      const formData = new FormData();
      let query = {
        MODULE_NAME,
        name: values.name,
        category_id: category.category_id ? category.category_id : category._id,
        image: images.length > 0 && images[0]["data_url"] ? [...images] : "",
       lat : location[0],
       lng : location[1]
      };

      if (images.length > 0 && images[0]["data_url"]) {
        for (let key in images) {
          if (images[key]["file"]) {
            formData.append("image", images[key]["file"]);
          }
        }

        formData.append("type", "image");
        formData.append("folder", "services");
        await props.dispatch(AuthService.fileUpload(formData)).then((res) => {
          let newImg = res.body;
          query.image = JSON.stringify(newImg);
        });
      }
      setSending(true);
      if (editkey) {
        query._id = editkey;
        await props.dispatch(AuthService.updateData(query)).then((res) => {
          setSending(false);
          swal("Success!", res.message, "success");
          setValues({
            name: "",
          });
          setCategory({
            category_id: "",
          });
          props.dispatch(
            actions.persist_store({ editFormServicesSubcategory: "" })
          );
          props.dispatch(reset("formServicesSubcategory"));
          history.push(Path.services_subcategory);
        });
      } else {
        await props.dispatch(AuthService.createData(query)).then((res) => {
          setSending(false);
          swal("Success!", res.message, "success");
          setValues({
            name: "",
          });
          setCategory({
            category_id: "",
          });
          props.dispatch(
            actions.persist_store({ editFormServicesSubcategory: "" })
          );
          props.dispatch(reset("formServicesSubcategory"));
          history.push(Path.services_subcategory);
        });
      }
    } catch (err) {
      console.log(err);
      setSending(false);
      if (err && err.data && err.data.message) {
        swal("Oops!", err.data.message, "error");
      }
    }
  };

  // render form
  const _renderForm = () => {
    return (
      <>
        <form onSubmit={handleSubmit(handleSubmit_)}>
          <div className="card-body">
            <div className="row">
              <div className="col-md-12" style={{ marginBottom: 20 }}>
                <h3 className="card-title">
                  <b>{editkey ? "Edit" : "Add"} Subcategory</b>
                </h3>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label for="exampleInputEmail1">Name</label>
                  <Field name="name" component={renderField} type="text" />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label for="exampleSelect1">Categories</label>
                  <select
                    name="category_id"
                    value={category.category_id}
                    onChange={handleChange2}
                    className={`form-control`}
                  >
                    <option value="">Select Category</option>
                    {categories.length > 0 &&
                      categories.map((item, key) => {
                        return (
                          <option
                            key={key}
                            value={item._id}
                            selected={item._id == category._id}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label for="exampleInputEmail1">Location</label>
                  <Combobox onSelect={handleSelect} aria-labelledby="demo">
                    <ComboboxInput
                      value={value}
                      onChange={handleInput}
                      disabled={!ready}
                      className="form-control"
                      required
                    />
                    <ComboboxPopover>
                      <ComboboxList>
                        {status === "OK" &&
                          data.map(({ place_id, description }) => (
                            <ComboboxOption
                              key={place_id}
                              value={description}
                            />
                          ))}
                      </ComboboxList>
                    </ComboboxPopover>
                  </Combobox>
                </div>
              </div>
            </div>
            <label for="exampleInputEmail1">Image</label>
            <ImageUploading
              multiple
              value={images}
              onChange={onChange}
              maxNumber={maxNumber}
              dataURLKey="data_url"
            >
              {({
                imageList,
                onImageUpload,
                // onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
              }) => (
                // write your building UI
                <>
                  <div
                    className="form-row"
                    style={isDragging ? { color: "red" } : undefined}
                    onClick={onImageUpload}
                    {...dragProps}
                  >
                    <img
                      src="/assets/images/upld.png"
                      onClick={() => onImageRemove(0)}
                      style={{ height: "70px", height: "70px" }}
                    />
                  </div>
                  <div className="row">
                    {images.length > 0 &&
                      images.map((image, index) => {
                        let imgData;
                        if (image["data_url"]) {
                          imgData = image["data_url"];
                        } else {
                          imgData = env.SERVER_URL + image.original;
                        }
                        return (
                          <div className="image-item" key={index}>
                            <div
                              className="close-img-item"
                              onClick={() => onImageRemove(index)}
                            >
                              <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                            <img
                              src={imgData}
                              alt=""
                              style={{ width: "50px", height: "50px" }}
                            />
                          </div>
                        );
                      })}
                  </div>
                </>
              )}
            </ImageUploading>

            <div className="row">
              <div
                className="col-4 loading--submit-button"
                style={{ marginTop: "20px" }}
              >
                <LoadingButton
                  type="submit"
                  className="btn btn-primary"
                  loading={sending}
                  disabled={submitting}
                >
                  {editkey ? "Update" : "Submit"}
                </LoadingButton>
              </div>
            </div>
          </div>
        </form>
      </>
    );
  };

  return (
    <>
      <Helmet title="Services Subcategory" />
      <div className="app-title">
        <div>
          <h1>
            <i className="fa fa-th-list"></i> Services Subcategory
          </h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="tile">
            <div className="tile-body">{_renderForm()}</div>
          </div>
        </div>
      </div>
    </>
  );
};

const initialValues = (values) => {
  if (values) {
    return {
      ...values,
      name: values.name ? values.name : "",
    };
  }
};

const validate = (values) => {
  const errors = {};
  //name
  if (!values.name) {
    errors.name = "Required*";
  }
  return errors;
};

const mapStateToProps = (state) => {
  return {
    initialValues: initialValues(
      state.persistStore.editFormServicesSubcategory
    ),
    isAuthenticated: state.Auth.isAuthenticated,
    user: state.Auth,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

const FormServicesSubcategoryForm = reduxForm({
  form: "formServicesSubcategory",
  enableReinitialize: true,
  validate,
})(FormServicesSubcategory);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormServicesSubcategoryForm);
