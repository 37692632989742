import * as ActionTypes from 'store/action-types';
import Http from 'Http';

const initialState = {
    isAuthenticated: false,
    user: null,
    loader:false,
};

//login 
const authLogin = (state, payload) => {
  const { body } = payload;
  localStorage.setItem('access_token', body.access_token);
  localStorage.setItem('user', JSON.stringify(body));
  Http.defaults.headers.common.Authorization = `Bearer ${body.access_token}`;
  const stateObj = {
    ...state,
      isAuthenticated: true,
      user:body
    };
  return stateObj;
};

//check auth
const checkAuth = (state) => {
    const stateObj = {
      ...state,
      isAuthenticated: !!localStorage.getItem('access_token'),
      user: JSON.parse(localStorage.getItem('user')),
    };
    if (state.isAuthenticated) {
      Http.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'access_token',
      )}`;
  
    }
    return stateObj;
};

//logout
const authLogout = (state) => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('user');
  const stateObj = {
    ...state,
    isAuthenticated: false,
    user: null,
  };
  return stateObj;
};

//get profile
const getProfile = (state, payload) => {
  const { body } = payload;
  localStorage.setItem('access_token', body.access_token);
  localStorage.setItem('user', JSON.stringify(body));
  Http.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'access_token',
  )}`;
  const stateObj = {
    ...state,
    isAuthenticated: true,
    user:body,
  };
  return stateObj;
};

//file upload
const fileUpload = (state, payload) => {
  const { body } = payload;
  localStorage.setItem('access_token', body.access_token);
  localStorage.setItem('user', JSON.stringify(body));
  Http.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'access_token',
  )}`;
  const stateObj = {
    ...state,
    isAuthenticated: true,
    user:body,
  };
  return stateObj;
};

//update profile
const updateProfile = (state, payload) => {
  const { body } = payload;
  localStorage.setItem('access_token', body.access_token);
  localStorage.setItem('user', JSON.stringify(body));
  Http.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'access_token',
  )}`;
  const stateObj = {
    ...state,
    isAuthenticated: true,
    user:body,
  };
  return stateObj;
};

//update password
const updatePassword = (state, payload) => {
  const { body } = payload;
  localStorage.setItem('access_token', body.access_token);
  localStorage.setItem('user', JSON.stringify(body));
  Http.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'access_token',
  )}`;
  const stateObj = {
    ...state,
    isAuthenticated: true,
    user:body,
  };
  return stateObj;
};

//reset password
const resetPassword = (state, payload) => {
  const { body } = payload;
  localStorage.setItem('access_token', body.access_token);
  localStorage.setItem('user', JSON.stringify(body));
  Http.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'access_token',
  )}`;
  const stateObj = {
    ...state,
    isAuthenticated: true,
    user:body,
  };
  return stateObj;
};

const resetForm = (state, payload) => {
  const { body } = payload;
  localStorage.setItem('access_token', body.access_token);
  localStorage.setItem('user', JSON.stringify(body));
  Http.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'access_token',
  )}`;
  const stateObj = {
    ...state,
    isAuthenticated: true,
    user:body,
  };
  return stateObj;
};

//get list
const getList = (state, payload) => {
  const { body } = payload;
  localStorage.setItem('access_token', body.access_token);
  localStorage.setItem('user', JSON.stringify(body));
  Http.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'access_token',
  )}`;
  const stateObj = {
    ...state,
    isAuthenticated: true,
    user:body,
  };
  return stateObj;
};

//view
const getDetail = (state, payload) => {
  const { body } = payload;
  localStorage.setItem('access_token', body.access_token);
  localStorage.setItem('user', JSON.stringify(body));
  Http.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'access_token',
  )}`;
  const stateObj = {
    ...state,
    isAuthenticated: true,
    user:body,
  };
  return stateObj;
};


//create data
const createData = (state, payload) => {
  const { body } = payload;
  localStorage.setItem('access_token', body.access_token);
  localStorage.setItem('user', JSON.stringify(body));
  Http.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'access_token',
  )}`;
  const stateObj = {
    ...state,
    isAuthenticated: true,
    user:body,
  };
  return stateObj;
};


//update data
const updateData = (state, payload) => {
  const { body } = payload;
  localStorage.setItem('access_token', body.access_token);
  localStorage.setItem('user', JSON.stringify(body));
  Http.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'access_token',
  )}`;
  const stateObj = {
    ...state,
    isAuthenticated: true,
    user:body,
  };
  return stateObj;
};

//update status
const updateStatus = (state, payload) => {
  const { body } = payload;
  localStorage.setItem('access_token', body.access_token);
  localStorage.setItem('user', JSON.stringify(body));
  Http.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'access_token',
  )}`;
  const stateObj = {
    ...state,
    isAuthenticated: true,
    user:body,
  };
  return stateObj;
};

//update plan status
const updatePlanStatus = (state, payload) => {
  const { body } = payload;
  localStorage.setItem('access_token', body.access_token);
  localStorage.setItem('user', JSON.stringify(body));
  Http.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'access_token',
  )}`;
  const stateObj = {
    ...state,
    isAuthenticated: true,
    user:body,
  };
  return stateObj;
};

//delete 
const deleteData = (state, payload) => {
  const { body } = payload;
  localStorage.setItem('access_token', body.access_token);
  localStorage.setItem('user', JSON.stringify(body));
  Http.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'access_token',
  )}`;
  const stateObj = {
    ...state,
    isAuthenticated: true,
    user:body,
  };
  return stateObj;
};

//dashboard
const dashboardCount = (state, payload) => {
  const { body } = payload;
  localStorage.setItem('access_token', body.access_token);
  localStorage.setItem('user', JSON.stringify(body));
  Http.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'access_token',
  )}`;
  const stateObj = {
    ...state,
    isAuthenticated: true,
    user:body,
  };
  return stateObj;
};

//cms
const getContent = (state, payload) => {
  const { body } = payload;
  localStorage.setItem('access_token', body.access_token);
  localStorage.setItem('user', JSON.stringify(body));
  Http.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'access_token',
  )}`;
  const stateObj = {
    ...state,
    isAuthenticated: true,
    user:body,
  };
  return stateObj;
};

//update cms
const updateContent = (state, payload) => {
  const { body } = payload;
  localStorage.setItem('access_token', body.access_token);
  localStorage.setItem('user', JSON.stringify(body));
  Http.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'access_token',
  )}`;
  const stateObj = {
    ...state,
    isAuthenticated: true,
    user:body,
  };
  return stateObj;
};

//getCategory 
const getCategory = (state, payload) => {
  const { body } = payload;
  localStorage.setItem('access_token', body.access_token);
  localStorage.setItem('user', JSON.stringify(body));
  Http.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'access_token',
  )}`;
  const stateObj = {
    ...state,
    isAuthenticated: true,
    user:body,
  };
  return stateObj;
};

//getUser business
const getUserBusiness = (state, payload) => {
  const { body } = payload;
  localStorage.setItem('access_token', body.access_token);
  localStorage.setItem('user', JSON.stringify(body));
  Http.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'access_token',
  )}`;
  const stateObj = {
    ...state,
    isAuthenticated: true,
    user:body,
  };
  return stateObj;
};

//home main category
const getHomeBannerCat = (state, payload) => {
  const { body } = payload;
  localStorage.setItem('access_token', body.access_token);
  localStorage.setItem('user', JSON.stringify(body));
  Http.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'access_token',
  )}`;
  const stateObj = {
    ...state,
    isAuthenticated: true,
    user:body,
  };
  return stateObj;
};

//home category
const getCategoriesHomeBanner = (state, payload) => {
  const { body } = payload;
  localStorage.setItem('access_token', body.access_token);
  localStorage.setItem('user', JSON.stringify(body));
  Http.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'access_token',
  )}`;
  const stateObj = {
    ...state,
    isAuthenticated: true,
    user:body,
  };
  return stateObj;
};

//home subcategory
const getHomeBannerSubCat = (state, payload) => {
  const { body } = payload;
  localStorage.setItem('access_token', body.access_token);
  localStorage.setItem('user', JSON.stringify(body));
  Http.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'access_token',
  )}`;
  const stateObj = {
    ...state,
    isAuthenticated: true,
    user:body,
  };
  return stateObj;
};

//img delete 
const deleteImgData = (state, payload) => {
  const { body } = payload;
  localStorage.setItem('access_token', body.access_token);
  localStorage.setItem('user', JSON.stringify(body));
  Http.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'access_token',
  )}`;
  const stateObj = {
    ...state,
    isAuthenticated: true,
    user:body,
  };
  return stateObj;
};


const Auth = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case ActionTypes.AUTH_LOGIN:
          return authLogin(state, payload);
        case ActionTypes.AUTH_CHECK:
          return checkAuth(state, payload);
        case ActionTypes.AUTH_LOGOUT:
          return authLogout(state);
        case ActionTypes.PROFILE:
          return getProfile(state, payload);
        case ActionTypes.UPDATE_PROFILE:
          return updateProfile(state, payload);
        case ActionTypes.FILE_UPLOAD:
          return fileUpload(state, payload);
        case ActionTypes.UPDATE_PASSWORD:
          return updatePassword(state, payload);
        case ActionTypes.RESET_PASSWORD:
          return resetPassword(state, payload);
        case ActionTypes.RESET_FORM:
          return resetForm(state, payload);
          case ActionTypes.GET_LIST:
          return getList(state, payload);
        case ActionTypes.GET_DETAIL:
          return getDetail(state, payload);
        case ActionTypes.UPDATE_STATUS:
          return updateStatus(state, payload);
        case ActionTypes.DELETE_DATA:
          return deleteData(state, payload);
        case ActionTypes.DASHBOARD_COUNT:
          return dashboardCount(state, payload);
        case ActionTypes.CREATE_DATA:
          return createData(state, payload);
        case ActionTypes.UPDATE_DATA:
          return updateData(state, payload);
        case ActionTypes.GET_CONTENT:
          return getContent(state, payload);
        case ActionTypes.UPDATE_CONTENT:
          return updateContent(state, payload);
        case ActionTypes.GET_CATEGORY:
          return getCategory(state, payload);
        case ActionTypes.GET_USERS_BUSINESS:
          return getUserBusiness(state, payload);
        case ActionTypes.GET_HOME_BANNER_CAT:
          return getHomeBannerCat(state, payload);
        case ActionTypes.GET_CATEGORIES_HOME_BANNER:
          return getCategoriesHomeBanner(state, payload);
        case ActionTypes.GET_HOME_BANNER_SUBCAT:
          return getHomeBannerSubCat(state, payload);
        case ActionTypes.DELETE_IMG_DATA:
          return deleteImgData(state, payload);
        default:
        return state;
    };
}
export default Auth;