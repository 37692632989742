import React, { useState, useEffect, useRef } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import * as Path from "routes/paths";
import AuthService from "services";
import swal from "sweetalert";
import * as actions from "store/actions";
import env from "env.json";
import { Field, reduxForm, reset, formValueSelector } from "redux-form";
import LoadingButton from "components/shared/LoadingButton";
import ImageUploading from "react-images-uploading";
import { css } from "@emotion/react";
import DotLoader from "react-spinners/DotLoader";
import moment from "moment";
const renderField = ({
  input,
  placeholder,
  type,
  disabled,
  min,
  meta: { touched, error, warning },
}) => (
  <>
    <input
      {...input}
      type={type}
      placeholder={placeholder}
      disabled={disabled}
      min={min}
      className="form-control"
    />

    {touched && error && <span className="errorMessage">{error}</span>}
  </>
);

const CreateAdds = (props) => {
  console.log(props,"props") 
  // return
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;
  let [color, setColor] = useState("#ffffff");

  const todayDate = moment().format("YYYY-MM-DD");
  const endDate = moment(todayDate).format("YYYY-MM-DD");
  const [finalEndDate, setFinalEndDate] = useState(endDate);

  //history
  const history = useHistory();

  const [addsType, setAddstype] = useState(null);

  const [catArr, setCatArr] = useState([
    "Market Place",
    "Shoppers",
    "Spotted Deals",
  ]);

  const [appUrl, setAppUrl] = useState({});

  let editkey = props && props.match.params.id;
  const { handleSubmit, submitting } = props;

  const [sending, setSending] = useState(false);
  const [mainLoader, setMainLoader] = useState(false);
  const MODULE_NAME = "adds";

  const [allData, setAllData] = useState({});
  const [values, setValues] = useState({
    title: "",
    start_date: todayDate,
    end_date: "",
  });

  //images
  const [images, setImages] = useState([]);
  const [imageError, setImageError] = useState(false);
  const maxNumber = 5;

  //maincategories
  const [mainCategories, setMainCategories] = useState([]);
  const [getMainCatName, setGetMainCatName] = useState({});
  const [mainCat, setMainCat] = useState({
    main_cat_id: "",
  });

  const handleChangeDate = (e) => {
    const { name, value } = e.target;
    if (name == "start_date") {
      let setEnddate = moment(e.target.value).format("YYYY-MM-DD");
      setFinalEndDate(setEnddate);
      props.reset({ end_date: "" });
    }
  };

  const handleChangeTitle = (e) => {
    const { name, value } = e.target;
    setAppUrl({
      ...appUrl,
      [name]: value,
    });
  };

  const handleMediaChange = (event) => {
    const selectedFiles = event.target.files;
    let updatedMediaFiles = [];

    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      console.log(file,"filefilefilefile")
      const isImage = file.type.startsWith("image/");
      const isVideo = file.type.startsWith("video/");

      if (isImage || isVideo) {
        updatedMediaFiles.push({
          data_url: URL.createObjectURL(file),
          file: file,
          priority_new: images.length + 1,
          priority: "0",
          isImage: isImage,
        });
      }
    }

    console.log(updatedMediaFiles,"updatedMediaFiles")

    setImages([...images, ...updatedMediaFiles]);
  };

  const handleMediaRemove = (index) => {
    const updatedMedia = images.filter((_, i) => i !== index);
    setImages(updatedMedia);
  };

  //handle change
  const handleChange = (e) => {
    let getIndex = e.nativeEvent.target.selectedIndex;
    console.log(getIndex,"getIndex")
    console.log(e.nativeEvent.target[getIndex].text, "getIndexooooooo")
    setGetMainCatName(e.nativeEvent.target[getIndex].text);
    const { name, value } = e.target;
    setMainCat({
      ...mainCat,
      [name]: value,
    });
    console.log(name,"namenamenamename")
    let catName = e.nativeEvent.target[getIndex].text;
    if (
      catName != "Hotels" &&
      catName != "Things to do" &&
      catName != "Employment" &&
      catName != "Shoppers" &&
      catName != "Spotted Deals"
    ) {
      getAllCatData(e.nativeEvent.target[getIndex].text);
    }
    setGetCatName("");
    setBannerCatId({});
    setBannerSubCatId({});
    setGetSubCatName("");
    setModel({});
    setType({});
    setBrand({});
  };
  //categories
  const [bannerCategory, setBannerCategory] = useState([]);
  const [getCatName, setGetCatName] = useState("");
  const [bannerCatId, setBannerCatId] = useState({
    cat_id: "",
  });

  //handle change
  const handleChange2 = (e) => {
    let getIndex = e.nativeEvent.target.selectedIndex;

    console.log(getIndex,"getIndexgetIndexgetIndex")
    setGetCatName(e.nativeEvent.target[getIndex].text);
    const { name, value } = e.target;
    setBannerCatId({
      ...bannerCatId,
      [name]: value,
    });
    console.log(name,"namenamename")
    // let subCatName = e.nativeEvent.target[getIndex].text;
    if (
      getMainCatName != "Restaurants" &&
      getMainCatName != "Real estate" &&
      getMainCatName != "Travel Tourism" &&
      getMainCatName != "Cars"
    ) {
      getAllSubCatData(value);
    }
  };

  //subcategory
  const [bannerSubCategory, setBannerSubCategory] = useState([]);
  const [getSubCatName, setGetSubCatName] = useState("");
  const [bannerSubCatId, setBannerSubCatId] = useState({
    sub_cat_id: "",
  });

  //handle change
  const handleChange3 = (e) => {
    let getIndex = e.nativeEvent.target.selectedIndex;
    console.log(getIndex,"hhhhhhhhhhhhhhhhhhhhhhhhh")
    setGetSubCatName(e.nativeEvent.target[getIndex].text);
    const { name, value } = e.target;
    setBannerSubCatId({
      // sub_cat_id: value,
      ...bannerSubCatId,
      [name]: value,
    });
    console.log(name,"namenamename")
  };
  //cars
  const [types, setTypes] = useState([]);
  const [type, setType] = useState({
    type_id: "",
  });
  //handle change
  const handleChangeType = (e) => {
    const { name, value } = e.target;
    setType({
      ...type,
      [name]: value,
    });
  };
  const [models, setModels] = useState([]);
  const [model, setModel] = useState({
    model_id: "",
  });
  //handle change
  const handleChangeModel = (e) => {
    const { name, value } = e.target;
    setModel({
      ...model,
      [name]: value,
    });
  };
  const [brands, setBrands] = useState([]);
  const [brand, setBrand] = useState({
    brand_id: "",
  });
  //handle change
  const handleChangeBrand = (e) => {
    const { name, value } = e.target;
    setBrand({
      ...brand,
      [name]: value,
    });
  };
  //images
  let emptyArray = [...images];
  const onChange = (imageList) => {
    console.log(imageList, "imageList");
    if (images.length >= 5) {
      setImageError(true);
    } else {
      setImageError(false);
      imageList.forEach((image, index) => {
        emptyArray.push(image);
        // arr.push(image)
        setImages([...images, image]);
      });
      emptyArray = getUniqueListBy(emptyArray, "data_url");
      let myNewImageArray = emptyArray.filter(function (obj) {
        return obj.data_url;
      });
      let myDbImageArray = images.filter(function (obj) {
        return !obj.data_url;
      });
      setImages([...myDbImageArray, ...myNewImageArray]);
    }
  };
  function getUniqueListBy(arr, key) {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  }

  const handleRemoveImgClick = async (img) => {
    let arr;
    if (img.data_url) {
      arr = images.filter((item) => item.data_url !== img.data_url);
    } else {
      let query = {
        MODULE_NAME,
        id: editkey,
        image_id: img._id,
      };

      await props.dispatch(AuthService.deleteImgData(query)).then((res) => {
        arr = images.filter((item) => item.original !== img.original);
      });
    }
    setImages(arr);
  };

  const editModal = async () => {
    let query = {
      MODULE_NAME,
      id: editkey,
    };
    setMainLoader(true);
    setSending(true);
    await props.dispatch(AuthService.getDetail(query)).then((res) => {
      setSending(false);
      setMainLoader(false);
      props.dispatch(
        actions.persist_store({ editCreateAdds: res.body.get_detail })
      );
      setImages(res.body.get_detail.image);
      setValues(res.body.get_detail);
      setAllData(res.body.get_detail);
      setMainCat(res.body.get_detail.main_category_id);
      setGetSubCatName(res.body.get_detail.subcat_model_name);
      setGetCatName(res.body.get_detail.cat_model_name);
      setBannerCatId(res.body.get_detail.category_id);
      setBannerSubCatId(res.body.get_detail.sub_category_id);
      setType(res.body.types);
      setModel(res.body.models);
      setBrand(res.body.brands);
      setAddstype(res.body.get_detail.type);
    });
  };

  const getMainCategories = async () => {
    let query = {
      MODULE_NAME,
      API_NAME: "main_categories",
    };
    setSending(true);
    await props.dispatch(AuthService.getHomeBannerCat(query)).then((res) => {
      setSending(false);
      let arr = res.body;
      arr = arr.filter((data) => {
        return !catArr.includes(data.name);
      });
      setMainCategories(arr);
    });
  };

  const getAllCatData = async (name) => {
    // console.log(name, "name")
    let query = {
      MODULE_NAME,
      API_NAME: "banner_categories",
      cat_name: name,
    };
    setSending(true);
    await props
      .dispatch(AuthService.getCategoriesHomeBanner(query))
      .then((res) => {
        setSending(false);
        setBannerCategory(res.body);
        if (name == "Cars") {
          setTypes(res.body.types);
          setModels(res.body.models);
          setBrands(res.body.brands);
        }
      });
  };

  const getAllSubCatData = async (cat_id) => {
    let query = {
      MODULE_NAME,
      API_NAME: "banner_subcategories",
      cat_name: getMainCatName,
      cat_id: cat_id,
    };
    setSending(true);
    await props.dispatch(AuthService.getHomeBannerSubCat(query)).then((res) => {
      setSending(false);
      setBannerSubCategory(res.body);
    });
  };

  function checkFileType(item) {
    // Get the file extension by splitting the string at the dot (.)
    const fileExtension = item.split(".").pop().toLowerCase();

    // Define an array of common image file extensions
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"];

    // Check if the file extension is in the list of image extensions
    if (imageExtensions.includes(fileExtension)) {
      return "image";
    } else {
      return "video";
    }
  }

  useEffect(() => {
    if (editkey) {
      editModal();
    }
    if (addsType != null && addsType?.banner_ads != "home") {
      getMainCategories();
    }
  }, [getMainCatName]);
  //submit function
  const handleSubmit_ = async (values) => {
    try {
      let query = {};
      const formData = new FormData();

      // Assign priority to images
      images.map((v, i) => {
        return Object.assign(v, { priority_new: i + 1 });
      });

      // Separate new images and existing images
      let myNewImageArray = images.filter(obj => obj.data_url);
      let myDbImageArray = images.filter(obj => !obj.data_url);

      // Process new images
      if (myNewImageArray.length > 0 && myNewImageArray[0]["data_url"]) {
        for (let key in myNewImageArray) {
          if (myNewImageArray[key]["file"]) {
            myNewImageArray[key].priority = editkey ? myNewImageArray[key].priority_new : key;
            const file = myNewImageArray[key]["file"];
            if (file.type.includes("image")) {
              formData.append("type", "image");
            } else if (file.type.includes("video")) {
              formData.append("type", "video");
            }
            formData.append("image", myNewImageArray[key]["file"]);
          }
        }

        formData.append("folder", "adds");

        await props.dispatch(AuthService.fileUpload(formData)).then((res) => {
          let newImg = res.body;
          if (editkey) {
            if (myDbImageArray.length > 0) {
              for (let i in myDbImageArray) {
                myDbImageArray[i].file_type = "image";
                myDbImageArray[i].folder = "adds";
                myDbImageArray[i].image = myDbImageArray[i].original;
                myDbImageArray[i].thumbnail = myDbImageArray[i].thumbnail;
              }
            }
            let newArrayForSave = [...newImg, ...myDbImageArray];
            query.image = JSON.stringify(newArrayForSave);
          } else {
            query.image = JSON.stringify(newImg);
          }
        });
      }

      // Set sending state to true
      setSending(true);

      // Prepare query data
      query = {
        ...query,
        MODULE_NAME,
        main_category_id: mainCat?.main_cat_id || mainCat || null,
        category_id: bannerCatId?.cat_id || bannerCatId || 0,
        sub_category_id: bannerSubCatId?.sub_cat_id || bannerSubCatId || 0,
        title: appUrl?.title || values.title,
        start_date: values.start_date,
        end_date: values.end_date,
        type: addsType?.banner_ads || "",
        cat_model_name: getCatName || "",
        subcat_model_name: getSubCatName || "",
        model_id: model?.model_id || 0,
        type_id: type?.type_id || 0,
        brand_id: brand?.brand_id || 0,
      };

      // Handle edit or create action
      if (editkey) {
        query._id = editkey;
        await props.dispatch(AuthService.updateData(query)).then((res) => {
          setSending(false);
          swal("Success!", res.message, "success");
          resetFormValues();
        });
      } else {
        await props.dispatch(AuthService.createData(query)).then((res) => {
          setSending(false);
          swal("Success!", res.message, "success");
          resetFormValues();
        });
      }
    } catch (err) {
      console.error(err);
      setSending(false);
      if (err && err.data && err.data.message) {
        swal("Oops!", err.data.message, "error");
      }
    }
  };

  // Function to reset form values
  const resetFormValues = () => {
    setValues({
      title: "",
      start_date: "",
      end_date: "",
    });
    props.dispatch(actions.persist_store({ editCreateAdds: "" }));
    props.dispatch(actions.persist_store({ valuess: "" }));
    props.dispatch(reset("createAdds"));
    history.push(Path.ads);
  };


  //handle change
  const handleChangeAdd = (e) => {
    const { name, value } = e.target;
    setAddstype({
      ...addsType,
      [name]: value,
    });
    console.log(name, value,"jkjkj")
    setMainCat({});
    setBannerCatId({});
    setTypes({});
    setModels({});
    setBrands({});
    setGetMainCatName({});
  };

  // console.log(values, "values")
  // render form
  const _renderForm = () => {
    return (
      <>
        <form onSubmit={handleSubmit(handleSubmit_)}>
          <div className="card-body">
            <div className="row">
              <div className="col-md-12" style={{ marginBottom: 20 }}>
                <h3 className="card-title">
                  <b>{editkey ? "Edit" : "Add"} Ads</b>
                </h3>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label for="exampleSelect1">Select Adds Type</label>
                  <select
                    name="banner_ads"
                    onChange={handleChangeAdd}
                    className={`form-control`}
                  >
                    <option value="">Select</option>
                    <option
                      value="home"
                      selected={mainCat == null}
                      disabled={editkey ? "disabled" : ""}
                    >
                      Home
                    </option>
                    <option
                      value="category"
                      selected={mainCat?.name}
                      disabled={editkey ? "disabled" : ""}
                    >
                      Category
                    </option>
                    <option
                      value="subcategory"
                      selected={addsType === "subcategory"}
                      disabled={editkey ? "disabled" : ""}
                    >
                      SubCategory
                    </option>
                  </select>
                </div>
              </div>
              {(addsType?.banner_ads === "category" ||
                addsType?.banner_ads === "subcategory") && (
                  <>
                    {!editkey && (
                      <div className="col-md-12">
                        <div className="form-group">
                          <label for="exampleSelect1">
                            Select Main Categories
                          </label>
                          <select
                            name="main_cat_id"
                            value={mainCat.main_cat_id}
                            onChange={handleChange}
                            className={`form-control`}
                          >
                            <option value="">Select Main Category</option>

                            {mainCategories.length > 0 &&
                              mainCategories.map((item, key) => {
                                return (
                                  <option
                                    key={key}
                                    value={item._id}
                                    selected={item._id == mainCat}
                                    disabled={editkey ? "disabled" : ""}
                                  >
                                    {item.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                    )}
                  </>
                )}
              {getMainCatName === "Cars" && addsType?.banner_ads !== "home" && (
                <>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label for="exampleSelect1">Types</label>
                      <select
                        name="type_id"
                        value={type.type_id}
                        onChange={handleChangeType}
                        className={`form-control`}
                      >
                        <option value="">Select Types</option>
                        {types.length > 0 &&
                          types.map((item, key) => {
                            return (
                              <option
                                key={key}
                                value={item._id}
                                selected={item._id == type._id}
                              >
                                {item.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label for="exampleSelect1">Models</label>
                      <select
                        name="model_id"
                        value={type.model_id}
                        onChange={handleChangeModel}
                        className={`form-control`}
                      >
                        <option value="">Select Models</option>
                        {models.length > 0 &&
                          models.map((item, key) => {
                            return (
                              <option
                                key={key}
                                value={item._id}
                                selected={item._id == model._id}
                              >
                                {item.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label for="exampleSelect1">Brands</label>
                      <select
                        name="brand_id"
                        value={type.brand_id}
                        onChange={handleChangeBrand}
                        className={`form-control`}
                      >
                        <option value="">Select Brands</option>
                        {brands.length > 0 &&
                          brands.map((item, key) => {
                            return (
                              <option
                                key={key}
                                value={item._id}
                                selected={item._id == brand._id}
                              >
                                {item.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </>
              )}

              {getMainCatName != "Hotels" &&
                getMainCatName != "Employment" &&
                getMainCatName != "Things to do" &&
                getMainCatName != "Shoppers" &&
                getMainCatName != "Spotted Deals" &&
                getMainCatName != "Cars" &&
                getMainCatName != "Real estate" && (
                  <>
                    {mainCategories.length > 0 && mainCat.main_cat_id && (
                      <div className="col-md-12">
                        <div className="form-group">
                          <label for="exampleSelect1">Select Category</label>
                          <select
                            name="cat_id"
                            value={bannerCatId.cat_id}
                            onChange={handleChange2}
                            className={`form-control`}
                          >
                            <option value="">Select Category</option>
                            {bannerCategory.length > 0 &&
                              bannerCategory.map((item, key) => {
                                return (
                                  <option
                                    key={key}
                                    value={item._id}
                                    selected={item._id == bannerCatId}
                                  >
                                    {item.type ? item.type : item.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                    )}
                  </>
                )}
              {editkey && mainCat && (
                <div className="col-md-12">
                  <div className="form-group">
                    <label for="exampleSelect1">Main Category</label>
                    <input
                      disabled
                      name="main_cat_id"
                      type="text"
                      className="form-control"
                      value={mainCat.name}
                    />
                  </div>
                </div>
              )}
              {editkey && bannerCatId != 0 && (
                <div className="col-md-12">
                  <div className="form-group">
                    <label for="exampleSelect1">Category</label>
                    <input
                      disabled
                      name="cat_model_name"
                      type="text"
                      className="form-control"
                      value={getCatName}
                    />
                  </div>
                </div>
              )}
              {editkey && bannerSubCatId != 0 && (
                <div className="col-md-12">
                  <div className="form-group">
                    <label for="exampleSelect1">SubCategory</label>
                    <input
                      disabled
                      name="subcat_model_name"
                      type="text"
                      className="form-control"
                      value={getSubCatName}
                    />
                  </div>
                </div>
              )}
              {getMainCatName != "Restaurants" &&
                getMainCatName != "Hotels" &&
                getMainCatName != "Things to do" &&
                getMainCatName != "Shoppers" &&
                getMainCatName != "Spotted Deals" &&
                getMainCatName != "Real estate" &&
                getMainCatName != "Travel Tourism" &&
                getMainCatName != "Cars" && (
                  <>
                    {bannerCatId.cat_id && bannerCategory.length > 0 && (
                      <div className="col-md-12">
                        <div className="form-group">
                          <label for="exampleSelect1">Select SubCategory</label>
                          <select
                            name="sub_cat_id"
                            value={bannerSubCatId.sub_cat_id}
                            onChange={handleChange3}
                            className={`form-control`}
                          >
                            <option value="">Select SubCategory</option>
                            {bannerSubCategory.length > 0 &&
                              bannerSubCategory.map((item, key) => {
                                return (
                                  <option
                                    key={key}
                                    value={item._id}
                                    selected={
                                      item._id == bannerSubCatId.sub_cat_id
                                    }
                                  >
                                    {item.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                    )}
                  </>
                )}

              {editkey && allData && allData.type_id != 0 && (
                <div className="col-md-12">
                  <div className="form-group">
                    <label for="exampleSelect1">Type</label>
                    <input
                      disabled
                      name="type"
                      type="text"
                      className="form-control"
                      value={type.name}
                    />
                  </div>
                </div>
              )}
              {editkey && allData && allData.model_id != 0 && (
                <div className="col-md-12">
                  <div className="form-group">
                    <label for="exampleSelect1">Models</label>
                    <input
                      disabled
                      name="model"
                      type="text"
                      className="form-control"
                      value={model.name}
                    />
                  </div>
                </div>
              )}
              {editkey && allData && allData.brand_id != 0 && (
                <div className="col-md-12">
                  <div className="form-group">
                    <label for="exampleSelect1">Brands</label>
                    <input
                      disabled
                      name="brand"
                      type="text"
                      className="form-control"
                      value={brand.name}
                    />
                  </div>
                </div>
              )}

              <div className="col-md-12">
                <div className="form-group">
                  <label for="exampleInputEmail1">App Url</label>
                  <input
                    name="title"
                    className="form-control"
                    type="url"
                    placeholder="www.google.com"
                    defaultValue={values.title || ""}
                    onChange={(e) => handleChangeTitle(e)}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputEmail1">Start Date</label>
                      <Field
                        name="start_date"
                        component={renderField}
                        type="date"
                        min={todayDate}
                        onChange={(e) => handleChangeDate(e)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputEmail1">End Date</label>
                      <Field
                        name="end_date"
                        component={renderField}
                        type="date"
                        min={finalEndDate}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <label for="exampleInputEmail1">
              Image (You can select upto 5 Images same time)
            </label>
            <br />
            {/* {imageError &&
                            <span className="errorMessage">Maximum limit is 5</span>
                        } */}
            {/* <ImageUploading
                            multiple
                            value={images}
                            onChange={onChange}
                            maxNumber={maxNumber}
                            dataURLKey="data_url"
                            >
                            {({
                                imageList,
                                onImageUpload,
                                // onImageRemoveAll,
                                onImageUpdate,
                                onImageRemove,
                                isDragging,
                                dragProps,
                                errors
                            }) => (
                                // write your building UI
                                <>
                                 
                                <div className="form-row" style={isDragging ? { color: 'red' } : undefined}
                                    onClick={onImageUpload}
                                    {...dragProps}>
                                    <img src="/assets/images/upld.png" onClick={() => onImageRemove(0)} style={{height:'70px', height:'70px'}} />
                                </div>
                                {errors && <div style={{color:'red'}}>
                                {errors.maxNumber && <span>You can select upto 5 images same time</span>}
                                </div>}
                                <div className="row">
                                    {images.length>0 && images.map((image, index) => {
                                        // console.log(image, "img");
                                        let imgData;
                                        let dbImage = []
                                        if(image['data_url']){
                                            imgData = image['data_url'];
                                        
                                        } else{
                                            imgData = env.SERVER_URL+image.original
                                        }
                                        return(
                                            <>
                                                <div className="image-item" key={index} 
                                                >
                                                <div className="close-img-item" onClick={() => handleRemoveImgClick(image)}>
                                                    <i className="fa fa-times" aria-hidden="true"></i>
                                                </div>
                                                <img src={imgData} alt="" style={{width:'50px', height:'50px'}}/>
                                                <div className="priorityStyle">{index+1}</div>
                                            </div>
                                        </>
                                        )
                                    })}
                                </div>
                                </>
                            )}
                        </ImageUploading>  */}

            <div>
              {imageError && (
                <span className="errorMessage">Maximum limit is 5</span>
              )}
              <input
                type="file"
                accept="image/*, video/*"
                onChange={handleMediaChange}
                multiple
              />
              {images.map((media, index) => {
                console.log("check the media", media);

                if (!media.hasOwnProperty("isImage")) {
                  const fileExtension = media.original
                    .split(".")
                    .pop()
                    .toLowerCase();
                  media.isImage = ["jpg", "jpeg", "png", "gif"].includes(
                    fileExtension
                  );
                }

                return (
                  <div key={index} className="media-container">
                    {media.isImage ? (
                      <img
                        src={media.data_url || media.original}
                        alt=""
                        className="media-item"
                      />
                    ) : (
                      <video
                        controls
                        src={media.data_url || media.original}
                        className="media-item"
                      />
                    )}
                    <div className="media-options">
                      <div
                        onClick={() => {
                          handleMediaRemove(index);
                          handleRemoveImgClick(media);
                        }}
                      >
                        Remove
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="row">
              <div
                className="col-4 loading--submit-button"
                style={{ marginTop: "20px" }}
              >
                <LoadingButton
                  type="submit"
                  className="btn btn-primary"
                  loading={sending}
                  disabled={submitting}
                >
                  {editkey ? "Update" : "Submit"}
                </LoadingButton>
              </div>
            </div>
          </div>
        </form>
      </>
    );
  };

  return (
    <>
      <Helmet title="Ads" />
      {mainLoader && (
        <div className="main-loader">
          <DotLoader
            color={color}
            loading={mainLoader}
            css={override}
            size={150}
          />
        </div>
      )}
      {!mainLoader && (
        <>
          <div className="app-title">
            <div>
              <h1>
                <i className="fa fa-th-list"></i> Ads
              </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="tile">
                <div className="tile-body">{_renderForm()}</div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const initialValues = (values) => {
  if (values) {
    return {
      ...values,
      title: values.title ? values.title : "",
      start_date: values.start_date ? values.start_date : "",
      end_date: values.end_date ? values.end_date : "",
    };
  }
};

const validate = (values) => {
  const errors = {};
  //title
  if (!values.title) {
    errors.title = "Required*";
  }
  if (!values.start_date) {
    errors.start_date = "Required*";
  }
  if (!values.end_date) {
    errors.end_date = "Required*";
  }
  return errors;
};

const formName = "createAdds";
const myFormValueSelector = formValueSelector(formName);

const mapStateToProps = (state) => {
  return {
    initialValues: initialValues(state.persistStore.editCreateAdds),
    isAuthenticated: state.Auth.isAuthenticated,
    user: state.Auth,
    start_date: myFormValueSelector(state, "start_date"),
    title: myFormValueSelector(state, "title"),
    end_date: myFormValueSelector(state, "end_date"),
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

const CreateAddsForm = reduxForm({
  form: "createAdds",
  enableReinitialize: true,
  validate,
})(CreateAdds);

export default connect(mapStateToProps, mapDispatchToProps)(CreateAddsForm);
