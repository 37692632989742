import React, {useState, useEffect} from "react";
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import * as Path from 'routes/paths';
import AuthService from 'services';
import swal from 'sweetalert';
import * as actions from 'store/actions';
import env from 'env.json';
import { Field, reduxForm, reset } from 'redux-form';
import LoadingButton from 'components/shared/LoadingButton';

const renderField = ({
    input,
    placeholder,
    type,
    disabled,
    min,
    meta: { touched, error, warning }
}) => (
    <>
        <input {...input} type={type} placeholder={placeholder} min={min} disabled={disabled} className="form-control"/>
        
        {touched &&
            (error && <span className="errorMessage">{error}</span>)
        }
    </>
)
const renderFieldTextArea = ({
    input,
    placeholder,
    type,
    disabled,
    meta: { touched, error, warning }
}) => (
    <>
        <textarea {...input} type={type} placeholder={placeholder} disabled={disabled} className="form-control"/>
        
        {touched &&
            (error && <span className="errorMessage">{error}</span>)
        }
    </>
)

const FormStoreSubscription = (props) => {
    
    //history
    const history = useHistory();
 // render form
    let editkey = props && props.match.params.id;

    const { handleSubmit, submitting} = props;
    const [sending, setSending] = useState(false);
   
    const [ type, setType] = useState({});
    const [ subscription_type, setSubscriptionType] = useState({});
    const MODULE_NAME = 'store_subscription';
   
    const [ values, setValues] = useState({
        type:"",
        subscription_type:"",
        description:"",
        price:"",
    });


    const handleChange2 = (e) => {
        const { name, value } = e.target;
        setType({
            ...type,
            [name]: value
        });
    }; 
    const handleChange3 = (e) => {
        const { name, value } = e.target;
        setSubscriptionType({
            ...subscription_type,
            [name]: value
        });
    }; 

    const editModal = async () => {
        let query = {
            MODULE_NAME,
            id:editkey
        };
        setSending(true);
        await props.dispatch(AuthService.getDetail(query)).then((res) => {
            setSending(false);
            props.dispatch(actions.persist_store({  editFormStoresubscription:res.body}));
            setType(res.body.type);
            setSubscriptionType(res.body.subscription_type);
            setValues(res.body);
        });
    };  
    console.log(subscription_type, "subscription_type")
    
    
    useEffect(() => {
        if(editkey){
            editModal();
        }
    }, []);
   
    const handleSubmit_ = async (values) => {
        try{
            let query = {
                MODULE_NAME,
                // type: type ? type.type : type,
                price:values.price,
                description:values.description,
               
            };
            setSending(true);
            if(editkey){
                query.subscription_id = editkey;
                query.type = type
                query.subscription_type = subscription_type
                console.log(query, "query")
                await props.dispatch(AuthService.updateData(query)).then((res) => {
                    setSending(false);
                    swal("Success!", res.message, "success");
                    setValues({
                        type:"",
                        subscription_type:"",
                        description:"",
                        price:"",
                    });
                    props.dispatch(actions.persist_store({ editFormStoresubscription:""}));
                    props.dispatch(reset('formStoreSubscription'));
                    history.push(Path.store_subscription);
                });
            }
            else{
                query.type = type.type
                query.subscription_type = subscription_type.subscription_type
                console.log(query, "query")
                console.log(subscription_type, "subscription_type")
                await props.dispatch(AuthService.createData(query)).then((res) => {
                    setSending(false);
                    swal("Success!", res.message, "success");
                    setValues({
                        type:"",
                        subscription_type:"",
                        description:"",
                        price:"",
                    });
                    props.dispatch(actions.persist_store({ editFormStoresubscription:""}));
                    props.dispatch(reset('formStoreSubscription'));
                    history.push(Path.store_subscription);
                });
            }
        }catch(err) {
            console.log(err);
            setSending(false);
            if(err && err.data && err.data.message){
                swal("Oops!", err.data.message, "error");
            }
        }
    }


    const _renderForm = () => {
    return(
        <>
            <form onSubmit={handleSubmit(handleSubmit_)}>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12" style={{marginBottom:20}}>
                            <h3 className="card-title"><b>{editkey ? 'Edit' : 'Add'} Store & Deals Subscription </b></h3>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                            <label for="exampleSelect1">Select Subscription Type</label>
                                <select
                                    name="type"
                                    onChange={handleChange2}
                                    className={`form-control`}
                                    required
                                  
                                    disabled={editkey ? 'disabled' : ''}
                                    >
                                    <option value="">Select Type</option>
                                    <option value="1" selected={type==1}>Store</option>
                                    <option value="2" selected={type==2}>Deals</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                            <label for="exampleSelect1">Select Type</label>
                                <select
                                    name="subscription_type"
                                    onChange={handleChange3}
                                    className={`form-control`}
                                    required
                                  
                                    disabled={editkey ? 'disabled' : ''}
                                    >
                                    <option value="">Select Type</option>
                                    <option value="1" selected={subscription_type==1}>Monthly</option>
                                    <option value="2" selected={subscription_type==2}>Annually</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label for="exampleInputEmail1">Price</label>
                                <Field 
                                    name="price" 
                                    component={renderField}
                                    type="text" 
                                    min={0}
                                />
                            </div>
                        </div>
                       
                      
                        <div className="col-md-12">
                            <div className="form-group">
                                <label for="exampleInputEmail1">Description</label>
                                <Field 
                                    name="description" 
                                    component={renderFieldTextArea}
                                    type="text" 
                                />
                            </div>
                        </div>
                    </div>
                   
                    <div className="row">
                        <div className="col-4 loading--submit-button" style={{marginTop:'20px'}}>
                            <LoadingButton
                                type="submit"
                                className="btn btn-primary"
                                loading={sending}
                                disabled={submitting}
                            >
                                {editkey ? 'Update' : 'Submit'}
                            </LoadingButton>
                        </div>
                    </div>  
                </div>
            </form>
        </>
    )}

    return(
        <>
        <Helmet title="Store & Deals Subscription" />
            <div className="app-title">
                <div>
                <h1>Store & Deals Subscription</h1>
                </div>
                
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="tile">
                        <div className="tile-body">
                            {_renderForm()}
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

const initialValues = (values) => {	
	if(values) {
		return {
			...values,
            // type: values.type ? values.type : "",
            price: values.price ? values.price : "",
            description: values.description ? values.description : "",
		}
	}
}

const validate = (values) => {
    const errors = {}
    if (!values.type) {
      errors.type = 'Required*'
    }
    if (!values.subscription_type) {
        errors.subscription_type = 'Required*'
      }
    if (!values.price) {
        errors.price = 'Required*'
    }
    if (!values.description) {
        errors.description = 'Required*'
    }
    return errors
}

  
const mapStateToProps = (state) => {
    return{
        initialValues: initialValues(state.persistStore.editFormStoresubscription),
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

const StoreSubscriptionForm = reduxForm({
    form: 'formStoreSubscription',
    enableReinitialize: true,
    validate
})(FormStoreSubscription);

export default connect(mapStateToProps, mapDispatchToProps)(StoreSubscriptionForm);
