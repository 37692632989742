import React, { useState, useEffect } from "react";
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Path from 'routes/paths';
import AuthService from 'services';
import swal from 'sweetalert';
import * as actions from 'store/actions';
import Toggle from 'react-toggle';
import ReactToggle from './ReactToggle.css';
import Pagination from "react-js-pagination";
import { List } from 'react-content-loader';
import env from 'env.json';
import { Field, reduxForm, reset } from 'redux-form';
import SelectInput from 'components/shared/SelectInput';
import TextInput from 'components/shared/TextInput';
import LoadingButton from 'components/shared/LoadingButton';
import moment from 'moment';

const renderField = ({
    input,
    placeholder,
    type,
    disabled,
    meta: { touched, error, warning }
}) => (
    <>
        <input {...input} type={type} placeholder={placeholder} disabled={disabled} className="form-control" />

        {touched &&
            (error && <span className="errorMessage">{error}</span>)
        }
    </>
)

const SpottedDeals = (props) => {

    //pagination
    const [activePage, setActivePage] = useState(1);
    const [totalItemsCount, setTotalItemsCount] = useState(0);
    const [itemsCountPerPage, setItemsCountPerPage] = useState(0);
    const [pageRange, setPageRange] = useState(5);


    const [sending, setSending] = useState(false);
    const [load, setLoad] = useState(false);
    const [data, setData] = useState([]);
    const MODULE_NAME = 'spotted_deals';

    //search record
    const [sortby, setSortby] = useState(1);
    const [clearSearch, setClearSearch] = useState(false);
    const [values, setValues] = useState({
        search: "",
    });

    //handle change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
    };

    //get type
    let isMounted = true;
    async function getData() {
        try {
            let search = clearSearch == false ? values.search : '';
            let query = {
                page: activePage,
                MODULE_NAME,
                sortby,
                search: search
            };
            setLoad(true);
            await props.dispatch(AuthService.getList(query)).then((res) => {
                if (isMounted) {
                    setLoad(false);
                    setData(res.body.data);
                    setClearSearch(false);
                    setItemsCountPerPage(res.body.per_page);
                    setTotalItemsCount(res.body.total_count);
                }
            });
        } catch (err) {
            setLoad(false);
            console.log(err);
            if (err && err.data && err.data.message) {
                swal("Oops!", err.data.message, "error");
            }
        }
    };

    useEffect(() => {
        getData();
        return () => {
            isMounted = false;
        };
    }, [activePage, sortby, clearSearch]);

    const clearFilter = () => {
        setClearSearch(!clearSearch)
        setValues({
            search: ""
        })
    }

    //update food type status
    const updateStatus = async (id) => {
        try {
            let query = {
                id: id,
                MODULE_NAME
            };
            await props.dispatch(AuthService.updateStatus(query)).then((res) => {
                swal("Success!", res.message, "success");
                getData();
            });
        } catch (err) {
            console.log(err);
            setSending(false);
            if (err && err.data && err.data.message) {
                swal("Oops!", err.data.message, "error");
            }
        }
    }

    const handlePageChange = (page) => {
        setActivePage(page);
        window.scrollTo(0, 0);
    }

    const handleSubmit_ = async (e) => {
        try {
            e.preventDefault();
            let query = {
                page: activePage,
                MODULE_NAME,
                sortby,
                search: values.search
            };
            setLoad(true);
            setSending(true);
            await props.dispatch(AuthService.getList(query)).then((res) => {
                setLoad(false);
                setSending(false);
                setData(res.body.data);
            });
        } catch (err) {
            console.log(err);
            setSending(false);
            if (err && err.data && err.data.message) {
                swal("Oops!", err.data.message, "error");
            }
        }
    }

    return (
        <>
            <Helmet title="Spotted Deals" />
            <div className="app-title">
                <div>
                    <h1><i className="fa fa-th-list"></i> Spotted Deals</h1>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="tile">
                        <div className="row">
                            <div className="col-md-5">
                                <h3 className="tile-title">Spotted Deal Listing</h3>
                            </div>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <SelectInput
                                        name="sort_by"
                                        className="custom-select"
                                        onChange={(value) => setSortby(value.target.value)}
                                    >
                                        <option value="" disabled>Sort by </option>
                                        <option value="0">Asc</option>
                                        <option value="1">Desc</option>
                                    </SelectInput>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <form onSubmit={handleSubmit_}>
                                    <div className="input-group">
                                        <TextInput
                                            name="search"
                                            className="form-control"
                                            placeholder="Search by title..."
                                            value={values.search}
                                            onChange={handleChange}
                                        >
                                        </TextInput>
                                        <div className="input-group-append">
                                            <LoadingButton
                                                type="submit"
                                                className="btn btn-primary"
                                                loading={sending}
                                            >
                                                <i className="fa fa-search"></i>
                                            </LoadingButton>
                                        </div>
                                        <div className="input-group-append" style={{ marginLeft: '3px' }}>
                                            <LoadingButton
                                                type="button"
                                                className="btn btn-danger"
                                                onClick={clearFilter}
                                            >
                                                <i className="fa fa-times"></i>
                                            </LoadingButton>
                                        </div>
                                     
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-1">
                                            <div className="form-group">
                                                <Link to={`${Path.spotted_form_deals}`} className="btn btn-primary btn-sm banner-btn" title="Add">
                                                    <i className="fa fa-lg fa-plus"></i>Add
                                                </Link>
                                            </div>
                                        </div>
                        </div>
                        {load &&
                            <List
                                style={{ width: '100%', padding: 10, }}
                                speed={2}
                                height={150}
                                backgroundColor="#f3f3f3"
                                foregroundColor="#c1c5c7"
                                viewBox="30 0 380 30"
                            />
                        }

                        {!load && data.length > 0 &&
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Title</th>
                                            <th>Images</th>
                                            <th>Coupon code</th>
                                            <th>Expiry Date</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.length > 0 && data.map((item, i) => {
                                            // let expiryDate = moment(item.expiry_date, 'HH').format("h A");
                                            return (
                                                <tr key={i}>
                                                    <td>{item.title}</td>
                                                    <td>
                                                        <a href={item.images.length > 0 && item.images[0].original ? env.SERVER_URL + item.images[0].original : '#'} target="_blank">
                                                            <img alt="Avatar" class="table-avatar profile-image-size" src={item.images.length > 0 && item.images[0].original ? env.SERVER_URL + item.images[0].original : '/assets/images/dummy.png'} />
                                                        </a>
                                                    </td>
                                                    <td>{item.coupon_code}</td>
                                                    <td>{item.expiry_date}</td>
                                                    <td>
                                                        <Toggle id='cheese-status' checked={item.status == 1} onClick={() => updateStatus(item._id)} />
                                                    </td>
                                                    <td>
                                                        <Link to={`${Path.view_spotted_deals}/${item._id}`} className="btn btn-primary btn-sm" title="View">
                                                            <i className="fa fa-eye"></i>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                    <div className="pagination-box-review">
                                        {(totalItemsCount > itemsCountPerPage) &&
                                            <Pagination
                                                activePage={activePage}
                                                itemsCountPerPage={itemsCountPerPage}
                                                totalItemsCount={totalItemsCount}
                                                pageRangeDisplayed={pageRange}
                                                onChange={(page) => handlePageChange(page)}
                                                itemClass='page-item'
                                                linkClass='page-link'
                                            />
                                        }
                                    </div>
                                </table>
                            </div>
                        }
                        {(!load && data.length == 0) &&
                            <div className="row">
                                <div className="col-md-6 not-found-detail">
                                    <h6>Data not found....</h6>
                                </div>
                            </div>
                        }


                    </div>
                </div>
            </div>

        </>
    )
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SpottedDeals);
