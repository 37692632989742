import React, { useState, useEffect, useRef } from "react";
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import * as Path from 'routes/paths';
import AuthService from 'services';
import swal from 'sweetalert';
import * as actions from 'store/actions';
import env from 'env.json';
import { Field, reduxForm, reset } from 'redux-form';
import LoadingButton from 'components/shared/LoadingButton';
import ImageUploading from 'react-images-uploading';
import { css } from "@emotion/react";
import DotLoader from "react-spinners/DotLoader";

const renderField = ({
    input,
    placeholder,
    type,
    disabled,
    meta: { touched, error, warning }
}) => (
    <>
        <input {...input} type={type} placeholder={placeholder} disabled={disabled} className="form-control" />

        {touched &&
            (error && <span className="errorMessage">{error}</span>)
        }
    </>
)

const FormHomeBanner = (props) => {

    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;
    let [color, setColor] = useState("#ffffff");

    //history
    const history = useHistory();

    const [addsType, setAddstype] = useState(null);

    let editkey = props && props.match.params.id;
    const { handleSubmit, submitting } = props;

    const [sending, setSending] = useState(false);
    const [mainLoader, setMainLoader] = useState(false);
    const MODULE_NAME = 'banners';

    const [allData, setAllData] = useState({});
    const [values, setValues] = useState({
        title: "",
    });

    //images
    const [images, setImages] = useState([]);
    const [imageError, setImageError] = useState(false);
    const maxNumber = 5;

    //maincategories
    const [mainCategories, setMainCategories] = useState([]);
    const [getMainCatName, setGetMainCatName] = useState({});
    const [mainCat, setMainCat] = useState({
        main_cat_id: "",
    });

    //handle change
    const handleChange = (e) => {
        let getIndex = e.nativeEvent.target.selectedIndex
        setGetMainCatName(e.nativeEvent.target[getIndex].text);
        const { name, value } = e.target;
        setMainCat({
            ...mainCat,
            [name]: value
        });
        let catName = e.nativeEvent.target[getIndex].text;
        if (catName != 'Hotels' && catName != 'Entertainment' && catName != 'Employment' && catName != 'Employment' && catName != 'Shoppers' && catName != 'Spotted Deals') {
            getAllCatData(e.nativeEvent.target[getIndex].text);
        }
    };
    //categories
    const [bannerCategory, setBannerCategory] = useState([]);
    const [getCatName, setGetCatName] = useState("");
    const [bannerCatId, setBannerCatId] = useState({
        cat_id: ""
    });

    //handle change
    const handleChange2 = (e) => {
        let getIndex = e.nativeEvent.target.selectedIndex
        setGetCatName(e.nativeEvent.target[getIndex].text);
        const { name, value } = e.target;
        setBannerCatId({
            ...bannerCatId,
            [name]: value
        });
        // let subCatName = e.nativeEvent.target[getIndex].text;
        if (getMainCatName != 'Restaurants' && getMainCatName != 'Real estate' && getMainCatName != 'Travel Tourism' && getMainCatName != 'Cars') {
            getAllSubCatData(value);
        }
    };


    function checkFileType(item) {

        // Get the file extension by splitting the string at the dot (.)
        const fileExtension = item && item.split('.').pop().toLowerCase();
      
        // Define an array of common image file extensions
        const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
      
        // Check if the file extension is in the list of image extensions
        if (imageExtensions.includes(fileExtension)) {
          return 'image';
        } else {
          return 'video';
        }
      }

    //subcategory
    const [bannerSubCategory, setBannerSubCategory] = useState([]);
    const [getSubCatName, setGetSubCatName] = useState("");
    const [bannerSubCatId, setBannerSubCatId] = useState({
        sub_cat_id: ""
    });

    //handle change
    const handleChange3 = (e) => {
        let getIndex = e.nativeEvent.target.selectedIndex
        setGetSubCatName(e.nativeEvent.target[getIndex].text);
        const { name, value } = e.target;
        setBannerSubCatId({
            // sub_cat_id: value,
            ...bannerSubCatId,
            [name]: value
        });
    };

    //cars
    const [types, setTypes] = useState([]);
    const [type, setType] = useState({
        type_id: "",
    });
    //handle change
    const handleChangeType = (e) => {
        const { name, value } = e.target;
        setType({
            ...type,
            [name]: value
        });
    };
    const [models, setModels] = useState([]);
    const [model, setModel] = useState({
        model_id: "",
    });
    //handle change
    const handleChangeModel = (e) => {
        const { name, value } = e.target;
        setModel({
            ...model,
            [name]: value
        });
    };
    const [brands, setBrands] = useState([]);
    const [brand, setBrand] = useState({
        brand_id: "",
    });
    //handle change
    const handleChangeBrand = (e) => {
        const { name, value } = e.target;
        setBrand({
            ...brand,
            [name]: value
        });
    };
    //images
    let emptyArray = [...images]
    let emptyArray2 = []
    let arr = []


    const handleMediaChange = (event) => {
        const selectedFiles = event.target.files;
        let updatedMediaFiles = [];

        for (let i = 0; i < selectedFiles.length; i++) {
            const file = selectedFiles[i];
            const isImage = file.type.startsWith('image/');
            const isVideo = file.type.startsWith('video/');

            if (isImage || isVideo) {
                updatedMediaFiles.push({
                    data_url: URL.createObjectURL(file),
                    file: file,
                    priority_new: images.length + 1,
                    priority: '0',
                    isImage: isImage,
                });
            }
        }

        setImages([...images, ...updatedMediaFiles]);
    };

    const handleMediaRemove = (index) => {
        const updatedMedia = images.filter((_, i) => i !== index);
        setImages(updatedMedia);
    };




    function getUniqueListBy(arr, key) {
        return [...new Map(arr.map(item => [item[key], item])).values()]
    }

    const handleRemoveImgClick = async (img) => {
        let arr;
        if (img.data_url) {
            arr = images.filter((item) => item.data_url !== img.data_url);
        } else {
            let query = {
                MODULE_NAME,
                id: editkey,
                image_id:img._id,
            };

            await props.dispatch(AuthService.deleteImgData(query)).then((res) => {
                arr = images.filter((item) => item.original !== img.original);
            });
            // arr = images.filter((item) => item.original !== img.original);
        }
        setImages(arr);
    };

    const editModal = async () => {
        let query = {
            MODULE_NAME,
            id: editkey
        };
        setMainLoader(true);
        setSending(true);
        await props.dispatch(AuthService.getDetail(query)).then((res) => {
            setSending(false);
            setMainLoader(false);
            props.dispatch(actions.persist_store({ editFormHomeBanner: res.body.get_detail }));
            setImages(res.body.get_detail.image);
            setValues(res.body.get_detail);
            setAllData(res.body.get_detail);
            setMainCat(res.body.get_detail.main_cat_id);
            setGetSubCatName(res.body.get_detail.subcat_model_name);
            setGetCatName(res.body.get_detail.cat_model_name);
            setBannerCatId(res.body.get_detail.cat_id);
            setBannerSubCatId(res.body.get_detail.sub_cat_id);
            setType(res.body.types);
            setModel(res.body.models);
            setBrand(res.body.brands);
        });
    };

    const getMainCategories = async () => {
        let query = {
            MODULE_NAME,
            API_NAME: "main_categories",
        };
        setSending(true);
        await props.dispatch(AuthService.getHomeBannerCat(query)).then((res) => {
            setSending(false);
            setMainCategories(res.body);
        });
    };

    const getAllCatData = async (name) => {
        // console.log(name, "name")
        let query = {
            MODULE_NAME,
            API_NAME: "banner_categories",
            cat_name: name
        };
        setSending(true);
        await props.dispatch(AuthService.getCategoriesHomeBanner(query)).then((res) => {
            setSending(false);
            setBannerCategory(res.body);
            if (name == 'Cars') {
                setTypes(res.body.types);
                setModels(res.body.models);
                setBrands(res.body.brands);
            }
        });
    };

    const getAllSubCatData = async (cat_id) => {
        let query = {
            MODULE_NAME,
            API_NAME: "banner_subcategories",
            cat_name: getMainCatName,
            cat_id: cat_id
        };
        setSending(true);
        await props.dispatch(AuthService.getHomeBannerSubCat(query)).then((res) => {
            setSending(false);
            setBannerSubCategory(res.body);
        });
    };

    useEffect(() => {
        if (editkey) {
            editModal();
        }
        if (addsType != null && addsType?.banner_ads != 'home') {
            getMainCategories();
        }
    }, [getMainCatName]);
    //submit function
    const handleSubmit_ = async (values) => {
        try {
            let query = {};
            const formData = new FormData();
            images.map((v, i) => {
                return Object.assign(v, { priority_new: i + 1 })
            })
            let myNewImageArray = images.filter(function (obj) {
                return obj.data_url
            });
            let myDbImageArray = images.filter(function (obj) {
                return !obj.data_url
            });
            if (myNewImageArray.length > 0 && myNewImageArray[0]['data_url']) {
                for (let key in myNewImageArray) {
                    if (myNewImageArray[key]['file']) {
                        myNewImageArray[key].priority = editkey ? myNewImageArray[key].priority_new : key

                        const file = myNewImageArray[key]['file'];
                        // Check the file type
                        if (file.type.includes('image')) {
                            formData.append('type', 'image');
                        } else if (file.type.includes('video')) {
                            formData.append('type', 'video');
                        }
                    }
                    formData.append('image', myNewImageArray[key]['file']);
                }

                query.image = myNewImageArray.length > 0 && myNewImageArray[0]['data_url'] ? [...myNewImageArray] : ''

                formData.append('folder', 'banner');
                await props.dispatch(AuthService.fileUpload(formData)).then((res) => {
                    let newImg = res.body;
                    if (editkey) {
                        let allPriorityIds = [1, 2, 3, 4, 5]
                        let includePriorityIds = []
                        let exlcludePriorityIds = []
                        if (myDbImageArray.length > 0) {
                            for (let i in myDbImageArray) {
                                myDbImageArray[i].file_type = 'image'
                                myDbImageArray[i].folder = 'banner'
                                myDbImageArray[i].image = myDbImageArray[i].original
                                myDbImageArray[i].thumbnail = myDbImageArray[i].thumbnail
                                myDbImageArray[i].priority = myDbImageArray[i].priority_new
                                exlcludePriorityIds.push(myDbImageArray[i].priority_new)
                            }
                        }
                        allPriorityIds.filter(ids => {
                            if (!exlcludePriorityIds.includes(ids)) {
                                includePriorityIds.push(ids)
                            }
                            // return !exlcludePriorityIds.includes(ids);
                        })

                        let newArrayForSave = [...newImg, ...myDbImageArray]

                        for (let i in newArrayForSave) {
                            if (newArrayForSave[i].fileName) {
                                newArrayForSave[i].priority = includePriorityIds[i]
                            }
                        }
                        newArrayForSave.sort((a, b) => a.priority - b.priority);
                        query.image = JSON.stringify(newArrayForSave)
                    } else {
                        for (let i in newImg) {
                            newImg[i].priority = parseInt(i) + 1
                        }
                        query.image = JSON.stringify(newImg)
                    }
                });
            }
            let finalImageArray = []

            setSending(true);
            if (editkey) {
                query = {
                    ...query,
                    MODULE_NAME,
                    main_cat_id: mainCat ? mainCat._id : null,
                    cat_id: bannerCatId,
                    sub_cat_id: bannerSubCatId,
                    title: values.title,
                    type: addsType?.banner_ads,
                    cat_model_name: getCatName,
                    subcat_model_name: getSubCatName,
                    model_id: model ? model._id : 0,
                    type_id: type ? type._id : 0,
                    brand_id: brand ? brand._id : 0,
                    // image: images.length > 0 && images[0]['data_url'] ? [...images] : ''
                };
                query._id = editkey;
                await props.dispatch(AuthService.updateData(query)).then((res) => {
                    setSending(false);
                    swal("Success!", res.message, "success");
                    setValues({
                        title: "",
                    });
                    props.dispatch(actions.persist_store({ editFormHomeBanner: "" }));
                    props.dispatch(reset('formHomeBanner'));
                    history.push(Path.banner);
                });
            }
            else {
                query = {
                    ...query,
                    MODULE_NAME,
                    // main_cat_id:mainCat.main_cat_id ? mainCat.main_cat_id : mainCat,
                    main_cat_id: null,
                    sub_cat_id: bannerSubCatId.sub_cat_id ? bannerSubCatId.sub_cat_id : 0,
                    title: values.title,
                    type: addsType?.banner_ads,
                    cat_model_name: getCatName,
                    subcat_model_name: getSubCatName,
                    model_id: model.model_id ? model.model_id : 0,
                    type_id: type.type_id ? type.type_id : 0,
                    brand_id: brand.brand_id ? brand.brand_id : 0,
                    // image: images.length > 0 && images[0]['data_url'] ? [...images] : ''
                };
                setSending(true);
                await props.dispatch(AuthService.createData(query)).then((res) => {
                    setSending(false);
                    swal("Success!", res.message, "success");
                    setValues({
                        title: "",
                    });
                    props.dispatch(actions.persist_store({ editFormHomeBanner: "" }));
                    props.dispatch(reset('formHomeBanner'));
                    history.push(Path.banner);
                });
            }
        } catch (err) {
            console.log(err);
            setSending(false);
            if (err && err.data && err.data.message) {
                swal("Oops!", err.data.message, "error");
            }
        }
    }

    const dragItem = useRef();
    const dragOverItem = useRef();

    const dragStart = (e, position) => {
        dragItem.current = position;
    };
    const dragEnter = (e, position) => {
        dragOverItem.current = position;
    };

    const drop = (e) => {
        const copyListItems = [...images];
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);
        dragItem.current = null;
        dragOverItem.current = null;
        setImages(copyListItems);
    };

    //handle change
    const handleChangeAdd = (e) => {
        const { name, value } = e.target;
        setAddstype({
            ...addsType,
            [name]: value
        });
        setMainCat({});
        setBannerCatId({});
        setTypes({});
        setModels({});
        setBrands({});
        setGetMainCatName({})
    };
    // console.log(props.match.params.type, "props.match.params")
    // render form
    const _renderForm = () => {
        return (
            <>
                {console.log("Check the res", images)}
                <form onSubmit={handleSubmit(handleSubmit_)}>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12" style={{ marginBottom: 20 }}>
                                <h3 className="card-title"><b>{editkey ? 'Edit' : 'Add'} Banners</b></h3>
                            </div>


                            <div className="col-md-12">
                                <div className="form-group">
                                    <label for="exampleSelect1">Select Adds Type</label>
                                    <select
                                        name="banner_ads"
                                        value={addsType?.banner_ads}
                                        onChange={handleChangeAdd}
                                        className={`form-control`}
                                    >
                                        {/* <option value="">Select</option> */}
                                        <option value="home" selected={mainCat == null} disabled={editkey ? 'disabled' : ''} >Home</option>
                                        {/* <option value="category" selected={mainCat?.name} disabled={editkey ? 'disabled' : ''}>Category</option>
                                            <option value="subcategory" disabled={editkey ? 'disabled' : ''}>SubCategory</option> */}
                                    </select>
                                </div>
                            </div>
                            {(addsType?.banner_ads === 'category' || addsType?.banner_ads === 'subcategory') &&
                                <>
                                    {!editkey &&
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label for="exampleSelect1">Select Main Categories</label>
                                                <select
                                                    name="main_cat_id"
                                                    value={mainCat.main_cat_id}
                                                    onChange={handleChange}
                                                    className={`form-control`}
                                                >
                                                    <option value="">Select Main Category</option>
                                                    {mainCategories.length > 0 && mainCategories.map((item, key) => {
                                                        return (
                                                            <option key={key} value={item._id} selected={item._id == mainCat} disabled={editkey ? 'disabled' : ''}>
                                                                {item.name}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    }
                                </>
                            }
                            {(getMainCatName === 'Cars' && addsType?.banner_ads !== 'home') &&
                                <>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label for="exampleSelect1">Types</label>
                                            <select
                                                name="type_id"
                                                value={type.type_id}
                                                onChange={handleChangeType}
                                                className={`form-control`}
                                            >
                                                <option value="">Select Types</option>
                                                {types.length > 0 && types.map((item, key) => {
                                                    return (
                                                        <option key={key} value={item._id} selected={item._id == type._id}>
                                                            {item.name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label for="exampleSelect1">Models</label>
                                            <select
                                                name="model_id"
                                                value={type.model_id}
                                                onChange={handleChangeModel}
                                                className={`form-control`}
                                            >
                                                <option value="">Select Models</option>
                                                {models.length > 0 && models.map((item, key) => {
                                                    return (
                                                        <option key={key} value={item._id} selected={item._id == model._id}>
                                                            {item.name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label for="exampleSelect1">Brands</label>
                                            <select
                                                name="brand_id"
                                                value={type.brand_id}
                                                onChange={handleChangeBrand}
                                                className={`form-control`}
                                            >
                                                <option value="">Select Brands</option>
                                                {brands.length > 0 && brands.map((item, key) => {
                                                    return (
                                                        <option key={key} value={item._id} selected={item._id == brand._id}>
                                                            {item.name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </>
                            }

                            {(getMainCatName != 'Hotels' && getMainCatName != 'Entertainment' && getMainCatName != 'Employment' && getMainCatName != 'Shoppers' && getMainCatName != 'Spotted Deals' && getMainCatName != 'Cars') &&
                                <>
                                    {(mainCategories.length > 0 && mainCat.main_cat_id) &&
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label for="exampleSelect1">Select Category</label>
                                                <select
                                                    name="cat_id"
                                                    value={bannerCatId.cat_id}
                                                    onChange={handleChange2}
                                                    className={`form-control`}
                                                >
                                                    <option value="">Select Category</option>
                                                    {bannerCategory.length > 0 && bannerCategory.map((item, key) => {
                                                        return (
                                                            <option key={key} value={item._id} selected={item._id == bannerCatId}>
                                                                {item.type ? item.type : item.name}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    }
                                </>
                            }
                            {(editkey && mainCat) &&
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label for="exampleSelect1">Main Category</label>
                                        <input disabled name="main_cat_id" type="text" className="form-control" value={mainCat.name} />
                                    </div>
                                </div>
                            }
                            {(editkey && bannerCatId != 0) &&
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label for="exampleSelect1">Category</label>
                                        <input disabled name="cat_model_name" type="text" className="form-control" value={getCatName} />
                                    </div>
                                </div>
                            }
                            {(editkey && bannerSubCatId != 0) &&
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label for="exampleSelect1">SubCategory</label>
                                        <input disabled name="subcat_model_name" type="text" className="form-control" value={getSubCatName} />
                                    </div>
                                </div>
                            }
                            {(getMainCatName != 'Restaurants' && getMainCatName != 'Hotels' && getMainCatName != 'Entertainment' && getMainCatName != 'Employment' && getMainCatName != 'Shoppers' && getMainCatName != 'Spotted Deals' && getMainCatName != 'Real estate' && getMainCatName != 'Travel Tourism' && getMainCatName != 'Cars') &&
                                <>
                                    {(bannerCatId.cat_id && bannerCategory.length > 0) &&
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label for="exampleSelect1">Select SubCategory</label>
                                                <select
                                                    name="sub_cat_id"
                                                    value={bannerSubCatId.sub_cat_id}
                                                    onChange={handleChange3}
                                                    className={`form-control`}
                                                >
                                                    <option value="">Select SubCategory</option>
                                                    {bannerSubCategory.length > 0 && bannerSubCategory.map((item, key) => {
                                                        return (
                                                            <option key={key} value={item._id} selected={item._id == bannerSubCatId.sub_cat_id}>
                                                                {item.name}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    }
                                </>
                            }

                            {(editkey && allData && allData.type_id != 0) &&
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label for="exampleSelect1">Type</label>
                                        <input disabled name="type" type="text" className="form-control" value={type.name} />
                                    </div>
                                </div>
                            }
                            {(editkey && allData && allData.model_id != 0) &&
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label for="exampleSelect1">Models</label>
                                        <input disabled name="model" type="text" className="form-control" value={model.name} />
                                    </div>
                                </div>
                            }
                            {(editkey && allData && allData.brand_id != 0) &&
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label for="exampleSelect1">Brands</label>
                                        <input disabled name="brand" type="text" className="form-control" value={brand.name} />
                                    </div>
                                </div>
                            }
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label for="exampleInputEmail1">Title</label>
                                    <Field
                                        name="title"
                                        component={renderField}
                                        type="text"
                                    />
                                </div>
                            </div>
                        </div>
                        <label for="exampleInputEmail1">Image (You can select upto 5 Images same time)</label>
                        <br />
                        {imageError &&
                            <span className="errorMessage">Maximum limit is 5</span>
                        }
                        <div>
                            <input type="file" accept="image/*, video/*" onChange={handleMediaChange} multiple />
                            {/* {
                                images.map((media, index) => {
                                    if (!media.hasOwnProperty('isImage')) {
                                        media.isImage = media.original.split(".")[1];
                                    }
                                    return (
                                        <div key={index} className="media-container">
                                            {media.isImage ? (
                                                <img src={media.data_url || media.original} alt="" className="media-item" />
                                            ) : (
                                                <video controls src={media.data_url || media.original} className="media-item" />
                                            )}
                                            <div className="media-options">
                                                <div onClick={() => {
                                                    handleMediaRemove(index) 
                                                    handleRemoveImgClick(media)
                                                    }}>Remove</div>
                                            </div>
                                        </div>
                                    );
                                })
                            } */}



                            {
                                images.map((media, index) => {
                                    const fileType = checkFileType(media.original);
                                    return (
                                        <div key={index} className="media-container">

                                            {fileType === 'image' ? (
                                                <img src={media.data_url || media.original} alt="" className="media-item" />
                                            ) : (
                                                <video controls src={media.data_url || media.original} className="media-item" />
                                            )}

                                             <div className="media-options">
                                                <div onClick={() => {
                                                    handleMediaRemove(index) 
                                                    handleRemoveImgClick(media)
                                                    }}>Remove</div>
                                            </div>
                                        </div>
                                    );
                                })
                            }


                        </div>

                        <div className="row">
                            <div className="col-4 loading--submit-button" style={{ marginTop: '20px' }}>
                                <LoadingButton
                                    type="submit"
                                    className="btn btn-primary"
                                    loading={sending}
                                    disabled={submitting}
                                >
                                    {editkey ? 'Update' : 'Submit'}
                                </LoadingButton>
                            </div>
                        </div>
                    </div>
                </form>
            </>
        )
    }

    return (
        <>
            <Helmet title="Banners" />
            {mainLoader &&
                <div className="main-loader">
                    <DotLoader color={color} loading={mainLoader} css={override} size={150} />
                </div>
            }
            {!mainLoader &&
                <>
                    <div className="app-title">
                        <div>
                            <h1><i className="fa fa-th-list"></i> Banners</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="tile">
                                <div className="tile-body">
                                    {_renderForm()}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }

        </>
    )
}

const initialValues = (values) => {
    if (values) {
        return {
            ...values,
            title: values.title ? values.title : "",
        }
    }
}

const validate = (values) => {
    const errors = {}
    //title
    if (!values.title) {
        errors.title = 'Required*'
    }
    return errors
}

const mapStateToProps = (state) => {
    return {
        initialValues: initialValues(state.persistStore.editFormHomeBanner),
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}


const FormHomeBannerForm = reduxForm({
    form: 'formHomeBanner',
    enableReinitialize: true,
    validate
})(FormHomeBanner);

export default connect(mapStateToProps, mapDispatchToProps)(FormHomeBannerForm);
