import React from "react";
import usePlacesAutocomplete from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import axios from "axios";
const PlacesAutocomplete = ({setSelectedLocation}) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
  } = usePlacesAutocomplete({ callbackName: "YOUR_CALLBACK_NAME" });

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = async (val) => {
    try {
      const reqUrl = `https://maps.googleapis.com/maps/api/place/textsearch/json?query=${val}&key=AIzaSyAcWaQOI8ee-mAh7CDGOuJ0--u4MCN6elw`;
      setValue(val, false);
      setSelectedLocation(val)
    } catch (err) {
      console.log("get place error :-", err);
    }
  };

  return (
    <Combobox onSelect={handleSelect} aria-labelledby="demo">
      <ComboboxInput value={value} onChange={handleInput} disabled={!ready} />
      <ComboboxPopover>
        <ComboboxList>
          {status === "OK" &&
            data.map(({ place_id, description }) => (
              <ComboboxOption key={place_id} value={description} />
            ))}
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
  );
};
export default PlacesAutocomplete;
