import React, {useState, useEffect} from "react";
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import * as Path from 'routes/paths';
import AuthService from 'services';
import swal from 'sweetalert';
import * as actions from 'store/actions';
import env from 'env.json';
import { Field, reduxForm, reset } from 'redux-form';
import LoadingButton from 'components/shared/LoadingButton';
import ImageUploading from 'react-images-uploading';
import ImageUploader from 'react-images-upload';
import moment from "moment";
import { List } from 'react-content-loader';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';

const renderField = ({
    input,
    placeholder,
    type,
    disabled,
    min,
    meta: { touched, error, warning }
}) => (
    <>
        <input {...input} type={type} placeholder={placeholder} min={min} disabled={disabled} className="form-control"/>
        
        {touched &&
            (error && <span className="errorMessage">{error}</span>)
        }
    </>
)
const renderFieldTextArea = ({
    input,
    placeholder,
    type,
    disabled,
    meta: { touched, error, warning }
}) => (
    <>
        {/* <textarea {...input} type={type} placeholder={placeholder} disabled={disabled} className="form-control"/> */}
        <CKEditor
                data={input.value}
                editor={ ClassicEditor }
                config={{
                    toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ] 
                }}
                onChange={(event, editor) => {
                    return input.onChange(editor.getData())
                }
                }
        />
        {touched &&
            (error && <span className="errorMessage">{error}</span>)
        }
    </>
)


// const renderckEditor = ({
//     input,
//     label,
//     placeholder,
//     type,
//     meta: { touched, error, warning }
// }) => (
//     <>
//         <CKEditor
//                 data={input.value}
//                 editor={ ClassicEditor }
//                 config={{
//                     toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ] 
//                 }}
//                 onChange={(event, editor) => {
//                     return input.onChange(editor.getData())
//                 }
//                 }
//         />
//         {touched &&
//             (error && <span className="errorMessage">{error}</span>)
//         }
//     </>
// )

const FormSubscription = (props) => {
    
    //history
    const history = useHistory();
 // render form
    let editkey = props && props.match.params.id;

    const { handleSubmit, submitting} = props;
    const [sending, setSending] = useState(false);
    //maincategories
    const [ mainCategories, setMainCategories] = useState([]);  
    const [ mainCat, setMainCat] = useState({
        main_cat_id:"",
    });
    const [ type, setType] = useState({

    });
    const MODULE_NAME = 'subscriptions';
    const [catArr, setCatArr] = useState(['Market Place','Shoppers','Spotted Deals']);
    const [ values, setValues] = useState({
        category_id:"",
        type:"",
        description:"",
        price:"",
    });

    //handle change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setMainCat({
            ...mainCat,
            [name]: value
        });
    }; 
    const handleChange2 = (e) => {
        const { name, value } = e.target;
        setType({
            ...type,
            [name]: value
        });
    }; 

    const editModal = async () => {
        let query = {
            MODULE_NAME,
            id:editkey
        };
        setSending(true);
        await props.dispatch(AuthService.getDetail(query)).then((res) => {
            console.log(res.body, "-")
            setSending(false);
            props.dispatch(actions.persist_store({  editFormsubscription:res.body}));
            setMainCat(res.body.category_id._id);
            setType(res.body.type);
            setValues(res.body);
        });
    };  
    
    const getMainCategories = async () => {
        let query = {
            MODULE_NAME:'banners',
            API_NAME:"main_categories",
        };
        setSending(true);
        await props.dispatch(AuthService.getHomeBannerCat(query)).then((res) => {
            setSending(false);
            let arr = res.body;
            arr = arr.filter((data) => {
                return !catArr.includes(data.name)
            })
            setMainCategories(arr);
        });
    }; 

    useEffect(() => {
        if(editkey){
            editModal();
        }
        getMainCategories();
    }, []);
   
    const handleSubmit_ = async (values) => {
        try{
            let query = {
                MODULE_NAME,
                // type: type ? type.type : type,
                category_id:mainCat.category_id ? mainCat.category_id : mainCat,
                price:values.price,
                description:values.description,
               
            };
            setSending(true);
            if(editkey){
                query.subscription_id = editkey;
                query.type = type
                await props.dispatch(AuthService.updateData(query)).then((res) => {
                    setSending(false);
                    swal("Success!", res.message, "success");
                    setValues({
                        category_id:"",
                        type:"",
                        description:"",
                        price:"",
                    });
                    props.dispatch(actions.persist_store({ editFormsubscription:""}));
                    props.dispatch(reset('formSubscription'));
                    history.push(Path.subscription);
                });
            }
            else{
                query.type = type.type
                await props.dispatch(AuthService.createData(query)).then((res) => {
                    setSending(false);
                    swal("Success!", res.message, "success");
                    setValues({
                        category_id:"",
                        type:"",
                        description:"",
                        price:"",
                    });
                    props.dispatch(actions.persist_store({ editFormsubscription:""}));
                    props.dispatch(reset('formSubscription'));
                    history.push(Path.subscription);
                });
            }
        }catch(err) {
            console.log(err);
            setSending(false);
            if(err && err.data && err.data.message){
                swal("Oops!", err.data.message, "error");
            }
        }
    }


    const _renderForm = () => {
    return(
        <>
            <form onSubmit={handleSubmit(handleSubmit_)}>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12" style={{marginBottom:20}}>
                            <h3 className="card-title"><b>{editkey ? 'Edit' : 'Add'} Subscription </b></h3>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                            <label for="exampleSelect1">Select Categories</label>
                                <select
                                    name="category_id"
                                    value={mainCat.category_id}
                                    onChange={handleChange}
                                    className={`form-control`}
                                    required
                                    disabled={editkey ? 'disabled' : ''}
                                    >
                                    <option value="">Select Category</option>
                                    {mainCategories.length>0 && mainCategories.map((item, key) => {
                                    return(
                                            <option key={key} value={item._id} selected={item._id==mainCat} disabled={editkey ? 'disabled' : ''}>
                                                {item.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                            <label for="exampleSelect1">Select Type</label>
                                <select
                                    name="type"
                                    onChange={handleChange2}
                                    className={`form-control`}
                                    required
                                  
                                    disabled={editkey ? 'disabled' : ''}
                                    >
                                    <option value="">Select Type</option>
                                    <option value="1" selected={type==1}>Monthly</option>
                                    <option value="2" selected={type==2}>Annually</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label for="exampleInputEmail1">Price</label>
                                <Field 
                                    name="price" 
                                    component={renderField}
                                    type="text" 
                                    min={0}
                                />
                            </div>
                        </div>
                       
                      
                        <div className="col-md-12">
                            <div className="form-group">
                                <label for="exampleInputEmail1">Description</label>
                                <Field 
                                    name="description" 
                                    component={renderFieldTextArea}
                                    type="text" 
                                />
                            </div>
                        </div>
                    </div>
                   
                    <div className="row">
                        <div className="col-4 loading--submit-button" style={{marginTop:'20px'}}>
                            <LoadingButton
                                type="submit"
                                className="btn btn-primary"
                                loading={sending}
                                disabled={submitting}
                            >
                                {editkey ? 'Update' : 'Submit'}
                            </LoadingButton>
                        </div>
                    </div>  
                </div>
            </form>
        </>
    )}

    return(
        <>
        <Helmet title="Subscription" />
            <div className="app-title">
                <div>
                <h1>Subscription</h1>
                </div>
                
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="tile">
                        <div className="tile-body">
                            {_renderForm()}
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

const initialValues = (values) => {	
	if(values) {
		return {
			...values,
            // type: values.type ? values.type : "",
            price: values.price ? values.price : "",
            category_id: values.category_id ? values.category_id : "",
            description: values.description ? values.description : "",
		}
	}
}

const validate = (values) => {
    const errors = {}
    //title
    if (!values.category_id) {
        errors.category_id = 'Required*'
    }
    if (!values.type) {
      errors.type = 'Required*'
    }
    if (!values.price) {
        errors.price = 'Required*'
    }
    if (!values.description) {
        errors.description = 'Required*'
    }
    return errors
}

  
const mapStateToProps = (state) => {
    return{
        initialValues: initialValues(state.persistStore.editFormsubscription),
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

const SubscriptionForm = reduxForm({
    form: 'formSubscription',
    enableReinitialize: true,
    validate
})(FormSubscription);

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionForm);
