import React, { useState, useEffect, useRef } from "react";
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import * as Path from 'routes/paths';
import AuthService from 'services';
import swal from 'sweetalert';
import * as actions from 'store/actions';
import env from 'env.json';
import { Field, reduxForm, reset, formValueSelector } from 'redux-form';
import LoadingButton from 'components/shared/LoadingButton';
import ImageUploading from 'react-images-uploading';
import { css } from "@emotion/react";
import DotLoader from "react-spinners/DotLoader";
import moment from "moment";
// import LocationSearch from './locationSearch';




const renderField = ({
    input,
    placeholder,
    type,
    disabled,
    min,
    meta: { touched, error, warning }
}) => (
    <>
        <input {...input} type={type} placeholder={placeholder} disabled={disabled} min={min} className="form-control" />

        {touched &&
            (error && <span className="errorMessage">{error}</span>)
        }
    </>
)

const CreateDeals = (props) => {
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;
    let [color, setColor] = useState("#ffffff");

    const todayDate = moment().format('YYYY-MM-DD')
    const endDate = moment(todayDate).format('YYYY-MM-DD')
    const [finalEndDate, setFinalEndDate] = useState(endDate);
    const [selectedPlaceData, setSelectedPlaceData] = useState(null);
    const [selectedAddress, setSelectedAddress] = useState("");
    const [latlng, setLatlng] = useState([]);
    const autoCompleteRef = useRef();
    const inputRef = useRef();
    const options = {
     // componentRestrictions: { country: "us" },
     fields: ["address_components", "geometry", "icon", "name"],
     types: ["establishment"]
    };

    useEffect(() => {
        autoCompleteRef.current = new window.google.maps.places.Autocomplete(
          inputRef.current,
          options
        );
    
        autoCompleteRef.current.addListener("place_changed", () => {
          const selectedPlace = autoCompleteRef.current.getPlace();
    
          if (selectedPlace && selectedPlace.geometry && selectedPlace.geometry.location) {
            const { lat, lng } = selectedPlace.geometry.location;
            const address = selectedPlace.name || "";
    
            setSelectedAddress(address);

            // geometry
    
            setLatlng([lng(),lat()])
            console.log("Latitude:", lat());
            console.log("Longitude:", lng());
            console.log("Complete Address:", selectedPlace);
          }
        });
      }, []);



    const handlePlaceSelect = (placeData) => {
        setSelectedPlaceData(placeData);
        console.log(selectedPlaceData,'==========selectedPlaceData===========')
      };

    //history
    const history = useHistory();


    const [addsType, setAddstype] = useState(null);
    const [catArr, setCatArr] = useState(['Market Place', 'Shoppers', 'Spotted Deals']);
    const [appUrl, setAppUrl] = useState({});


    let editkey = props && props.match.params.id;
    const { handleSubmit, submitting } = props;


    const [sending, setSending] = useState(false);
    const [mainLoader, setMainLoader] = useState(false);
    const MODULE_NAME = 'spotted_deals';


    const [allData, setAllData] = useState({});
    const [values, setValues] = useState({
        title: "",
        coupon_code: "",
        description: "",
        expiry_date: "",
        location: "",
    
    });

    //images
    const [images, setImages] = useState([]);
    const [imageError, setImageError] = useState(false);
    const maxNumber = 5;

    //maincategories
    const [mainCategories, setMainCategories] = useState([]);
    const [getMainCatName, setGetMainCatName] = useState({});
    const [mainCat, setMainCat] = useState({
        main_cat_id: "",
    });
    const handleChangeDate = (e) => {
        const { name, value } = e.target;
        if (name == 'start_date') {
            let setEnddate = moment(e.target.value).format('YYYY-MM-DD')
            setFinalEndDate(setEnddate)
            props.reset({ 'expiry_date': '' })
        }
    }

    const handleChangeTitle = (e) => {

        console.log(e.target.value,'===============================e=========================')

        const { name, value } = e.target;
        setAppUrl({
            ...appUrl,
            [name]: value
        });
    }


    const handleMediaChange = (event) => {
        const selectedFiles = event.target.files;
        let updatedMediaFiles = [];

        for (let i = 0; i < selectedFiles.length; i++) {
            const file = selectedFiles[i];
            const isImage = file.type.startsWith('image/');
            const isVideo = file.type.startsWith('video/');

            if (isImage || isVideo) {
                updatedMediaFiles.push({
                    data_url: URL.createObjectURL(file),
                    file: file,
                    priority_new: images.length + 1,
                    priority: '0',
                    isImage: isImage,
                });
            }
        }

        setImages([...images, ...updatedMediaFiles]);
    };

    const handleMediaRemove = (index) => {
        const updatedMedia = images.filter((_, i) => i !== index);
        setImages(updatedMedia);
    };

    //handle change
    const handleChange = (e) => {
        let getIndex = e.nativeEvent.target.selectedIndex
        setGetMainCatName(e.nativeEvent.target[getIndex].text);
        const { name, value } = e.target;
        setMainCat({
            ...mainCat,
            [name]: value
        });
        let catName = e.nativeEvent.target[getIndex].text;
        if (catName != 'Hotels' && catName != 'Entertainment' && catName != 'Employment' && catName != 'Shoppers' && catName != 'Spotted Deals') {
            getAllCatData(e.nativeEvent.target[getIndex].text);
        }
        setGetCatName("")
        setBannerCatId({})
        setBannerSubCatId({})
        setGetSubCatName("")
        setModel({})
        setType({})
        setBrand({})
    };
    //categories
    const [bannerCategory, setBannerCategory] = useState([]);
    const [getCatName, setGetCatName] = useState("");
    const [bannerCatId, setBannerCatId] = useState({
        cat_id: ""
    });

    //handle change
    const handleChange2 = (e) => {
        let getIndex = e.nativeEvent.target.selectedIndex
        setGetCatName(e.nativeEvent.target[getIndex].text);
        const { name, value } = e.target;
        setBannerCatId({
            ...bannerCatId,
            [name]: value
        });
        // let subCatName = e.nativeEvent.target[getIndex].text;
        if (getMainCatName != 'Restaurants' && getMainCatName != 'Real estate' && getMainCatName != 'Travel Tourism' && getMainCatName != 'Cars') {
            getAllSubCatData(value);
        }
    };

    //subcategory
    const [bannerSubCategory, setBannerSubCategory] = useState([]);
    const [getSubCatName, setGetSubCatName] = useState("");
    const [bannerSubCatId, setBannerSubCatId] = useState({
        sub_cat_id: ""
    });

    //handle change
    const handleChange3 = (e) => {
        let getIndex = e.nativeEvent.target.selectedIndex
        setGetSubCatName(e.nativeEvent.target[getIndex].text);
        const { name, value } = e.target;
        setBannerSubCatId({
            // sub_cat_id: value,
            ...bannerSubCatId,
            [name]: value
        });
    };

    //cars
    const [types, setTypes] = useState([]);
    const [type, setType] = useState({
        type_id: "",
    });
    //handle change
    const handleChangeType = (e) => {
        const { name, value } = e.target;
        setType({
            ...type,
            [name]: value
        });
    };
    const [models, setModels] = useState([]);
    const [model, setModel] = useState({
        model_id: "",
    });
    //handle change
    const handleChangeModel = (e) => {
        const { name, value } = e.target;
        setModel({
            ...model,
            [name]: value
        });
    };
    const [brands, setBrands] = useState([]);
    const [brand, setBrand] = useState({
        brand_id: "",
    });
    //handle change
    const handleChangeBrand = (e) => {
        const { name, value } = e.target;
        setBrand({
            ...brand,
            [name]: value
        });
    };
    //images
    let emptyArray = [...images]

    const onChange = (imageList) => {
        console.log(imageList, "imageList")
        if (images.length >= 5) {
            setImageError(true);
        } else {
            setImageError(false);
            imageList.forEach((image, index) => {
                emptyArray.push(image)
                // arr.push(image)
                setImages([...images, image]);

            });
            emptyArray = getUniqueListBy(emptyArray, 'data_url')
            let myNewImageArray = emptyArray.filter(function (obj) {
                return obj.data_url
            });
            let myDbImageArray = images.filter(function (obj) {
                return !obj.data_url
            });
            setImages([...myDbImageArray, ...myNewImageArray])
        }
    };

    function getUniqueListBy(arr, key) {
        return [...new Map(arr.map(item => [item[key], item])).values()]
    }

    const handleRemoveImgClick = async (img) => {
        let arr;
        if (img.data_url) {
            arr = images.filter((item) => item.data_url !== img.data_url);
        } else {
            let query = {
                MODULE_NAME,
                id: editkey,
                image_id: img._id,
            };

            await props.dispatch(AuthService.deleteImgData(query)).then((res) => {
                arr = images.filter((item) => item.original !== img.original);
            });
        }
        setImages(arr);
    };

    const editModal = async () => {
        let query = {
            MODULE_NAME,
            id: editkey
        };
        setMainLoader(true);
        setSending(true);
        await props.dispatch(AuthService.getDetail(query)).then((res) => {
            setSending(false);
            setMainLoader(false);
            props.dispatch(actions.persist_store({ editCreateAdds: res.body.get_detail }));
            setImages(res.body.get_detail.image);
            setValues(res.body.get_detail);
            setAllData(res.body.get_detail);
            setMainCat(res.body.get_detail.main_category_id);
            setGetSubCatName(res.body.get_detail.subcat_model_name);
            setGetCatName(res.body.get_detail.cat_model_name);
            setBannerCatId(res.body.get_detail.category_id);
            setBannerSubCatId(res.body.get_detail.sub_category_id);
            setType(res.body.types);
            setModel(res.body.models);
            setBrand(res.body.brands);
            setAddstype(res.body.get_detail.type)
        });
    };

    const getMainCategories = async () => {
        let query = {
            MODULE_NAME,
            API_NAME: "main_categories",
        };
        setSending(true);
        await props.dispatch(AuthService.getHomeBannerCat(query)).then((res) => {
            setSending(false);
            let arr = res.body;
            arr = arr.filter((data) => {
                return !catArr.includes(data.name)
            })
            setMainCategories(arr);

        });
    };

    const getAllCatData = async (name) => {
        // console.log(name, "name")
        let query = {
            MODULE_NAME,
            API_NAME: "banner_categories",
            cat_name: name
        };
        setSending(true);
        await props.dispatch(AuthService.getCategoriesHomeBanner(query)).then((res) => {
            setSending(false);
            setBannerCategory(res.body);
            if (name == 'Cars') {
                setTypes(res.body.types);
                setModels(res.body.models);
                setBrands(res.body.brands);
            }
        });
    };

    const getAllSubCatData = async (cat_id) => {
        let query = {
            MODULE_NAME,
            API_NAME: "banner_subcategories",
            cat_name: getMainCatName,
            cat_id: cat_id
        };
        setSending(true);
        await props.dispatch(AuthService.getHomeBannerSubCat(query)).then((res) => {
            setSending(false);
            setBannerSubCategory(res.body);
        });
    };


    function checkFileType(item) {
        // Get the file extension by splitting the string at the dot (.)
        const fileExtension = item.split('.').pop().toLowerCase();

        // Define an array of common image file extensions
        const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];

        // Check if the file extension is in the list of image extensions
        if (imageExtensions.includes(fileExtension)) {
            return 'image';
        } else {
            return 'video';
        }
    }

    useEffect(() => {
        if (editkey) {
            editModal();
        }
        if (addsType != null && addsType?.banner_ads != 'home') {
            getMainCategories();
        }
    }, [getMainCatName]);


    //submit function
    const handleSubmit_ = async (values) => {
        try {
            let query = {};
            const formData = new FormData();
            images.map((v, i) => {
                return Object.assign(v, { priority_new: i + 1 })
            })
            let myNewImageArray = images.filter(function (obj) {
                return obj.data_url
            });
            let myDbImageArray = images.filter(function (obj) {
                return !obj.data_url
            });
            if (myNewImageArray.length > 0 && myNewImageArray[0]['data_url']) {
                for (let key in myNewImageArray) {
                    if (myNewImageArray[key]['file']) {
                        myNewImageArray[key].priority = editkey ? myNewImageArray[key].priority_new : key

                        const file = myNewImageArray[key]['file'];
                        // Check the file type
                        if (file.type.includes('image')) {
                            formData.append('type', 'image');
                        } else if (file.type.includes('video')) {
                            formData.append('type', 'video');
                        }
                    }
                    formData.append('image', myNewImageArray[key]['file']);
                }

                query.image = myNewImageArray.length > 0 && myNewImageArray[0]['data_url'] ? [...myNewImageArray] : ''
                formData.append('folder', 'adds');
                await props.dispatch(AuthService.fileUpload(formData)).then((res) => {
                    let newImg = res.body;
                    if (editkey) {
                        if (myDbImageArray.length > 0) {
                            for (let i in myDbImageArray) {
                                myDbImageArray[i].file_type = 'image'
                                myDbImageArray[i].folder = 'adds'
                                myDbImageArray[i].image = myDbImageArray[i].original
                                myDbImageArray[i].thumbnail = myDbImageArray[i].thumbnail
                            }
                        }

                        let newArrayForSave = [...newImg, ...myDbImageArray]

                        query.image = JSON.stringify(newArrayForSave)
                    } else {
                        query.image = JSON.stringify(newImg)
                    }

                });
            }

            setSending(true);
            if (editkey) {
                query = {
                    ...query,
                    MODULE_NAME,
                    main_category_id: mainCat ? mainCat._id : null,
                    category_id: bannerCatId,
                    sub_category_id: bannerSubCatId,
                    title: appUrl ? appUrl.title : values.title,
                    type: addsType,
                    cat_model_name: getCatName,
                    subcat_model_name: getSubCatName,
                    start_date: values.start_date,
                    expiry_date: values.expiry_date,
                    model_id: model ? model._id : 0,
                    type_id: type ? type._id : 0,
                    brand_id: brand ? brand._id : 0,
                    // image: images.length > 0 && images[0]['data_url'] ? [...images] : ''
                };
                query._id = editkey;
                await props.dispatch(AuthService.updateData(query)).then((res) => {
                    setSending(false);
                    swal("Success!", res.message, "success");
                    setValues({
                        title: "",
                        start_date: "",
                        expiry_date: "",
                    });
                    props.dispatch(actions.persist_store({ editCreateAdds: "" }));
                    props.dispatch(actions.persist_store({ valuess: "" }));
                    props.dispatch(reset('createAdds'));
                    history.push(Path.ads);
                });
            }
            else {
                console.log(appUrl, "appUrl")
                query = {
                    ...query,
                    MODULE_NAME,
                    title: appUrl ? appUrl.title : values.title,
                    coupon_code: appUrl ? appUrl.coupon_code : values.coupon_code,
                    location: appUrl ? selectedAddress : selectedAddress,
                    description: appUrl ? appUrl.description : values.description,
                    expiry_date: values.expiry_date,
                    geometry: appUrl ?  latlng  : latlng,
                    // image: images.length > 0 && images[0]['data_url'] ? [...images] : ''
                };
                console.log(query, "query=======================spotted_deals================")

                setSending(true);
                await props.dispatch(AuthService.createData(query)).then((res) => {
                    setSending(false);
                    swal("Success!", res.message, "success");
                    setValues({
                        title: "",
                        expiry_date: "",
                        coupon_code: "",
                        description: "",
                        location: "",
                        
                    });
                    props.dispatch(actions.persist_store({ editCreateAdds: "" }));
                    props.dispatch(actions.persist_store({ valuess: "" }));
                    props.dispatch(reset('createAdds'));
                    history.push(Path.spotted_deals);
                });
            }
        } catch (err) {
            console.log(err);
            setSending(false);
            if (err && err.data && err.data.message) {
                swal("Oops!", err.data.message, "error");
            }
        }
    }

    //handle change
    const handleChangeAdd = (e) => {
        const { name, value } = e.target;
        setAddstype({
            ...addsType,
            [name]: value
        });
        setMainCat({});
        setBannerCatId({});
        setTypes({});
        setModels({});
        setBrands({});
        setGetMainCatName({})
    };

    // console.log(values, "values")
    // render form
    const _renderForm = () => {
        return (
            <>
                <form onSubmit={handleSubmit(handleSubmit_)}>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12" style={{ marginBottom: 20 }}>
                                <h3 className="card-title"><b>{editkey ? 'Edit' : 'Add'} Deals</b></h3>
                            </div>


                            <div className="col-md-12">
                                <div className="form-group">
                                    <label for="exampleInputEmail1">Title</label>
                                    <input
                                        name="title"
                                        className="form-control"
                                        type="text"
                                        placeholder="Eg. ABC"
                                        defaultValue={values.title || ''}
                                        onChange={(e) => handleChangeTitle(e)}
                                    />
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label for="exampleInputEmail1">Location</label>
                                    
                                        <input 
                                        type="text"
                                        name="location"
                                        className="form-control" 
                                        // onChange={(e) => handleChangeTitle(e)}
                                        defaultValue={values.location || ''}
                                        ref={inputRef} />
                             
                                </div>
                            </div>


                            <div className="col-md-12">
                                <div className="form-group">
                                    <label for="exampleInputEmail1">Coupon Code</label>
                                    <input
                                        name="coupon_code"
                                        className="form-control"
                                        type="text"
                                        placeholder="COUPON123"
                                        defaultValue={values.coupon_code || ''}
                                        onChange={(e) => handleChangeTitle(e)}

                                    />
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label for="exampleInputEmail1">End Date</label>
                                    <Field
                                        name="expiry_date"
                                        component={renderField}
                                        type="date"
                                        min={finalEndDate}
                                    />
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label for="exampleInputEmail1">Description</label>
                                    <textarea
                                        name="description"
                                        className="form-control"
                                        type="url"
                                        placeholder="Write..."
                                        defaultValue={values.description || ''}
                                        onChange={(e) => handleChangeTitle(e)}

                                    />
                                </div>
                            </div>

                        </div>
                        <label for="exampleInputEmail1">Image (You can select upto 5 Images same time)</label>
                        <br />



                        <div>
                            {imageError &&
                                <span className="errorMessage">Maximum limit is 5</span>
                            }
                            <input type="file" accept="image/*, video/*" onChange={handleMediaChange} multiple />
                            {
                                images.map((media, index) => {
                                    console.log("check the media", media);

                                    if (!media.hasOwnProperty('isImage')) {
                                        const fileExtension = media.original.split('.').pop().toLowerCase();
                                        media.isImage = ['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension);
                                    }

                                    return (
                                        <div key={index} className="media-container">
                                            {media.isImage ? (
                                                <img src={media.data_url || media.original} alt="" className="media-item" />
                                            ) : (
                                                <video controls src={media.data_url || media.original} className="media-item" />
                                            )}
                                            <div className="media-options">
                                                <div
                                                    onClick={() => {
                                                        handleMediaRemove(index);
                                                        handleRemoveImgClick(media);
                                                    }}
                                                >
                                                    Remove
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }

                        </div>

                        <div className="row">
                            <div className="col-4 loading--submit-button" style={{ marginTop: '20px' }}>
                                <LoadingButton
                                    type="submit"
                                    className="btn btn-primary"
                                    loading={sending}
                                    disabled={submitting}
                                >
                                    {editkey ? 'Update' : 'Submit'}
                                </LoadingButton>
                            </div>
                        </div>
                    </div>
                </form>
            </>
        )
    }

    return (
        <>
            <Helmet title="Ads" />
            {mainLoader &&
                <div className="main-loader">
                    <DotLoader color={color} loading={mainLoader} css={override} size={150} />
                </div>
            }
            {!mainLoader &&
                <>
                    <div className="app-title">
                        <div>
                            <h1><i className="fa fa-th-list"></i> Deals</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="tile">
                                <div className="tile-body">
                                    {_renderForm()}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }

        </>
    )
}

const initialValues = (values) => {
    if (values) {
        return {
            ...values,
            title: values.title ? values.title : "",
            expiry_date: values.expiry_date ? values.expiry_date : "",
            coupon_code: values.coupon_code ? values.coupon_code : "",
            location: values.location ? values.location : "",
            description: values.description ? values.description : "",
        }
    }
}

const validate = (values) => {
    const errors = {}
    //title
    if (!values.title) {
        errors.title = 'Required*'
    }
    if (!values.coupon_code) {
        errors.coupon_code = 'Required*'
    }
    if (!values.expiry_date) {
        errors.expiry_date = 'Required*'
    }
    if (!values.description) {
        errors.description = 'Required*'
    } if (!values.location) {
        errors.location = 'Required*'
    }


    return errors
}

const formName = 'createAdds';
const myFormValueSelector = formValueSelector(formName);



const mapStateToProps = (state) => {
    return {
        initialValues: initialValues(state.persistStore.editCreateAdds),
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
        title: myFormValueSelector(state, 'title'),
        expiry_date: myFormValueSelector(state, 'expiry_date'),
        coupon_code: myFormValueSelector(state, 'coupon_code'),
        description: myFormValueSelector(state, 'description')
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

const CreateAddsForm = reduxForm({
    form: 'createAdds',
    enableReinitialize: true,
    validate
})(CreateDeals);

export default connect(mapStateToProps, mapDispatchToProps)(CreateAddsForm);
