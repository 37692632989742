import React, {useState, useEffect} from "react";
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import AuthService from 'services';
import swal from 'sweetalert';
import { Field, reduxForm } from 'redux-form';
import LoadingButton from 'components/shared/LoadingButton';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { List } from 'react-content-loader';


const Cms = (props) => {
   
    const [sending, setSending] = useState(false);
    const [load, setLoad] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    const [data, setData] = useState({});
    const { handleSubmit, submitting} = props;
    const MODULE_NAME = 'cms';
    //values
    const [ values, setValues ] = useState({
        slug:"",
        description:"",
        description1:"",
        description2:"",
    });
   
    //get content
    let isMounted = true;
    const getContent = async (index) => {
        try{
            let slug  = 'privacy_policy';
            if(index==1){
                slug = 'term_condition';
            }
            if(index==2){
                slug = 'help';
            }
            let query = {
                slug: slug,
                MODULE_NAME
            };
            setLoad(true);
            await props.dispatch(AuthService.getContent(query)).then((res) => {
                if(isMounted){
                    setLoad(false);
                    setData(res.body);
                    props.dispatch(actions.persist_store({ editContent:res.body}));
                }
            });
        }catch(err) {
            console.log(err);
            setLoad(false);
            if(err && err.data && err.data.message){
                swal("Oops!", err.data.message, "error");
            }
        }
    }

    useEffect(() => {
        getContent(tabIndex);  
        return () => {
            isMounted = false;
        };
    }, [tabIndex]);

    //submit content
    const handleSubmit_ = async (values) => {
        try{
            let slug;
            let description;
            if(tabIndex==1){
                slug = 'term_condition';
                description = values.description1;
            }
            if(tabIndex==2){
                slug = 'help';
                description = values.description2;
            }
            if(tabIndex==0){
                slug = 'privacy_policy';
                description = values.description;
            }
            let data = {
                slug : slug,
                description : description
            }
            setSending(true);
            // return
            await props.dispatch(AuthService.updateContent(data)).then((res) => {
                setSending(false);
                swal("Success!", res.message, "success");
                setTabIndex(tabIndex);
            });
        }catch(err) {
            console.log(err);
            setSending(false);
            if(err && err.data && err.data.message){
                swal("Oops!", err.data.message, "error");
            }
        }
    }

    const renderckEditor = ({
        input,
        label,
        placeholder,
        type,
        meta: { touched, error, warning }
    }) => (
        <>
            <CKEditor
                    data={input.value}
                    editor={ ClassicEditor }
                    config={{
                        toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ] 
                    }}
                    onChange={(event, editor) => {
                        return input.onChange(editor.getData())
                    }
                    }
            />
            {touched &&
                (error && <span className="errorMessage">{error}</span>)
            }
        </>
    )
    

    return(
        <>
        <Helmet title="CMS" />
            <div className="app-title">
                <div>
                <h1><i className="fa fa-folder"></i> CMS</h1>
                </div>
            </div>
          
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <Tabs selectedIndex={tabIndex} onSelect={tabIndex  => setTabIndex(tabIndex)}>
                                    <div className="card-header p-2">
                                        <ul className="nav nav-pills">
                                        <TabList>
                                            <Tab onClick={() => { getContent(0)} }>Privacy policy</Tab>
                                            <Tab onClick={() => { getContent(1)} }>Terms & condition</Tab>
                                            <Tab onClick={() => { getContent(2)} }>Help</Tab>
                                        </TabList>
                                        </ul>
                                    </div>
                                    {load &&
                                        <List  
                                            style={{ width: '100%', padding: 10, }} 
                                            speed={2}
                                            height={150}
                                            backgroundColor="#f3f3f3"
                                            foregroundColor="#c1c5c7"
                                            viewBox="30 0 380 30"
                                        />
                                    } 
                                    {!load &&
                                    <form onSubmit={handleSubmit(handleSubmit_)}>
                                        <div className="card-body">
                                            <div className="tab-content">
                                                <TabPanel>
                                                    <Field
                                                        name="description"
                                                        label = "Description"
                                                        component={renderckEditor}
                                                    />
                                                </TabPanel>
                                                <TabPanel>
                                                    <Field
                                                        name="description1"
                                                        label = "Description"
                                                        component={renderckEditor}
                                                    />
                                                </TabPanel>
                                                <TabPanel>
                                                    <Field
                                                        name="description2"
                                                        label = "Description"
                                                        component={renderckEditor}
                                                    />
                                                </TabPanel>
                                            </div>
                                            <div className="row">
                                                <div className="col-4 loading--submit-button" style={{marginTop:'10px'}}>
                                                    <LoadingButton
                                                        type="submit"
                                                        className="btn btn-primary"
                                                        loading={sending}
                                                        disabled={submitting}
                                                    >
                                                        Update
                                                    </LoadingButton>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    }
                                </Tabs>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </section>
        </>
    )
};

const initialValues = (values) => {	
	if(values) {
        let slug = values.slug==="privacy_policy" ? 1 : values.slug==="term_condition" ?  2 : 3;
		return {
			...values,
            description: slug===1 ? values.description : "",
            description1: slug===2 ? values.description : "",
            description2: slug===3 ? values.description : "",
		}
	}
}


const mapStateToProps = (state) => {
    return{
        initialValues: initialValues(state.persistStore.editContent),
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

const CmsForm = reduxForm({
    form: 'cms',
    enableReinitialize: true
	// validate
})(Cms);

export default connect(mapStateToProps, mapDispatchToProps)(CmsForm);
