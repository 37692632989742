import * as Path from './paths';

//-----------------------auth--------------------//
import SignIn from 'components/views/admin/auth/Login';

//------------Dashboard--------------------------//
import Dashboard from 'components/views/admin/dashboard/Dashboard';

//------------Profile--------------------------//
import Profile from 'components/views/admin/profile/Profile';

//------------User--------------------------//
import User from 'components/views/admin/user/User';
import UserView from 'components/views/admin/user/UserView';

//------------Business--------------------------//
import Business from 'components/views/admin/business/Business';
import BusinessView from 'components/views/admin/business/BusinessView';

//------------Food Type--------------------------//
import FoodType from 'components/views/admin/foodtype/FoodType';
import FormFoodType from 'components/views/admin/foodtype/FormFoodType';

//------------Setting--------------------------//
import Setting from 'components/views/admin/setting/Setting';

//------------CMS--------------------------//
import CMS from 'components/views/admin/cms/Cms';

//------------Contact us--------------------------//
import ContactUs from 'components/views/admin/contactus/ContactUs';
import ContactUsView from 'components/views/admin/contactus/ContactUsView';

//------------Brand-------------------------//
import Brand from 'components/views/admin/brand/Brand';
import FormBrand from 'components/views/admin/brand/FormBrand';

//------------Models-------------------------//
import Model from 'components/views/admin/model/Model';

//------------Types-------------------------//
import Type from 'components/views/admin/type/Type';

//------------Home Banner-------------------------//
import HomeBanner from 'components/views/admin/homebanner/HomeBanner';
import FormHomeBanner from 'components/views/admin/homebanner/FormHomeBanner';

//------------Banner-------------------------//
import Banner from 'components/views/admin/homebanner/Banner';
import FormBanner from 'components/views/admin/homebanner/FormBanner';


//------------Ads-------------------------//
import Ads from 'components/views/admin/adds/Adds';
import AddsView from 'components/views/admin/adds/AddsView';
import AdsForm from 'components/views/admin/adds/CreateAdds';

//------------Coupon-------------------------//
import Coupon from 'components/views/admin/coupon/Coupon';
import FormCoupon from 'components/views/admin/coupon/FormCoupon';

//------------Restaurants--------------------------//
import Restaurants from 'components/views/admin/restaurants/Restaurants';
import RestaurantsView from 'components/views/admin/restaurants/RestaurantsView';

//------------Hotels--------------------------//
import Hotels from 'components/views/admin/hotels/Hotels';
import HotelView from 'components/views/admin/hotels/HotelView';

//------------Entertainment--------------------------//
import Entertainment from 'components/views/admin/entertainment/Entertainment';
import EntertainmentView from 'components/views/admin/entertainment/EntertainmentView';

//------------Employment--------------------------//
import Employment from 'components/views/admin/employment/Employment';
import EmploymentView from 'components/views/admin/employment/EmploymentView';
import EmploymentCategory from 'components/views/admin/employment/EmploymentCategory';
import EmploymentSubcategory from 'components/views/admin/employment/EmploymentSubcategory';

//------------Car--------------------------//
import Cars from 'components/views/admin/cars/Cars';
import CarView from 'components/views/admin/cars/CarView';

//------------Real estate--------------------------//
import RealEstate from 'components/views/admin/realestate/RealEstate';
import RealEstateView from 'components/views/admin/realestate/RealEstateView';
import RealEstateType from 'components/views/admin/realestate/RealEstateType';

//------------Travel  Tourism--------------------------//
import TravelTourism from 'components/views/admin/traveltourism/TravelTourism';
import TravelTourismView from 'components/views/admin/traveltourism/TravelTourismView';
import TravelTourismSubcategory from 'components/views/admin/traveltourism/TravelTourismSubcategory';
import FormTravelTourismSubcategory from 'components/views/admin/traveltourism/FormTravelTourismSubcategory';

//------------Things to do --------------------------//
import ThingsToDo from 'components/views/admin/thingstodo/ThingsToDo';
import ThingsToDoView from 'components/views/admin/thingstodo/ThingsToDoView';
import ThingsToDoSubcategory from 'components/views/admin/thingstodo/ThingsToDoSubcategory';
import FormThingsToDoSubCategory from 'components/views/admin/thingstodo/FormThingsToDoSubCategory';
import ThingsToDoCategory from 'components/views/admin/thingstodo/ThingsToDoCategory';
import FormThingsToDoCategory from 'components/views/admin/thingstodo/FormThingsToDoCategory';

//------------Services--------------------------//
import Services from 'components/views/admin/services/Services';
import ServicesView from 'components/views/admin/services/ServicesView';
import ServicesCategory from 'components/views/admin/services/ServicesCategory';
import FormServicesCategory from 'components/views/admin/services/FormServicesCategory';
import ServicesSubcategory from 'components/views/admin/services/ServicesSubcategory';
import FormServicesSubcategory from 'components/views/admin/services/FormServicesSubcategory';
//------------MarketPlace--------------------------//
import MarketPlaceList from 'components/views/admin/marketplace/MarketList';
import MarketPlaceProduct from 'components/views/admin/marketplace/MarketProductView';
import MarketPlace from 'components/views/admin/marketplace/MarketPlace';
import MarketPlaceView from 'components/views/admin/marketplace/MarketView';
import MarketPlaceCategory from 'components/views/admin/marketplace/MarketPlaceCategory';
import MarketPlaceSubcategory from 'components/views/admin/marketplace/MarketPlaceSubcategory';

//------------Categpry Type--------------------------//
import Category from 'components/views/admin/categories/Category';
import FormCategory from 'components/views/admin/categories/FormCategory';

//------------SpottedDeals-------------------------//
import SpottedDeals from 'components/views/admin/spotted_deals/SpottedDeals';
import SpottedDealsView from 'components/views/admin/spotted_deals/SpottedDealsView';

//------------Reported User --------------------------//
import ReportedUser from 'components/views/admin/reported_users/ReportedUser';
import ReportedUserView from 'components/views/admin/reported_users/ReportedUserView';

//------------Blocked User--------------------------//
import BlockedUser from 'components/views/admin/blocked_users/BlockedUser';
import BlockedUserView from 'components/views/admin/blocked_users/BlockedUserView';

//------------Subscription--------------------------//
import Subscription from 'components/views/admin/subscription/Subscription';
import SubscriptionView from 'components/views/admin/subscription/SubscriptionView';
import FormSubscription from 'components/views/admin/subscription/FormSubscription';

//------------Store Subscription--------------------------//
import StoreSubscription from 'components/views/admin/store_subscription/StoreSubscription';
import StoreSubscriptionView from 'components/views/admin/store_subscription/StoreSubscriptionView';
import FormStoreSubscription from 'components/views/admin/store_subscription/FormStoreSubscription';
import SendNotification from 'components/views/admin/notification/SendNotificaiton';

//---------------Page not found--------------//
import NotFound from 'components/NotFound';
import CreateDeals from 'components/views/admin/spotted_deals/CreateDeals';

const routes = [  
	{
		path: Path.login,
		exact: true,
		auth: false,
		fallback: true,
		component: SignIn,
	},
	/* dashboard routes */
	{
		path: Path.dashboard,
		exact: true,
		auth: true,
		component: Dashboard,
	},
	/* profile routes */
	{
		path: Path.profile,
		exact: true,
		auth: true,
		component: Profile,
	},
	/* setting routes */
	{
		path: Path.setting,
		exact: true,
		auth: true,
		component: Setting,
	},
	/* user routes */
	{
		path: Path.user,
		exact: true,
		auth: true,
		component: User,
	},
	{
		path: Path.userview,
		exact: true,
		auth: true,
		component: UserView,
	},
	{
		path: Path.user_view,
		exact: true,
		auth: true,
		component: UserView,
	},
	{
		path: Path.send_notification,
		exact: true,
		auth: true,
		component: SendNotification,
	},
	/* business routes */
	{
		path: Path.business,
		exact: true,
		auth: true,
		component: Business,
	},
	{
		path: Path.businessview,
		exact: true,
		auth: true,
		component: BusinessView,
	},
	{
		path: Path.business_view,
		exact: true,
		auth: true,
		component: BusinessView,
	},
	/* food type */
	{
		path: Path.foodtype,
		exact: true,
		auth: true,
		component: FoodType,
	},
	{
		path: Path.form_foodtype,
		exact: true,
		auth: true,
		component: FormFoodType,
	},
	{
		path: Path.form_food_type,
		exact: true,
		auth: true,
		component: FormFoodType,
	},
	/* cms routes */
	{
		path: Path.cms,
		exact: true,
		auth: true,
		component: CMS,
	},
	/* contactus routes */
	{
		path: Path.contactus,
		exact: true,
		auth: true,
		component: ContactUs,
	},
	{
		path: Path.contactusview,
		exact: true,
		auth: true,
		component: ContactUsView,
	},
	{
		path: Path.contactus_view,
		exact: true,
		auth: true,
		component: ContactUsView,
	},
	/* brand routes */
	{
		path: Path.brand,
		exact: true,
		auth: true,
		component: Brand,
	},
	{
		path: Path.formbrand,
		exact: true,
		auth: true,
		component: FormBrand,
	},
	{
		path: Path.form_brand,
		exact: true,
		auth: true,
		component: FormBrand,
	},
	/* model routes */
	{
		path: Path.model,
		exact: true,
		auth: true,
		component: Model,
	},
	/* type routes */
	{
		path: Path.type,
		exact: true,
		auth: true,
		component: Type,
	},
	/* home banner routes */
	{
		path: Path.banner,
		exact: true,
		auth: true,
		component: HomeBanner,
	},
	{
		path: Path.form_banner,
		exact: true,
		auth: true,
		component: FormHomeBanner,
	},
	{
		path: Path.form_home_banner,
		exact: true,
		auth: true,
		component: FormHomeBanner,
	},
	/* home routes */
	{
		path: Path.banner1,
		exact: true,
		auth: true,
		component: Banner,
	},
	{
		path: Path.form_banner1,
		exact: true,
		auth: true,
		component: FormBanner,
	},
	/* ads routes */
	{
		path: Path.ads,
		exact: true,
		auth: true,
		component: Ads,
	},
	{
		path: Path.adsview,
		exact: true,
		auth: true,
		component: AddsView,
	},
	{
		path: Path.ads_view,
		exact: true,
		auth: true,
		component: AddsView,
	},
	{
		path: Path.ads_form_banner,
		exact: true,
		auth: true,
		component: AdsForm,
	},
	{
		path: Path.ads_form_home_banner,
		exact: true,
		auth: true,
		component: AdsForm,
	},
	/* coupon routes */
	{
		path: Path.coupon,
		exact: true,
		auth: true,
		component: Coupon,
	},
	{
		path: Path.form_coupon,
		exact: true,
		auth: true,
		component: FormCoupon,
	},
	{
		path: Path.formcoupon,
		exact: true,
		auth: true,
		component: FormCoupon,
	},
	/* restaurants routes */
	{
		path: Path.restaurants,
		exact: true,
		auth: true,
		component: Restaurants,
	},
	{
		path: Path.restaurantsview,
		exact: true,
		auth: true,
		component: RestaurantsView,
	},
	{
		path: Path.restaurants_view,
		exact: true,
		auth: true,
		component: RestaurantsView,
	},
	/* hotel routes */
	{
		path: Path.hotels,
		exact: true,
		auth: true,
		component: Hotels,
	},
	{
		path: Path.hotelview,
		exact: true,
		auth: true,
		component: HotelView,
	},
	{
		path: Path.hotel_view,
		exact: true,
		auth: true,
		component: HotelView,
	},
	/* entertainment routes */
	{
		path: Path.entertainment,
		exact: true,
		auth: true,
		component: Entertainment,
	},
	{
		path: Path.entertainmentview,
		exact: true,
		auth: true,
		component: EntertainmentView,
	},
	{
		path: Path.entertainment_view,
		exact: true,
		auth: true,
		component: EntertainmentView,
	},
	/* employment routes */
	{
		path: Path.employment,
		exact: true,
		auth: true,
		component: Employment,
	},
	{
		path: Path.employmentview,
		exact: true,
		auth: true,
		component: EmploymentView,
	},
	{
		path: Path.employment_view,
		exact: true,
		auth: true,
		component: EmploymentView,
	},
	{
		path: Path.employment_subcategory,
		exact: true,
		auth: true,
		component: EmploymentSubcategory,
	},
	{
		path: Path.employment_category,
		exact: true,
		auth: true,
		component: EmploymentCategory,
	},
	/* car routes */
	{
		path: Path.cars,
		exact: true,
		auth: true,
		component: Cars,
	},
	{
		path: Path.carview,
		exact: true,
		auth: true,
		component: CarView,
	},
	{
		path: Path.car_view,
		exact: true,
		auth: true,
		component: CarView,
	},
	/* real estate routes */
	{
		path: Path.realestate,
		exact: true,
		auth: true,
		component: RealEstate,
	},
	{
		path: Path.realestateview,
		exact: true,
		auth: true,
		component: RealEstateView,
	},
	{
		path: Path.realestate_view,
		exact: true,
		auth: true,
		component: RealEstateView,
	},
	{
		path: Path.realestate_type,
		exact: true,
		auth: true,
		component: RealEstateType,
	},
	/* travel tourism routes */
	{
		path: Path.traveltourism,
		exact: true,
		auth: true,
		component: TravelTourism,
	},
	{
		path: Path.traveltourismview,
		exact: true,
		auth: true,
		component: TravelTourismView,
	},
	{
		path: Path.traveltourism_view,
		exact: true,
		auth: true,
		component: TravelTourismView,
	},
	{
		path: Path.traveltourism_subcategory,
		exact: true,
		auth: true,
		component: TravelTourismSubcategory,
	},
	{
		path: Path.form_travel_tourism_subcategory,
		exact: true,
		auth: true,
		component: FormTravelTourismSubcategory,
	},
	{
		path: Path.form_traveltourism_subcategory,
		exact: true,
		auth: true,
		component: FormTravelTourismSubcategory,
	},
	/* thingstodo routes */
	{
		path: Path.thingstodo,
		exact: true,
		auth: true,
		component: ThingsToDo,
	},
	{
		path: Path.thingstodoview,
		exact: true,
		auth: true,
		component: ThingsToDoView,
	},
	{
		path: Path.thingstodo_view,
		exact: true,
		auth: true,
		component: ThingsToDoView,
	},
	{
		path: Path.thingstodo_subcategory,
		exact: true,
		auth: true,
		component: ThingsToDoSubcategory,
	},
	{
		path: Path.form_thingstodo_subcategory,
		exact: true,
		auth: true,
		component: FormThingsToDoSubCategory,
	},
	{
		path: Path.form_things_todo_subcategory,
		exact: true,
		auth: true,
		component: FormThingsToDoSubCategory,
	},
	{
		path: Path.form_things_todo_category,
		exact: true,
		auth: true,
		component: FormThingsToDoCategory,
	},
	{
		path: Path.form_thingstodo_category,
		exact: true,
		auth: true,
		component: FormThingsToDoCategory,
	},
	{
		path: Path.thingstodo_category,
		exact: true,
		auth: true,
		component: ThingsToDoCategory,
	},
	/* services routes */
	{
		path: Path.services,
		exact: true,
		auth: true,
		component: Services,
	},
	{
		path: Path.servicesview,
		exact: true,
		auth: true,
		component: ServicesView,
	},
	{
		path: Path.services_view,
		exact: true,
		auth: true,
		component: ServicesView,
	},
	{
		path: Path.services_subcategory,
		exact: true,
		auth: true,
		component: ServicesSubcategory,
	},
	{
		path: Path.form_services_subcategory,
		exact: true,
		auth: true,
		component: FormServicesSubcategory,
	},
	{
		path: Path.formservices_subcategory,
		exact: true,
		auth: true,
		component: FormServicesSubcategory,
	},
	{
		path: Path.services_category,
		exact: true,
		auth: true,
		component: ServicesCategory,
	},
	{
		path: Path.form_services_category,
		exact: true,
		auth: true,
		component: FormServicesCategory,
	},
	{
		path: Path.formservices_category,
		exact: true,
		auth: true,
		component: FormServicesCategory,
	},

	/* market place routes */
	{
		path: Path.marketplace,
		exact: true,
		auth: true,
		component: MarketPlace,
	},
	{
		path: Path.marketplacelist,
		exact: true,
		auth: true,
		component: MarketPlaceList,
	},
	{
		path: Path.marketplaceview,
		exact: true,
		auth: true,
		component: MarketPlaceView,
	},
	{
		path: Path.marketplace_view,
		exact: true,
		auth: true,
		component: MarketPlaceView,
	},
	{
		path: Path.marketplace_product,
		exact: true,
		auth: true,
		component: MarketPlaceProduct,
	},
	{
		path: Path.marketplaceproduct,
		exact: true,
		auth: true,
		component: MarketPlaceProduct,
	},
	{
		path: Path.marketplace_subcategory,
		exact: true,
		auth: true,
		component: MarketPlaceSubcategory,
	},
	{
		path: Path.marketplace_category,
		exact: true,
		auth: true,
		component: MarketPlaceCategory,
	},
	/* category type */
	{
		path: Path.name_category,
		exact: true,
		auth: true,
		component: Category,
	},
	{
		path: Path.name_categoryform,
		exact: true,
		auth: true,
		component: FormCategory,
	},
	{
		path: Path.name_category_form,
		exact: true,
		auth: true,
		component: FormCategory,
	},
	/* spotted deals routes */
	{
		path: Path.spotted_form_deals,
		exact: true,
		auth: true,
		component:CreateDeals,
	},
	{
		path: Path.spotted_deals,
		exact: true,
		auth: true,
		component: SpottedDeals,
	},
	{
		path: Path.view_spotted_deals,
		exact: true,
		auth: true,
		component: SpottedDealsView,
	},
	{
		path: Path.viewspotted_deals,
		exact: true,
		auth: true,
		component: SpottedDealsView,
	},
	/* reported users routes */
	{
		path: Path.reported_user,
		exact: true,
		auth: true,
		component: ReportedUser,
	},
	{
		path: Path.reporteduser_view,
		exact: true,
		auth: true,
		component: ReportedUserView,
	},
	{
		path: Path.reporteduserview,
		exact: true,
		auth: true,
		component: ReportedUserView,
	},
	/* blocked user routes */
	{
		path: Path.blocked_user,
		exact: true,
		auth: true,
		component: BlockedUser,
	},
	{
		path: Path.blocked_userview,
		exact: true,
		auth: true,
		component: BlockedUserView,
	},
	{
		path: Path.blockeduserview,
		exact: true,
		auth: true,
		component: BlockedUserView,
	},
	/* subscription routes */
	{
		path: Path.subscription,
		exact: true,
		auth: true,
		component: Subscription,
	},
	{
		path: Path.subscription_view,
		exact: true,
		auth: true,
		component: SubscriptionView,
	},
	{
		path: Path.subscriptionview,
		exact: true,
		auth: true,
		component: SubscriptionView,
	},
	{
		path: Path.form_subscription,
		exact: true,
		auth: true,
		component: FormSubscription,
	},
	{
		path: Path.formsubscription,
		exact: true,
		auth: true,
		component: FormSubscription,
	},
	/* store subscription routes */
	{
		path: Path.store_subscription,
		exact: true,
		auth: true,
		component: StoreSubscription,
	},
	{
		path: Path.store_subscription_view,
		exact: true,
		auth: true,
		component: StoreSubscriptionView,
	},
	{
		path: Path.store_subscriptionview,
		exact: true,
		auth: true,
		component: StoreSubscriptionView,
	},
	{
		path: Path.store_formsubscription,
		exact: true,
		auth: true,
		component: FormStoreSubscription,
	},
	{
		path: Path.store_form_subscription,
		exact: true,
		auth: true,
		component: FormStoreSubscription,
	},
	{
		path: '/',
		exact: false,
		component: NotFound,
	},

];

export default routes;
