import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Path from 'routes/paths';
import Helmet from 'react-helmet';
import { List } from 'react-content-loader';
import AuthService from 'services';
import swal from 'sweetalert';
//countUp
import CountUp from 'react-countup';

const Dashboard = (props) => {

    const [sending, setSending] = useState(false);
    const [data, setData] = useState({});

    //get data
    let isMounted = true;
    async function getData() {
        try {
            setSending(true);
            await props.dispatch(AuthService.dashboardCount()).then((res) => {
                if (isMounted) {
                    setSending(false);
                    setData(res.body);
                }
            });
        } catch (err) {
            setSending(false);
            console.log(err);
            if (err && err.data && err.data.message) {
                swal("Oops!", err.data.message, "error");
            }
        }
    };

    useEffect(() => {
        getData();
        return () => {
            isMounted = false;
        };
    }, []);


    return (
        <>
            <Helmet title="Dashboard" />
            <div className="app-title flex-row  ">
                <div>
                    <h1><i className="fa fa-dashboard"></i> Dashboard</h1>
                </div>
                <ul className="app-breadcrumb breadcrumb">
                    <li className="breadcrumb-item"><i className="fa fa-home fa-lg"></i></li>

                </ul>
            </div>
            {sending &&
                <List
                    style={{ width: '100%', padding: 10, }}
                    speed={2}
                    height={150}
                    backgroundColor="#f3f3f3"
                    foregroundColor="#c1c5c7"
                    viewBox="0 0 380 50"
                />
            }

            {!sending && data &&
                <div className="row">
                    <div className="col-md-6 col-lg-3">
                        <Link to={Path.name_category}>
                            <div className="widget-small primary coloured-icon"><i className="icon fa fa-folder fa-3x"></i>
                                <div className="info">
                                    <h4>Main Categories</h4>
                                    <p><b><CountUp start={0} end={data.nameCategory ? data.nameCategory : 0} duration={3} /></b></p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <Link to={Path.user}>
                            <div className="widget-small primary coloured-icon"><i className="icon fa fa-users fa-3x"></i>
                                <div className="info">
                                    <h4>Users</h4>
                                    <p><b><CountUp start={0} end={data.user_count ? data.user_count : 0} duration={3} /></b></p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <Link to={Path.business}>
                            <div className="widget-small primary coloured-icon"><i className="icon fa fa-industry fa-3x"></i>
                                <div className="info">
                                    <h4>Business</h4>
                                    <p><b><CountUp start={0} end={data.business_count ? data.business_count : 0} duration={3} /></b></p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <Link to={Path.banner}>
                            <div className="widget-small primary coloured-icon"><i className="icon fa fa-picture-o fa-3x"></i>
                                <div className="info">
                                    <h4>Banner</h4>
                                    <p><b><CountUp start={0} end={data.banner ? data.banner : 0} duration={3} /></b></p>
                                </div>
                            </div>
                        </Link>
                    </div>

                    <div className="col-md-6 col-lg-3">
                        <Link to={Path.ads}>
                            <div className="widget-small primary coloured-icon"><i className="icon fa fa-file fa-3x"></i>
                                <div className="info">
                                    <h4>Ads</h4>
                                    <p><b><CountUp start={0} end={data.ads ? data.ads : 0} duration={3} /></b></p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <Link to={Path.restaurants}>
                            <div className="widget-small primary coloured-icon"><i className="icon fa fa-file fa-3x"></i>
                                <div className="info">
                                    <h4>Restaurants</h4>
                                    <p><b><CountUp start={0} end={data.restaurants ? data.restaurants : 0} duration={3} /></b></p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <Link to={Path.hotels}>
                            <div className="widget-small primary coloured-icon"><i className="icon fa fa-building fa-3x"></i>
                                <div className="info">
                                    <h4>Hotels</h4>
                                    <p><b><CountUp start={0} end={data.hotel ? data.hotel : 0} duration={3} /></b></p>
                                </div>
                            </div>
                        </Link>
                    </div>

                    <div className="col-md-6 col-lg-3">
                        <Link to={Path.entertainment}>
                            <div className="widget-small primary coloured-icon"><i className="icon fa fa-file fa-3x"></i>
                                <div className="info">
                                    <h4>Things to do</h4>
                                    <p><b><CountUp start={0} end={data.entertainments ? data.entertainments : 0} duration={3} /></b></p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <Link to={Path.cars}>
                            <div className="widget-small primary coloured-icon"><i className="icon fa fa-car fa-3x"></i>
                                <div className="info">
                                    <h4>Cars</h4>
                                    <p><b><CountUp start={0} end={data.cars ? data.cars : 0} duration={3} /></b></p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <Link to={Path.employment}>
                            <div className="widget-small primary coloured-icon"><i className="icon fa fa-file fa-3x"></i>
                                <div className="info">
                                    <h4>Employment</h4>
                                    <p><b><CountUp start={0} end={data.employment ? data.employment : 0} duration={3} /></b></p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <Link to={Path.realestate}>
                            <div className="widget-small primary coloured-icon"><i className="icon fa fa-folder fa-3x"></i>
                                <div className="info">
                                    <h4>Real Estate</h4>
                                    <p><b><CountUp start={0} end={data.real_estate ? data.real_estate : 0} duration={3} /></b></p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <Link to={Path.thingstodo}>
                            <div className="widget-small primary coloured-icon"><i className="icon fa fa-folder fa-3x"></i>
                                <div className="info">
                                    <h4>Entertainment</h4>
                                    <p><b><CountUp start={0} end={data.things_to_do ? data.things_to_do : 0} duration={3} /></b></p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <Link to={Path.traveltourism}>
                            <div className="widget-small primary coloured-icon"><i className="icon fa fa-folder fa-3x"></i>
                                <div className="info">
                                    <h4>Travel & Tourism</h4>
                                    <p><b><CountUp start={0} end={data.travel_tourism ? data.travel_tourism : 0} duration={3} /></b></p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <Link to={Path.services}>
                            <div className="widget-small primary coloured-icon"><i className="icon fa fa-folder fa-3x"></i>
                                <div className="info">
                                    <h4>Services</h4>
                                    <p><b><CountUp start={0} end={data.services ? data.services : 0} duration={3} /></b></p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <Link to={Path.marketplace}>
                            <div className="widget-small primary coloured-icon"><i className="icon fa fa-folder fa-3x"></i>
                                <div className="info">
                                    <h4>MarketPlace</h4>
                                    {/* <p><b><CountUp start={0} end={data.coupons ? data.coupons : 0} duration={3}/></b></p> */}
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <Link to={Path.coupon}>
                            <div className="widget-small primary coloured-icon"><i className="icon fa fa-folder fa-3x"></i>
                                <div className="info">
                                    <h4>Shoppers</h4>
                                    <p><b><CountUp start={0} end={data.coupons ? data.coupons : 0} duration={3} /></b></p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <Link to={Path.spotted_deals}>
                            <div className="widget-small primary coloured-icon"><i className="icon fa fa-folder fa-3x"></i>
                                <div className="info">
                                    <h4>Spotted Deals</h4>
                                    <p><b><CountUp start={0} end={data.spotted_deals ? data.spotted_deals : 0} duration={3} /></b></p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            }
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Dashboard);