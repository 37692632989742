import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as Path from "routes/paths";
import AuthService from "services";
import swal from "sweetalert";
import * as actions from "store/actions";
import Toggle from "react-toggle";
import ReactToggle from "./ReactToggle.css";
import Pagination from "react-js-pagination";
import { List } from "react-content-loader";
import env from "env.json";
import SelectInput from "components/shared/SelectInput";
import TextInput from "components/shared/TextInput";
import LoadingButton from "components/shared/LoadingButton";

const Adds = (props) => {
  //pagination
  const [activePage, setActivePage] = useState(1);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [itemsCountPerPage, setItemsCountPerPage] = useState(0);
  const [pageRange, setPageRange] = useState(5);

  const [sending, setSending] = useState(false);
  const [load, setLoad] = useState(false);
  const [data, setData] = useState([]);
  console.log("table data ===========>", data);
  const MODULE_NAME = "adds";

  //search record
  const [sortby, setSortby] = useState(1);
  const [clearSearch, setClearSearch] = useState(false);
  const [values, setValues] = useState({
    search: "",
  });

  const [deleteRecord, setDeleteRecord] = useState([]);
  const [checkUncheck, setCheckUncheck] = useState(false);

  const [checkUncheckStatus, setCheckUncheckStatus] = useState(false);
  const [checkUncheckStatus1, setCheckUncheckStatus1] = useState(false);

  //handle change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  //get type
  let isMounted = true;
  async function getData() {
    try {
      let search = clearSearch == false ? values.search : "";
      let query = {
        page: activePage,
        MODULE_NAME,
        sortby,
        search: search,
      };
      setLoad(true);
      await props.dispatch(AuthService.getList(query)).then((res) => {
        if (isMounted) {
          setLoad(false);
          setClearSearch(false);
          setData(res.body.data);
          setItemsCountPerPage(res.body.per_page);
          setTotalItemsCount(res.body.total_count);
        }
      });
    } catch (err) {
      setLoad(false);
      console.log(err);
      if (err && err.data && err.data.message) {
        swal("Oops!", err.data.message, "error");
      }
    }
  }

  useEffect(() => {
    getData();
    return () => {
      isMounted = false;
    };
  }, [activePage, sortby, clearSearch]);

  const clearFilter = () => {
    setClearSearch(!clearSearch);
    setValues({
      search: "",
    });
  };

  //update banner status
  const updateStatus = async (id, type, status_type) => {
    try {
      if (type == "all" || status_type == true) {
        setCheckUncheckStatus(status_type);
      } else if (type == "all" || status_type == false) {
        setCheckUncheckStatus1(status_type);
      }
      let ids = [];
      data.map((item) => {
        return ids.push(item._id);
      });
      let query = {
        id: type == "all" ? ids : [id],
        MODULE_NAME,
        status: status_type,
        type: "ADDS",
      };
      await props.dispatch(AuthService.updateStatus(query)).then((res) => {
        swal("Success!", res.message, "success");
        if (status_type == false) {
          setCheckUncheckStatus1(true);
        }
        if (status_type == true) {
          setCheckUncheckStatus(false);
          setCheckUncheckStatus1(false);
        }
        getData();
      });
    } catch (err) {
      console.log(err);
      setSending(false);
      if (err && err.data && err.data.message) {
        swal("Oops!", err.data.message, "error");
      }
    }
  };

  const handlePageChange = (page) => {
    setActivePage(page);
    window.scrollTo(0, 0);
  };

  const handleSubmit_ = async (e) => {
    try {
      e.preventDefault();
      let query = {
        page: activePage,
        MODULE_NAME,
        sortby,
        search: values.search,
      };
      setLoad(true);
      setSending(true);
      await props.dispatch(AuthService.getList(query)).then((res) => {
        setLoad(false);
        setSending(false);
        setData(res.body.data);
      });
    } catch (err) {
      console.log(err);
      setSending(false);
      if (err && err.data && err.data.message) {
        swal("Oops!", err.data.message, "error");
      }
    }
  };

  const popupDelete = (id, type) => {
    try {
      if (type == "delete_all") {
        if (id.length == 0) {
          swal({
            text: "please select atleast one id",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          });
        } else {
          swal({
            title: "Are you sure?",
            text: "Are you sure to Delete?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              try {
                let query = {
                  id: id,
                  MODULE_NAME,
                  type: "ADDS",
                };
                props.dispatch(AuthService.deleteData(query)).then((res) => {
                  swal("Deleted!", res.message, "success");
                  getData();
                });
              } catch (err) {
                console.log(err);
                setSending(false);
                if (err && err.data && err.data.message) {
                  swal("Oops!", err.data.message, "error");
                }
              }
            }
          });
        }
      }
    } catch (err) {
      console.log(err);
      swal("Oops!", "Something went wrong!", "error");
    }
  };

  let arrIds = [];
  const mark_unmark_ids = (e, id, type) => {
    const { checked } = e.target;
    if (type == "single") {
      popupDelete(id, "delete_all");
    } else if (type == "multiple") {
      if (checked == true) {
        setDeleteRecord([...deleteRecord, id]);
        setCheckUncheck(!checkUncheck);
      } else {
        let index = deleteRecord.indexOf(id);
        if (index > -1) {
          deleteRecord.splice(index, 1);
          setDeleteRecord(deleteRecord);
          setCheckUncheck(!checkUncheck);
        }
      }
    } else {
      if (checked == true) {
        data.map((item) => {
          return arrIds.push(item._id);
        });
        setCheckUncheck(!checkUncheck);
        setDeleteRecord(arrIds);
      } else {
        arrIds = [];
        setDeleteRecord([]);
        setCheckUncheck(!checkUncheck);
      }
    }
    return arrIds;
  };

  const delete_all = () => {
    popupDelete(deleteRecord, "delete_all");
  };

  function checkFileType(item) {
    // Get the file extension by splitting the string at the dot (.)
    const fileExtension = item?.split(".").pop().toLowerCase();

    // Define an array of common image file extensions
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"];

    // Check if the file extension is in the list of image extensions
    if (imageExtensions.includes(fileExtension)) {
      return "image";
    } else {
      return "video";
    }
  }

  return (
    <>
      <Helmet title="Ads" />
      <div className="app-title">
        <div>
          <h1>
            <i className="fa fa-th-list"></i> Ads
          </h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="tile">
            <div className="row">
              <div className="col-md-5">
                <h3 className="tile-title">Ads Listing</h3>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <SelectInput
                    name="sort_by"
                    className="custom-select"
                    onChange={(value) => setSortby(value.target.value)}
                  >
                    <option value="" disabled>
                      Sort by{" "}
                    </option>
                    <option value="0">Asc</option>
                    <option value="1">Desc</option>
                  </SelectInput>
                </div>
              </div>
              <div className="col-md-4">
                <form onSubmit={handleSubmit_}>
                  <div className="input-group">
                    <TextInput
                      name="search"
                      className="form-control"
                      placeholder="Search by title..."
                      value={values.search}
                      onChange={handleChange}
                    ></TextInput>
                    <div className="input-group-append">
                      <LoadingButton
                        type="submit"
                        className="btn btn-primary"
                        loading={sending}
                      >
                        <i className="fa fa-search"></i>
                      </LoadingButton>
                    </div>
                    <div
                      className="input-group-append"
                      style={{ marginLeft: "3px" }}
                    >
                      <LoadingButton
                        type="button"
                        className="btn btn-danger"
                        onClick={clearFilter}
                      >
                        <i className="fa fa-times"></i>
                      </LoadingButton>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-md-1">
                <div className="form-group">
                  <Link
                    to={`${Path.ads_form_banner}`}
                    className="btn btn-primary btn-sm banner-btn"
                    title="Add"
                  >
                    <i className="fa fa-lg fa-plus"></i>Add
                  </Link>
                </div>
              </div>
            </div>
            <div className="tile">
              <div className="row">
                <div className="col-md-3 col-6">
                  <p>
                    <b>Select all</b>
                  </p>
                  <input
                    type="checkbox"
                    name="selection"
                    onClick={(e) => mark_unmark_ids(e, "", "all")}
                  />
                </div>
                <div className="col-md-3 col-6">
                  <p>
                    <b>Active all </b>
                  </p>
                  <Toggle
                    id="cheese-status"
                    checked={checkUncheckStatus == true ? "checked" : ""}
                    onClick={() => updateStatus("", "all", true)}
                  />
                </div>
                <div className="col-md-3 col-6">
                  <p>
                    <b>Inactive all </b>
                  </p>
                  <Toggle
                    id="cheese-status1"
                    checked={checkUncheckStatus1 == false ? "checked" : ""}
                    onClick={() => updateStatus("", "all", false)}
                  />
                </div>
                <div className="col-md-3 col-6">
                  <p>
                    <b>Delete all</b>
                  </p>
                  <span
                    className="btn btn-danger btn-sm"
                    title="Delete"
                    onClick={() => {
                      delete_all();
                    }}
                  >
                    <i className="fa fa-trash"></i>
                  </span>
                </div>
              </div>
            </div>

            {load && (
              <List
                style={{ width: "100%", padding: 10 }}
                speed={2}
                height={150}
                backgroundColor="#f3f3f3"
                foregroundColor="#c1c5c7"
                viewBox="30 0 380 30"
              />
            )}

            {!load && data.length > 0 && (
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Select all</th>
                      <th>Business Owner Name</th>
                      <th>Category Name</th>
                      <th>Title</th>
                      <th>Image/video</th>
                      <th>Subscription Status</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 &&
                      data.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              <input
                                type="checkbox"
                                name="selection"
                                onClick={(e) =>
                                  mark_unmark_ids(e, item._id, "multiple")
                                }
                                checked={
                                  deleteRecord.includes(item._id)
                                    ? "checked"
                                    : ""
                                }
                              />
                            </td>
                            <td>
                              {item.user_id.first_name}{" "}
                              {item.user_id.last_name
                                ? item.user_id.last_name.substring(0, 10) +
                                "..."
                                : ""}
                            </td>
                            <td>
                              {item.main_category_id &&
                                item.main_category_id.name
                                ? (
                                  item.main_category_id.name === "Things to do" ? "Entertainment" : (item.main_category_id.name === "Entertainment" ? "Things to do" : item.main_category_id.name)
                                )
                                : "Home"}
                            </td>
                            <td>{item.title.substring(0, 10)}...</td>
                            <td>
                              <a
                                href={
                                  item?.image?.length > 0 &&
                                    item?.image[0]?.original
                                    ? env.SERVER_URL + item?.image[0]?.original
                                    : "#"
                                }
                                target="_blank"
                              >
                                {checkFileType(
                                  item?.image[0]?.original.split(".")[1]
                                ) === "image" ? (
                                  <img
                                    alt="Avatar"
                                    className="table-avatar profile-image-size"
                                    src={
                                      item?.image[0] && item?.image[0]?.original
                                        ? env.SERVER_URL +
                                        item?.image[0]?.original
                                        : "/assets/images/dummy.png"
                                    }
                                  />
                                ) : (
                                  <video
                                    controls
                                    src={
                                      item?.image[0] && item?.image[0]?.original
                                        ? env.SERVER_URL +
                                        item?.image[0]?.original
                                        : "/assets/images/dummy.png"
                                    }
                                    className="table-avatar profile-image-size"
                                  />
                                )}
                              </a>
                            </td>
                            <td>
                              {item.user_id.is_subscribed == 1
                                ? "Active"
                                : "Inactive"}
                            </td>
                            <td>
                              <Toggle
                                id="cheese-status"
                                checked={item.status == 1}
                                onClick={() =>
                                  updateStatus(
                                    item._id,
                                    "single",
                                    item.status == 1 ? 0 : 1
                                  )
                                }
                              />
                            </td>
                            <td>
                              <Link
                                to={`${Path.adsview}/${item._id}`}
                                className="btn btn-primary btn-sm"
                                title="View"
                              >
                                <i className="fa fa-eye"></i>
                              </Link>
                              <Link
                                to={`${Path.ads_form_banner}/${item._id}`}
                                style={{ marginLeft: "10px" }}
                                className="btn btn-warning btn-sm"
                                title="Edit"
                              >
                                <i className="fa fa-lg fa-edit"></i>
                              </Link>
                              <span
                                style={{ marginLeft: "24px" }}
                                className="btn btn-danger btn-sm"
                                title="Delete"
                                onClick={(e) => {
                                  mark_unmark_ids(e, item._id, "single");
                                }}
                              >
                                <i className="fa fa-trash"></i>
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                  <div className="pagination-box-review">
                    {totalItemsCount > itemsCountPerPage && (
                      <Pagination
                        activePage={activePage}
                        itemsCountPerPage={itemsCountPerPage}
                        totalItemsCount={totalItemsCount}
                        pageRangeDisplayed={pageRange}
                        onChange={(page) => handlePageChange(page)}
                        itemClass="page-item"
                        linkClass="page-link"
                      />
                    )}
                  </div>
                </table>
              </div>
            )}
            {!load && data.length == 0 && (
              <div className="row">
                <div className="col-md-6 not-found-detail">
                  <h6>Data not found....</h6>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.Auth.isAuthenticated,
    user: state.Auth,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(Adds);
