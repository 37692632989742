//auth
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_CHECK = 'AUTH_CHECK';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const PROFILE = 'PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_FORM = 'RESET_FORM';
export const FILE_UPLOAD = 'FILE_UPLOAD';
export const GET_LIST = 'GET_LIST';
export const DELETE_DATA = 'DELETE_DATA';
export const UPDATE_STATUS = 'UPDATE_STATUS';
export const GET_DETAIL = 'GET_DETAIL';
export const CREATE_DATA = 'CREATE_DATA';
export const UPDATE_DATA = 'UPDATE_DATA';
export const DASHBOARD_COUNT = 'DASHBOARD_COUNT';
export const UPDATE_CONTENT = 'UPDATE_CONTENT';
export const GET_CONTENT = 'GET_CONTENT';
export const GET_CATEGORY = 'GET_CATEGORY';
export const UPDATE_PLAN_STATUS = 'UPDATE_PLAN_STATUS';
export const GET_USERS_BUSINESS = 'GET_USERS_BUSINESS';
export const GET_HOME_BANNER_CAT = 'GET_HOME_BANNER_CAT';
export const GET_CATEGORIES_HOME_BANNER = 'GET_CATEGORIES_HOME_BANNER';
export const GET_HOME_BANNER_SUBCAT = 'GET_HOME_BANNER_SUBCAT';
export const DELETE_IMG_DATA = 'DELETE_IMG_DATA';

export const PERSIST_STORE = 'persist/REHYDRATE';
