import React, {useState, useEffect} from "react";
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import * as Path from 'routes/paths';
import AuthService from 'services';
import swal from 'sweetalert';
import env from 'env.json';
import { Link } from 'react-router-dom';
import { List }  from 'react-content-loader';

const ContactUsView = (props) => {
    
    const [sending, setSending] = useState(false);
    const [data, setData] = useState({});
    const MODULE_NAME = 'contactus';

    //get data
    let isMounted = true;
    async function getData() {
        try{
            let query = {
                id: props.match.params.id,
                MODULE_NAME
            };
            setSending(true);
            await props.dispatch(AuthService.getDetail(query)).then((res) => {
                if(isMounted){
                    setSending(false);
                    setData(res.body);
                }
            });
        }catch(err) {
            setSending(false)
            console.log(err);
            if(err && err.data && err.data.message){
                swal("Oops!", err.data.message, "error");
            }
        }
    };
  
    useEffect(() => {
        getData();
        return () => {
            isMounted = false;
        };
    }, []);

    return(
        <>
        <Helmet title="Contact Us" />
            <div className="app-title">
                <div>
                <h1><i className="fa fa-address-book"></i> Contact Detail</h1>
                </div>
            </div>

            {sending && 
                <List  
                    style={{ width: '100%', padding: 10, }} 
                    speed={2}
                    height={150}
                    backgroundColor="#f3f3f3"
                    foregroundColor="#c1c5c7"
                    viewBox="30 0 380 30"
                />
            }
            {(!sending && data)  &&
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                <ul className="list-group list-group-unbordered mb-3">
                                    <li className="list-group-item">
                                        <b>Name</b> <span className="float-right">{data.name}</span>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Email</b> <span className="float-right">{data.email}</span>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Contact</b> <span className="float-right">{data.phone}</span>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Company Name</b> <span className="float-right">{data.company_name}</span>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Category</b> <span className="float-right">{data.category_name}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="card card-primary card-outline">
                                    <div className="card-body box-profile">
                                            <div class="timeline-post">
                                                <div class="post-media">
                                                    <div class="content">
                                                        <h5>Description</h5>
                                                    </div>
                                                </div>
                                                <div class="post-content">
                                                <p>{data.description}</p>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2" style={{marginTop:"10px"}}>        
                                <Link to={Path.contactus} class="btn btn-primary btn-block"><b>Back</b></Link>
                            </div>
                        </div>
                    </div>
                </section>   
            }
        </>
    )
};

const mapStateToProps = (state) => {
    return{
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ContactUsView);

