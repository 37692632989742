import React, {useState, useEffect} from "react";
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Path from 'routes/paths';
import AuthService from 'services';
import swal from 'sweetalert';
import * as actions from 'store/actions';
import Toggle from 'react-toggle';
import ReactToggle from './ReactToggle.css';
import Pagination from "react-js-pagination";
import { List } from 'react-content-loader';
import env from 'env.json';
import SelectInput from 'components/shared/SelectInput';
import TextInput from 'components/shared/TextInput';
import LoadingButton from 'components/shared/LoadingButton';

const Banner = (props) => {
 
    //pagination
    const [ activePage, setActivePage ] = useState(1);
    const [totalItemsCount, setTotalItemsCount] = useState(0);
    const [itemsCountPerPage, setItemsCountPerPage] = useState(0);
    const [pageRange, setPageRange] = useState(5);

    const [sending, setSending] = useState(false);
    const [load, setLoad] = useState(false);
    const [data, setData] = useState([]);
    const MODULE_NAME = 'home_banner';

    //get type
    let isMounted = true;
    async function getData() {
        try{
            let query = {
                page: activePage,
                MODULE_NAME,
            };
            setLoad(true);
            await props.dispatch(AuthService.getList(query)).then((res) => {
                if(isMounted){
                setLoad(false);
                setData(res.body.data);
                setItemsCountPerPage(res.body.per_page);
                setTotalItemsCount(res.body.total_count);
                }  
            });
        }catch(err) {
            setLoad(false);
            console.log(err);
            if(err && err.data && err.data.message){
                swal("Oops!", err.data.message, "error");
            }
        }
    };

    useEffect(() => {
        getData();
        return () => {
            isMounted = false;
        };
    }, [activePage]);

    

    //update banner status
    const updateStatus = async (id) => {
        try{
            let query = {
                id: id,
                MODULE_NAME
            };
            await props.dispatch(AuthService.updateStatus(query)).then((res) => {
                swal("Success!", res.message, "success");
                getData();
            });
        }catch(err) {
            console.log(err);
            setSending(false);
            if(err && err.data && err.data.message){
                swal("Oops!", err.data.message, "error");
            }
        }
    }

      //delete users
	const popupDelete = id =>{
		try {
			swal({
				title: "Are you sure?",
				text: "Are you sure to Delete?",
				icon: "warning",
				buttons: true,
				dangerMode: true,
			})
			.then(willDelete => {
				if (willDelete) {
                    try{
                        let query = {
                            id: id,
                            MODULE_NAME
                        };
                        props.dispatch(AuthService.deleteData(query)).then((res) => {
                            swal("Deleted!", res.message, "success");
                            getData();
                        });
                    }catch(err) {
                        console.log(err);
                        setSending(false);
                        if(err && err.data && err.data.message){
                            swal("Oops!", err.data.message, "error");
                        }
                    }
                }
			});
		}
		catch(err) {
            console.log(err);
			swal("Oops!", "Something went wrong!", "error");
	    }
	}

    const handlePageChange = (page) => {
        setActivePage(page);
       window.scrollTo(0,0);
    }

    const handleSubmit_ = async (e) => {
        try{
            e.preventDefault();
            let query = {
                page: activePage,
                MODULE_NAME,
            };
            setLoad(true);
            setSending(true);
            await props.dispatch(AuthService.getList(query)).then((res) => {
                setLoad(false);
                setSending(false);
                setData(res.body.data);
            });
        }catch(err) {
            console.log(err);
            setSending(false);
            if(err && err.data && err.data.message){
                swal("Oops!", err.data.message, "error");
            }
        }
    }

    return(
        <>
            <Helmet title="Home Banner" />
            <div className="app-title">
                <div>
                <h1><i className="fa fa-th-list"></i> Home Banner</h1>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="tile">
                        <div className="row">
                            <div className="col-md-10">
                                <h3 className="tile-title">Home Banner Listing</h3>
                            </div>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <Link to={Path.form_banner1} className="btn btn-primary btn-sm" title="Add">
                                        <i className="fa fa-lg fa-plus"></i>Add
                                    </Link>
                                </div>
                            </div>
                        </div>
                        {load &&
                            <List  
                                style={{ width: '100%', padding: 10, }} 
                                speed={2}
                                height={150}
                                backgroundColor="#f3f3f3"
                                foregroundColor="#c1c5c7"
                                viewBox="30 0 380 30"
                            />
                        } 
                         
                        {!load && data.length>0 &&
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th>Image</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {data.length>0  && data.map((item, i) => {
                                    return(
                                        <tr key={i}>
                                            <td>
                                                <a href={item.image.original ? env.SERVER_URL+item.image.original : '#'} target="_blank">
                                                <img alt="Avatar" class="table-avatar profile-image-size"  src={item.image && item.image.original ? env.SERVER_URL+item.image.original : '/assets/images/dummy.png'} />
                                                </a>
                                            </td>
                                            <td>
                                                <Toggle id='cheese-status' checked={item.status==1} onClick={()=> updateStatus(item._id)} />
                                            </td>
                                            <td>  
                                                <span style={{ marginLeft:'10px'}} className="btn btn-danger btn-sm" title="Delete" onClick={() => {popupDelete(item._id)}}>
                                                    <i className="fa fa-trash"></i>
                                                </span>
                                               
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                                <div className="pagination-box-review">
                                    {(totalItemsCount>itemsCountPerPage) &&
                                    <Pagination
                                        activePage={activePage}
                                        itemsCountPerPage={itemsCountPerPage}
                                        totalItemsCount={totalItemsCount}
                                        pageRangeDisplayed={pageRange}
                                        onChange={(page) => handlePageChange(page)}
                                        itemClass='page-item'
                                        linkClass='page-link'
                                    />
                                    }
                                </div>
                            </table>
                        </div>
                        }
                        {(!load && data.length==0) &&
                            <div className="row">
                                <div className="col-md-6 not-found-detail">
                                    <h6>Data not found....</h6>
                                </div>
                            </div>
                        } 
                                     
                       
                    </div>
                </div>
            </div>
            
        </>
    )
}

const mapStateToProps = (state) => {
    return{
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Banner);
 