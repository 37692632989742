import React, {useState, useEffect} from "react";
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Path from 'routes/paths';
import AuthService from 'services';
import swal from 'sweetalert';
import Pagination from "react-js-pagination";
import { List } from 'react-content-loader';
import env from 'env.json';
import SelectInput from 'components/shared/SelectInput';
import TextInput from 'components/shared/TextInput';
import LoadingButton from 'components/shared/LoadingButton';

const BlockedUser = (props) => {

    //pagination
    const [ activePage, setActivePage ] = useState(1);
    const [totalItemsCount, setTotalItemsCount] = useState(0);
    const [itemsCountPerPage, setItemsCountPerPage] = useState(0);
    const [pageRange, setPageRange] = useState(5);

    const [sending, setSending] = useState(false);
    const [load, setLoad] = useState(false);
    const [data, setData] = useState([]);
    const MODULE_NAME = 'blocked_users';

    //search record
    const [sortby, setSortby] = useState(1);
 
    //get data
    let isMounted = true;
    async function getData() {
        try{
            let query = {
                page: activePage,
                MODULE_NAME,
                sortby,
            };
            setSending(true);
            await props.dispatch(AuthService.getList(query)).then((res) => {
                if(isMounted){
                setSending(false);
                setData(res.body.data);
                setItemsCountPerPage(res.body.per_page);
                setTotalItemsCount(res.body.total_count);
                }  
            });
        }catch(err) {
            setSending(false);
            console.log(err);
            if(err && err.data && err.data.message){
                swal("Oops!", err.data.message, "error");
            }
        }
    };

    useEffect(() => {
        getData();
        return () => {
            isMounted = false;
        };
    }, [activePage, sortby]);

 
    const handlePageChange = (page) => {
        setActivePage(page);
       window.scrollTo(0,0);
    }

    const handleSubmit_ = async (e) => {
        try{
            e.preventDefault();
            let query = {
                page: activePage,
                MODULE_NAME,
                sortby,
            };
            setLoad(true);
            setSending(true);
            await props.dispatch(AuthService.getList(query)).then((res) => {
                setLoad(false);
                setSending(false);
                setData(res.body.data);
            });
        }catch(err) {
            console.log(err);
            setSending(false);
            if(err && err.data && err.data.message){
                swal("Oops!", err.data.message, "error");
            }
        }
    }
 
    return(
        <>
            <Helmet title="Blocked User" />
            <div className="app-title">
                <div>
                <h1><i className="fa fa-th-list"></i> Blocked User</h1>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="tile">
                        <div className="row">
                            <div className="col-md-10">
                                <h3 className="tile-title">Blocked User Listing</h3>
                            </div>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <SelectInput
                                        name="sort_by"
                                        className="custom-select"
                                        onChange={(value) => setSortby(value.target.value)}
                                    >
                                        <option value="" disabled>Sort by </option>
                                        <option value="0">Asc</option>
                                        <option value="1">Desc</option>
                                    </SelectInput>
                                </div>
                            </div>
                        </div>
                        {sending &&
                            <List  
                                style={{ width: '100%', padding: 10, }} 
                                speed={2}
                                height={150}
                                backgroundColor="#f3f3f3"
                                foregroundColor="#c1c5c7"
                                viewBox="30 0 380 30"
                            />
                        } 
                        {!sending && data.length>0 &&
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th>Blocked To</th>
                                    <th>Blocked By</th>
                                    <th style={{minWidth:'122px'}}>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {data.length>0  && data.map((item, i) => {
                                    return(
                                        <tr key={i}>
                                            <td>{item.blocked_to.first_name} {item.blocked_to.lastname}</td>
                                            <td>{item.blocked_by.first_name} {item.blocked_by.lastname}</td>
                                            <td>
                                                <Link to={`${Path.blocked_userview}/${item._id}`} className="btn btn-primary btn-sm" title="View">
                                                    <i className="fa fa-eye"></i>
                                                </Link>
                                                {/* <span style={{ marginLeft:'10px'}} className="btn btn-danger btn-sm" title="Delete" onClick={() => {popupDelete(item._id)}}>
                                                    <i className="fa fa-trash"></i>
                                                </span> */}
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                                <div className="pagination-box-review">
                                    {(totalItemsCount>itemsCountPerPage) &&
                                    <Pagination
                                        activePage={activePage}
                                        itemsCountPerPage={itemsCountPerPage}
                                        totalItemsCount={totalItemsCount}
                                        pageRangeDisplayed={pageRange}
                                        onChange={(page) => handlePageChange(page)}
                                        itemClass='page-item'
                                        linkClass='page-link'
                                    />
                                    }
                                </div>
                            </table>
                        </div>
                        }               
                        {(!sending && data.length==0) &&
                            <div className="row">
                                <div className="col-md-6 not-found-detail">
                                    <h6>Data not found....</h6>
                                </div>
                            </div>
                        }  
                    </div>
                </div>
            </div>

        </>
    )
}

const mapStateToProps = (state) => {
    return{
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BlockedUser);